<template>
  <b-form-select v-model="selected" :options="options"></b-form-select>
</template>

<script>
export default {
  name: "PreferredLanguageDropdownComponent",
  props: {
    preferredLanguage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selected: null,
      options: [
        {
          value: "de-DE",
          text: "Deutsch",
        },
        {
          value: "en-US",
          text: "English",
        },
      ],
    };
  },
  watch: {
    selected(selected) {
      this.$emit("languageSelected", selected)
    },
    preferredLanguage(preferredLanguage) {
      this.selected = preferredLanguage
    },
  },
  mounted() {
    this.selected = this.preferredLanguage
  }
};
</script>
