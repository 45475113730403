<template>
  <div
    class="d-flex justify-content-center align-items-center bg-white"
    style="height: calc(100% - 56px)"
    v-if="isLoading"
  >
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
  <div v-else>
    <navigation-component></navigation-component>
    <router-view></router-view>
    <transition name="slide-fade">
      <news-panel-component v-if="$store.state.showNewsPanel"></news-panel-component>
    </transition>
  </div>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import NavigationComponent from "../components/NavigationComponent.vue";
import NewsPanelComponent from "../components/NewsPanelComponent.vue";
import { auth, firestore, functions } from "../plugins/firebase";
import { signOut, updateProfile } from "firebase/auth";
import { collection, doc, onSnapshot, query, orderBy, updateDoc, getDoc } from "firebase/firestore";

export default {
  name: "LoggedInView",
  components: { NavigationComponent, NewsPanelComponent },
  data() {
    return {
      uid: auth.currentUser.uid,
      subuserId: "",
      isAdmin: true,
      isLoading: true,
      showNewsPanel: false,
    };
  },
  async beforeMount() {
    await this.fetchData();
  },
  beforeUnmount() {
    this.detachDocListeners();
  },
  methods: {
    async fetchData() {
      if (this.uid.includes("-")) {
        const uidSegments = this.uid.split("-");
        this.subuserId = uidSegments[0];
        this.isAdmin = false;
        await this.fetchSubuserDataFromFirestore();
      }

      await this.fetchResellerDataFromFirestore();

      await this.fetchNotificationsFromFirestore();

      this.isLoading = false;
    },
    async fetchSubuserDataFromFirestore() {
      const docRef = doc(firestore, "resellers", this.uid, "users", this.subuserId);

      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        this.$store.commit("storeResellerSubUserData", docSnap.val());

        const subuserDocListener = this.attachSubuserDocListener(docRef);
        this.$store.commit("storeSubuserDocListener", subuserDocListener);
      } else {
        this.signOutUser();
      }
    },
    attachSubuserDocListener(docRef) {
      return onSnapshot(docRef, (docSnap) => {
        this.$store.commit("storeResellerSubUserData", docSnap.data());
      }, (error) => {
        console.error(error);
        this.signOutUser();
      });
    },
    async fetchResellerDataFromFirestore() {
      const docRef = doc(firestore, "resellers", this.uid);

      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const docData = docSnap.data();
        const docId = docSnap.id;
        const hiddenNotifications = docData?.hiddenNotifications || [];
        const hiddenCustomers = docData?.hiddenCustomers || [];

        if (docData.companyProfile.companyName !== auth.currentUser.displayName) {
          this.updateDisplayName(docData.companyProfile.companyName, storeData);
        }

        const storeData = this.createStoreData(docData, hiddenNotifications, hiddenCustomers, docId);
        this.$store.commit("storeResellerData", storeData);

        const resellerDocListener = this.attachResellerDocListener(docRef)
        this.$store.commit("storeResellerDocListener", resellerDocListener);

        await this.fetchResellerDataFromAdobe();
      } else {
        this.signOutUser();
      }
    },
    async updateDisplayName(newDisplayName, storeData) {
      updateProfile(auth.currentUser, {
        displayName: newDisplayName,
      })
        .then(() => {
          storeData.displayName = newDisplayName;
        })
        .catch((error) => console.error(error.message));
    },
    createStoreData(data, hiddenNotifications, hiddenCustomers, docId) {
      return {
        displayName: auth.currentUser.displayName,
        email: auth.currentUser.email,
        uid: this.uid,
        resellerId: data.resellerId,
        customernumber: data.customernumber,
        companyProfile: data.companyProfile,
        firestoreId: docId,
        hiddenNotifications: hiddenNotifications,
        isAdmin: this.isAdmin,
        hiddenCustomers: hiddenCustomers,
        settings: data.settings,
      };
    },
    attachResellerDocListener(docRef) {
      return onSnapshot(docRef, (docSnap) => {
        const docData = docSnap.data();
        const docId = docSnap.id;
        const hiddenNotifications = docData?.hiddenNotifications || [];
        const hiddenCustomers = docData?.hiddenCustomers || [];

        if (docData.companyProfile.companyName !== auth.currentUser.displayName) {
          this.updateDisplayName(docData.companyProfile.companyName, storeData);
        }

        const storeData = this.createStoreData(docData, hiddenNotifications, hiddenCustomers, docId);
        this.$store.commit("storeResellerData", storeData);
      }, (error) => {
        console.error(error);
        this.signOutUser();
      });
    },
    async fetchResellerDataFromAdobe() {
      const getResellerAccount = httpsCallable(functions, "getResellerAccount");
      return getResellerAccount({ resellerId: this.$store.state.resellerData.resellerId })
        .then((result) => {
          const docRef = doc(firestore, "resellers", this.uid);
          updateDoc(docRef, {
            "companyProfile": result.data.companyProfile,
          });
        })
        .catch((error) => {
          console.error(error);
          this.signOutUser();
        });
    },
    fetchNotificationsFromFirestore() {
      const q = query(collection(firestore, "notifications"), orderBy("order", "desc"));
      const notificationsDocListener = onSnapshot(q, (querySnapshot) => {
        const notifications = [];
        querySnapshot.forEach((doc) => {
          const notification = {
            docId: doc.id,
            docData: doc.data(),
          };
          notifications.push(notification);
        });
        this.$store.commit("storeNotifications", notifications);
      }, (error) => {
        console.error(error);
      });

      this.$store.commit("storeNotificationsDocListener", notificationsDocListener);
    },
    signOutUser() {
      signOut(auth)
          .then(() => this.$router.replace("/"))
          .catch((error) => console.error(error.message));
    },
    detachDocListeners() {
      this.$store.state.resellerDocListener();
      if (this.uid.includes("-")) {
        this.$store.state.subuserDocListener();
      }
      this.$store.state.notificationsDocListener();
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(320px);
}
</style>
