<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center">
        <b-button class="mr-3" variant="dark" :to="{ name: 'TransferSubscriptionsView' }"><fa-icon :icon="['far','angle-left']"></fa-icon></b-button>
        <h3 class="font-weight-bold mb-0">
          {{ $t("transferArchive") }}
        </h3>
      </b-container>
    </div>
    <b-container class="pt-4">
      <div class="mb-4 text-muted" v-html="$t('transfersText')"></div>

      <b-card no-body>
        <b-card-header class="p-3 d-flex">

          <b-button variant="dark" v-b-tooltip.hover.topright="$t('exportCustomerData')" v-b-modal="'export-transfers-modal'" :disabled="getTransfers.length == 0">
            <fa-layers>
              <fa-icon :icon="['fa-regular', 'fa-user']"></fa-icon>
              <fa-icon :icon="['fa-solid', 'fa-down-to-line']" transform="shrink-6 right-10 up-3"></fa-icon>
            </fa-layers>
          </b-button>

          <b-input-group class="ml-3">
            <template #prepend>
              <b-input-group-text class="bg-white pr-0">
                <fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon>
              </b-input-group-text>
            </template>
            <b-input
              :placeholder="$t('searchByMembershipIdCusomterCustomerId')"
              class="border-left-0"
              v-model="filter"
            ></b-input>
          </b-input-group>
        </b-card-header>
        <b-table
          :items="getTransfers"
          :fields="fields"
          ref="transfers"
          class="mb-0"
          :filter="filter"
          show-empty
          :empty-filtered-text="$t('emptyFilteredText')"
          empty-text="Es sind keine Transfers vorhanden"
          sort-icon-left
        >
          <template #empty="scope">
            <div class="text-center font-italic">{{ scope.emptyText }}</div>
          </template>
          
          <template #emptyfiltered="scope">
            <div class="text-center font-italic">{{ scope.emptyFilteredText }}</div>
          </template>

          <template #cell(transfer)="data">
            <span v-b-tooltip.hover :title="$t('membershipId')">{{ data.item.membershipId }}</span><br />
            <small class="text-muted" v-b-tooltip.hover :title="$t('transferId')" v-html="data.item.transferId"></small>
          </template>

          <template #cell(customer)="data">
            {{ data.item.companyName }}<br />
            <small class="text-muted" v-b-tooltip.hover :title="$t('customerId')" v-if="data.item.customerId">
              <fa-icon :icon="['fa-regular','fa-id-card']" class="mr-1"></fa-icon>{{ data.item.customerId }}
            </small>
          </template>

          <template #cell(status)="data">
            <div class="d-flex flex-column align-items-start">
              <div :class="statusClass(data.item.status)">
                <fa-icon
                  :icon="['fa-regular', statusIcon(data.item.status)]"
                  class="mr-1"
                ></fa-icon>
                {{ data.item.status | subscriptionStatus }}
              </div>
            </div>
          </template>

          <template #cell(creationDate)="data">
            <span v-if="data.item.creationDate">
              <fa-icon :icon="['fa-regular','fa-clock']" class="mr-1"></fa-icon>{{ data.item.creationDate | date }}
            </span>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <b-button
                  class="mr-3"
                  variant="outline-primary"
                  size="sm"
                  v-on:click="goToTransferView(data.item.docId, data.item.membershipId)"
                  v-if="data.item.status === 'init'"
                >
                  <fa-icon :icon="['fa-regular', 'fa-paper-plane']" class="mr-2"></fa-icon>{{ $t("submitTransfer") }}
                </b-button>
                <b-button
                  variant="outline-danger"
                  size="sm"
                  v-on:click="deleteTransferWithId(data.item)"
                  v-if="data.item.status === 'init'"
                >
                  <fa-icon :icon="['fa-regular', 'fa-trash']" class="mr-2"></fa-icon>{{ $t("delete") }}
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-container>

    <b-modal
      id="export-transfers-modal"
      ref="export-transfers-modal"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      v-on:show="exportTransfersData()"
      :title="$t('exportCustomerData')"
    >
      <div class="w-100">
         <p>{{$t("pleaseWait")}}</p>
        <b-progress :max="transfers.length">
          <b-progress-bar :value="exportedTransfersCount" :label="`${exportedTransfersCount} / ${transfers.length}`"></b-progress-bar>
        </b-progress>
      </div>
    </b-modal>

    <b-modal
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      ref="delete-modal"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      v-on:cancel="clearTransferToBeDeleted()"
      v-on:hidden="clearTransferToBeDeleted()"
    >
      <div>
        Möchten Sie diesen Auftrag wirklich löschen?
      </div>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" v-on:click="cancel()">
          Abbrechen
        </b-button>

        <b-button v-on:click="deleteTransfer()" variant="danger">
          Löschen
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { orderBy } from 'lodash-es';
import moment from "moment-timezone";
import { firestore } from "../plugins/firebase";
import { query, collection, where, getDocs, deleteDoc, doc } from "firebase/firestore";
import { utils, writeFileXLSX } from 'xlsx';

export default {
  name: "TransfersView",
  data() {
    return {
      transfers: [],
      exportedTransfersCount: 0,
      filter: "",
      fields: [
        {
          key: "transfer",
          label: this.$t("transfer"),
          sortable: false,
        },
        {
          key: "customer",
          label: this.$t("customer"),
          sortable: false,
        },
        {
          key: "creationDate",
          label: this.$t("creationTime"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("status"),
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
      transferToBeDeleted: null,
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],

    };
  },
  methods: {
    async getTransfers(ctx) {
      const transfersColRef = query(collection(firestore, "transfers"), where("resellerId", "==", this.$store.state.resellerData.resellerId));
      const transfersRequest = await getDocs(transfersColRef);

      const customersColRef = collection(firestore, "customers")
      const customersRequest = await getDocs(customersColRef);

      const promises = [transfersRequest, customersRequest];

      return await Promise.all(promises)
        .then((responses) => {
          const customersSnapshot = responses[1];
          const customers = [];
          if (!customersSnapshot.empty) {
            customersSnapshot.forEach((customer) => {
              let data = customer.data();
              customers.push(data);
            });
          }

          const transfersSnapshot = responses[0];
          const transfers = [];
          if (!transfersSnapshot.empty) {
            transfersSnapshot.forEach((transfer) => {
              let transferData = transfer.data();

              transferData["docId"] = transfer.id;

              if (!transferData.creationDate) {
                transferData["creationDate"] = "";
              }

              if (!transferData.transferId) {
                transferData["transferId"] = "...";
              }

              const matchingCustomers = customers.filter((customer) => customer.customerId === transferData.customerId);
              if (matchingCustomers.length > 0) {
                transferData.companyName = matchingCustomers[0].companyProfile.companyName;
              } else {
                transferData.companyName = "";
                transferData.customerId = "";
              }

              if (
                transferData.membershipId.toLowerCase().includes(ctx.filter.toLowerCase()) ||
                transferData.companyName.toLowerCase().includes(ctx.filter.toLowerCase()) ||
                transferData.customerId.toLowerCase().includes(ctx.filter.toLowerCase())
              ) {
                transfers.push(transferData);
              }
            });
          }

          const orderedTransfers = orderBy(
            transfers,
            (item) => {
              return moment(item.creationDate).unix();
            },
            "desc"
          );
          this.transfers = orderedTransfers;
          return orderedTransfers;
        })
        .catch((error) => {
          console.error(error);
          return [];
        });
    },
    statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    goToTransferView(docId, membershipId) {
      this.$router.push({
        name: "TransferSubscriptionsPrevieOffersView",
        params: { docId: docId, membershipId: membershipId },
      });
    },
    async getCompanyNames(transfers) {
      return transfers;
    },
    deleteTransferWithId(transfer) {
      this.transferToBeDeleted = transfer;
      this.$refs["delete-modal"].show();
    },
    clearTransferToBeDeleted() {
      this.transferToBeDeleted = null;
    },
    async deleteTransfer() {
      const docRef = doc(firestore, "transfers", this.transferToBeDeleted.docId);
      await deleteDoc(docRef);

      this.clearTransferToBeDeleted();
      this.$refs.transfers.refresh()
      this.$refs["delete-modal"].hide();
    },
    async exportTransfersData() {
      this.exportedTransfersCount = 0;
      const transfers = [];
      this.transfers.forEach((transfer) => {
        transfer.status == "1000" && transfers.push({
          "VIP Nr.": transfer.membershipId,
          "Kunde": transfer.companyName,
          "Status": transfer.status,
          "Kundenummer": transfer.customerId,
          "Erstellungszeitpunkt": new Date(transfer.creationDate).toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
        });
        this.exportedTransfersCount++
      }); 
      const worksheet = utils.json_to_sheet(transfers);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Daten");
      writeFileXLSX(workbook, "acs-transfers-export.xlsx");
      this.$bvModal.hide("export-transfers-modal");
    },
  }
};
</script>
