<template>
  <div>
    <b-modal 
    hide-header
    :content-class="contentClass"
    :footer-class="footerClass"
    :body-class="bodyClass"
    v-model="alertErrorShow"
    hide-cancel
    no-close-on-esc
    no-close-on-backdrop
    ok-only
    ok-variant="primary"
    no-footer
    v-on:hidden="onHidden"
    id="price-increase-modal">
      <h3 class="mb-4">Adobe erhöht zum 02.11.2023 die Preise für Creative Cloud Produkte</h3>
      <p>Adobe hat für den 02. November eine weitere wertbasierte Preisanpassung seiner Creative Cloud Produkte für VIP und VIP Marketplace angekündigt.</p>
      <p>Im Adobe Cloud Store liegt die Preiserhöhung für All Apps und Single Apps bei bis zu 13%.</p>
      <p>Die genauen Preise erfahren Sie von uns ab dem 02.10.2023.</p>
      <p>Für Kunden welche ein aktuelles 3YC haben, ist diese Erhöhung nicht relevant.</p>
      Für aktuelle Verlängerungsangebote wenden Sie sich bitte an das ComLine-Team.
    </b-modal>

    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <div>
          <div class="d-flex align-items-center mb-4">
            <h3 class="font-weight-bold mb-0">
              {{ $store.state.resellerData.companyProfile.companyName }}
            </h3>
          </div>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column mr-4">
              <small class="text-muted">{{ $t("yourResellerId") }}</small>
              <div>{{ $store.state.resellerData.resellerId }}</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <small class="text-muted">{{ $t("yourLimit") }}</small>
              <customer-limit-component />
            </div>
            <div class="d-flex flex-column mr-4">
              <small class="text-muted">{{ $t("signedInAs") }}</small>
              <div v-if="$store.state.resellerSubUserData !== null">
                {{ $store.state.resellerSubUserData.firstName }} {{ $store.state.resellerSubUserData.lastName }} ({{
                  $store.state.resellerSubUserData.email
                }})
              </div>
              <div v-else>Admin ({{ $store.state.resellerData.email }})</div>
            </div>
          </div>
        </div>
        <div v-if="imagePath != ''">
          <b-img :src="imagePath" thumbnail id="resellerImage"></b-img>
        </div>
      </b-container>
    </div>

    <b-container class="pt-5">
      <h5 class="font-weight-bold">{{ $t("yourDashboard") }}</h5>
      <p class="text-muted">{{ $t("keyFactsAtAGlance") }}</p>
      <b-row class="mb-5">
        <b-col xl="8">
          <!-- <PurchaseRequestsComponent class="mb-4"></PurchaseRequestsComponent> -->
          <Pending3ycComponent class="mb-4" :customers="customers" v-if="customers.length > 0"></Pending3ycComponent>
          <BannerComponent v-if="!settings.partnerStore" class="mb-4"></BannerComponent>
          <NewsComponent class="mb-4 mb-xl-0"></NewsComponent>
        </b-col>
        <b-col>
          <UpcomingRenewalsComponent
            class="mb-4"
            :customers="customers"
            v-if="customers.length > 0"
          ></UpcomingRenewalsComponent>
          <ReleaseTimelineComponent></ReleaseTimelineComponent>
        </b-col>
      </b-row>

      <h5 class="font-weight-bold">{{ $t("comlineContactsHeadline") }}</h5>
      <p class="text-muted mb-0">{{ $t("comlineContactsText") }}</p>
      <ContactsComponent></ContactsComponent>
    </b-container>
  </div>
</template>

<script>
import BannerComponent from "../components/BannerComponent";
import ContactsComponent from "../components/ContactsComponent.vue";
import NewsComponent from "../components/NewsComponent.vue";
// import PurchaseRequestsComponent from "../components/PurchaseRequestsComponent.vue";
import UpcomingRenewalsComponent from "../components/UpcomingRenewalsComponent.vue";
import ReleaseTimelineComponent from "../components/ReleaseTimelineComponent.vue";
import Pending3ycComponent from "../components/Pending3ycComponent.vue";
import CustomerLimitComponent from "../components/CustomerLimitComponent.vue";
import { mapState } from "vuex";
import { firestore, storage } from "../plugins/firebase";
import { collection, where, query, getDocs, doc, getDoc } from "firebase/firestore";
import { ref as storageRef, getDownloadURL } from "firebase/storage";

export default {
  name: "HomepageView",
  components: {
    BannerComponent,
    ContactsComponent,
    NewsComponent,
    // PurchaseRequestsComponent,
    UpcomingRenewalsComponent,
    ReleaseTimelineComponent,
    Pending3ycComponent,
    CustomerLimitComponent,
  },
  data() {
    return {
      imagePath: "",
      customers: [],
      contentClass: ["border-0 shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
    };
  },
  computed: {
    ...mapState(["settings"]),
    alertErrorShow() {
      //return localStorage.getItem("price-increase-modal") !== "hide";
      return false;
    }
  },
  async mounted() {
    this.getCustomers();
    this.getImage();
  },
  methods: {
    async getImage() {
      const docRef = doc(firestore, "resellers", this.$store.state.resellerData.customernumber);
      const docSnapshot = await getDoc(docRef);
      const path = docSnapshot.data()?.logo?.path;
      this.imagePath = path ? await getDownloadURL(storageRef(storage, path)) : "";
    },
    async getCustomers() {
      const colRef = query(
        collection(firestore, "customers"),
        where("resellerId", "==", this.$store.state.resellerData.resellerId)
      );

      const querySnapshot = await getDocs(colRef);

      querySnapshot.forEach((doc) => {
        this.customers.push(doc.data());
      });
    },
    onHidden() {
      localStorage.setItem("price-increase-modal", "hide");
    },
  },
};
</script>

<style scoped>
#resellerImage {
  width: 100px;
  height: 100px;
}
</style>
