<template>
  <div>
    <div class="mb-4 text-muted">
      {{ $t("customerRenewalOfferText") }}
    </div>
    <b-form-group>
      <b-form-radio v-model="selectedOption" value="endcustomerSalesPrices">{{$t("rrp")}}</b-form-radio>
      <b-form-radio v-model="selectedOption" value="prices">{{ $t("purchasePrices") }}</b-form-radio>
    </b-form-group>
    <b-button v-on:click="generateRenewalOffer()" variant="primary">{{ $t("downloadRenewalOffer") }}</b-button>
    <div class="imageContainer d-none">
      <ImageBlob v-if="imagePath != ''" :data="{image: imagePath}" id="doc-logo" class="d-none"/>
      <img v-else src="/logo_pdf.png" alt="Logo" id="doc-logo" class="d-none" />
    </div>
  </div>
</template>

<script>
import ImageBlob from "../components/ImageBlob.vue"
import jsPDF from "jspdf";
import "../../node_modules/jspdf-autotable";
import { firestore, functions } from "../plugins/firebase";
import { doc, getDoc } from "firebase/firestore";
import { collection, orderBy, query, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";

export default {
  name: "CustomerRenewalOfferView",
  components: { 
    ImageBlob,
  },
  props: {
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
    subscriptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    autoRenewalSubscriptions: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedOption: "endcustomerSalesPrices",
      imagePath: "",
      specialPriceSwitch: Array(),
      hiddenRenewalOfferProducts: [],
      marketSegments: [],
    }
  },
  methods: {   
    async fetchMarketSegmentProductTypes(){
      const colRef = doc(firestore, "generalSettings", "marketSegments");
      const snap = await getDoc(colRef);
      this.marketSegments = snap.data()[this.customer.companyProfile.marketSegment] || null;
    },
    async getHiddenRenewalOfferProducts(){
      const colRef = doc(firestore, "generalSettings", "hiddenRenewalOfferProducts");
      const snap = await getDoc(colRef);
      this.hiddenRenewalOfferProducts = snap.data().productCodes || [];
    }, 
    async getResellerDataFromCollection() {
      const docRef = doc(firestore, "resellers", this.$store.state.resellerData.customernumber);
      const docSnapshot = await getDoc(docRef);
      this.imagePath = docSnapshot.data()?.logo?.path || false;
    },
    getTaxRate(country) {
      if (this.$store.state.taxRates[country] !== undefined) {
        return this.$store.state.taxRates[country];
      } else {
        return 0.19;
      }
    },
    findPriceToUse(product, offerId) {
      const price = product.prices.find(price => price.offerId === offerId);
      if (price) {
        return price;
      } else {
        return product.prices[0];
      }
    },
    async generateRenewalOffer() {
      if (this.autoRenewalSubscriptions === null || this.autoRenewalSubscriptions === 0) {
        this.$store.dispatch("alertError", {
          message: "Sie haben keine Lizenzen, die automatisch verlängert werden.",
          show: true,
          noFooter: false,
        });
        return;
      }

      if (this.subscriptions.length === 0) {
        this.$store.dispatch("alertError", {
          message: "Sie haben keine Lizenzen.",
          show: true,
          noFooter: false,
        });
        return;
      }

      const productsColRef = query(collection(firestore, "products"), orderBy("productName"));
      const products = await getDocs(productsColRef);

      if (products.empty) {
        this.$store.dispatch("alertError", {
          message: "Es wurden keine Produkte gefunden.",
          show: true,
          noFooter: false,
        });
        return;
      }

      const newProducts = [];
      
      products.forEach((product) => {
        newProducts.push(product.data())
      });

      const requestProducts = [];

      this.subscriptions.forEach((subscription) => {
        const matchedProduct = newProducts.find((product) => {
          const itemNumberObject = this.$options.filters.itemNumberObject(product.itemNumber);
          const isNotIncludedInHiddenRenewalOfferProducts = !this.hiddenRenewalOfferProducts.includes(`${itemNumberObject.productType}-${itemNumberObject.product}`);

          return product.manufacturerItemNumber === subscription.offerId && isNotIncludedInHiddenRenewalOfferProducts;
        });

        requestProducts.push(matchedProduct);
      });

      const getPrices = httpsCallable(functions, "getPrices");
      const productsWithPrices = await getPrices({
        benefits: this.customer.benefits,
        cotermDate: this.customer.cotermDate,
        discounts: this.customer.discounts,
        products: requestProducts,
        resellerId: this.customer.resellerId,
        marketSegment: this.customer.companyProfile.marketSegment,
      });

      console.log(productsWithPrices);

      let offerSum = 0;

      const printDataSubscriptions = this.subscriptions.map((subscription) => {
        console.log(subscription);

        const matchedProduct = productsWithPrices.data.find((product) => {
          product.priceToUse = this.findPriceToUse(product, subscription.offerId);

          return product.priceToUse.offerId === subscription.offerId;
        });

        const lineSum = matchedProduct.priceToUse.price * subscription.autoRenewal.renewalQuantity;

        offerSum += lineSum;

        return {
          price: this.$options.filters.currency(matchedProduct.priceToUse.price),
          productName: this.$options.filters.productNameWithoutLevel(matchedProduct.priceToUse.productName),
          quantity: subscription.autoRenewal.renewalQuantity.toString(),
          itemNumber: matchedProduct.priceToUse.itemNumber,
          offerId: matchedProduct.priceToUse.offerId,
          sum: this.$options.filters.currency(lineSum),
        };
      });

      const volumeLevel = this.customer.discounts.find((discount) => discount.offerType === "LICENSE")?.level;
      const consumableLevel = this.customer.discounts.find((discount) => discount.offerType === "CONSUMABLES")?.level;

      const printData = {
        customer: {
          companyName: this.customer.companyProfile.companyName,
          addressLine1: this.customer.companyProfile.address.addressLine1,
          addressLine2: this.customer.companyProfile.address.addressLine2,
          postalCode: this.customer.companyProfile.address.postalCode,
          city: this.customer.companyProfile.address.city,
        },
        reseller: {
          companyName: this.$store.state.resellerData.companyProfile.companyName,
          addressLine1: this.$store.state.resellerData.companyProfile.address.addressLine1,
          addressLine2: this.$store.state.resellerData.companyProfile.address.addressLine2,
          postalCode: this.$store.state.resellerData.companyProfile.address.postalCode,
          city: this.$store.state.resellerData.companyProfile.address.city,
        },
        cotermDate: this.customer.cotermDate,
        customerId: this.customer.customerId,
        date: moment().tz("Europe/Berlin").format(),
        volumeLevel: volumeLevel,
        consumableLevel: consumableLevel,
        subscriptions: printDataSubscriptions,
        sum: offerSum,
      };

      this.generatePdf(printData);
    },
    generateTable(printData) {
      const taxRate = this.getTaxRate(this.$store.state.resellerData.companyProfile.address.country);

      let result = {
        head: [
          [
            {
              content: this.$t("posNo").toUpperCase(),
              styles: { valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("product").toUpperCase(),
              styles: { valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("price").toUpperCase(),
              styles: { halign: "right", valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("quantity").toUpperCase(),
              styles: { halign: "right", valign: "middle", fillColor: "#cccccc" },
            },
            {
              content: this.$t("totalPrice").toUpperCase(),
              styles: { halign: "right", valign: "middle", fillColor: "#cccccc" },
            },
          ],
        ],
        foot: [
          [
            "",
            "",
            "",
            this.$t("totalPrice").toUpperCase(),
            {
              content: this.$options.filters.currency(printData.sum),
              styles: { halign: "right" },
            },
          ],
          [
            "",
            "",
            "",
            {
              content: this.$t("vat", {taxRate: (taxRate*100)}).toUpperCase(),
              styles: { fontSize: 9, textColor: "#9b9b9b" },
            },
            {
              content: this.$options.filters.currency(printData.sum * taxRate),
              styles: { fontSize: 9, halign: "right", textColor: "#9b9b9b" },
            },
          ],
          [
            "",
            "",
            "",
            {
              content: this.$t("grossPrice").toUpperCase(),
              styles: { fontSize: 9, textColor: "#9b9b9b" },
            },
            {
              content: this.$options.filters.currency(printData.sum * taxRate + printData.sum),
              styles: { fontSize: 9, halign: "right", textColor: "#9b9b9b" },
            },
          ],
        ],
        body: [],
        startY: 127,
        theme: "plain",
        showHead: "firstPage",
        showFoot: "lastPage",
        styles: {
          font: "helvetica",
          fontSize: 12,
          cellPadding: { top: 2, right: 1, bottom: 2, left: 1 },
        },
      };

      if (this.$i18n.locale === "en") {
        delete result.foot.splice(1, 1);
        delete result.foot.splice(1, 1);
      }

      printData.subscriptions.forEach((subscription, index) => {
        const row = [
          {
            content: index + 1,
            styles: { valign: "middle" },
          },
          `${subscription.productName}\nSKU: ${subscription.itemNumber}\nAdobe SKU: ${subscription.offerId}`,
          {
            content: subscription.price,
            styles: { halign: "right", valign: "middle" },
          },
          {
            content: subscription.quantity,
            styles: { halign: "right", valign: "middle" },
          },
          {
            content: subscription.sum,
            styles: { halign: "right", valign: "middle" },
          },
        ];
        result.body.push(row);
      });
      return result;
    },
    generatePdf(printData) {
      const doc = new jsPDF();
      
      const docLogo = document.querySelectorAll('.imageContainer img')[0];
      const imageSize = {
        width: 78,
        height: 78
      }
      
      // ...(..., ..., Links, Oben, Breite, Höhe)
      doc.addImage(docLogo.src, "png", 120, 5, imageSize.width, imageSize.height);

      doc.setFont("Helvetica", "", "normal");

      doc.setFontSize(9);
      doc.setTextColor("#9b9b9b");
      const resellerAdress = `${printData.reseller.companyName}\n${printData.reseller.addressLine1}\n${printData.reseller.postalCode} ${printData.reseller.city}`
      doc.text(doc.splitTextToSize(resellerAdress, 90), 14, 35);

      doc.setFontSize(12);
      doc.setTextColor("#000000");
      const customerAddress = `${printData.customer.companyName}\n${printData.customer.addressLine1}\n${printData.customer.addressLine2}\n${printData.customer.postalCode} ${printData.customer.city}`
      doc.text(doc.splitTextToSize(customerAddress, 90), 14, 50);

      doc.setFontSize(15);
      doc.setFont("Helvetica", "", "bold");
      doc.text(`${this.$t("offerFrom")} ${this.$options.filters.date(printData.date)}`, 14, 97);

      doc.setFontSize(9);
      doc.setFont("Helvetica", "", "normal");
      doc.setTextColor("#9b9b9b");
      doc.text(this.$t("customerId").toUpperCase(), 14, 109);
      doc.text(this.$t("anniversaryDate").toUpperCase(), 54, 109);
      printData.volumeLevel ? doc.text(this.$t("volumeLevel").toUpperCase(), 89, 109) : doc.text("", 89, 109);
      printData.consumableLevel ? doc.text(this.$t("consumableLevel").toUpperCase(), 139, 109) : doc.text("", 139, 109);

      doc.setFontSize(12);
      doc.setTextColor("#000000");
      doc.text(printData.customerId, 14, 115);
      doc.text(this.$options.filters.date(printData.cotermDate), 54, 115);

      printData.volumeLevel ? doc.text(printData.volumeLevel.toString(), 89, 115) : doc.text("", 89, 115);
      printData.consumableLevel ? doc.text(printData.consumableLevel.toString(), 139, 115) : doc.text("", 139, 115);

      doc.autoTable(this.generateTable(printData));

      doc.setFontSize(9);
      const footerText = doc.splitTextToSize(this.$t("renewalOfferFooterText"), 182);
      doc.text(footerText, 14, doc.autoTable.previous.finalY + 12);

      let fileNameSuffix = "";

      if (this.selectedOption === "prices") {
        fileNameSuffix = "-reseller-prices";
      }

      doc.save(`${this.$t("renewalOfferFileName")}${printData.customerId.toLowerCase()}${fileNameSuffix}.pdf`);
    },
  },
  mounted() {
    this.getHiddenRenewalOfferProducts();
    this.fetchMarketSegmentProductTypes();
    this.getResellerDataFromCollection();
  }
};
</script>
