<template>
  <b-alert class="mt-4 mb-4 d-flex justify-content-between align-items-center" variant="warning" show>
    <div>
      <h5>{{ $t("missedRenewal") }}</h5>
      {{ $t("missedRenewalText") }}
    </div>
    <submit-button-component
      v-on:click="createOrder()"
      :state="submitButtonState"
      :label="$t('createOrder')"
      :block="false"
      :variant="'warning'"
    ></submit-button-component>
  </b-alert>
</template>

<script>
import moment from "moment-timezone";
import SubmitButtonComponent from './SubmitButtonComponent.vue';
import { firestore } from "../plugins/firebase"
import { collection, addDoc, query, getDocs, writeBatch, doc } from "firebase/firestore";

export default {
  name: "CreateRenewalOrderComponent",
  components: { SubmitButtonComponent },
  props: {
    profile: {
      type: Object,
      default: () => {
        return null;
      },
    },
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
    subscriptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isWorking: false,
    };
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    async createOrder() {
      this.isWorking = true;

      const allProducts = await this.getProducts();

      const products = [];
      this.subscriptions.forEach((subscription) => {
        const filteredProducts = allProducts.filter((product) => product.manufacturerItemNumber === subscription.offerId);
        
        if (filteredProducts.length > 0) {
          filteredProducts[0]["quantity"] = subscription.currentQuantity;
          products.push(filteredProducts[0]);
        }
      });

      const parentDocRef = await addDoc(collection(firestore, "orders"), {
        finished: false,
        customer: this.customer,
        creationDate: moment()
          .tz("Europe/Berlin")
          .format(),
      });

      const batch = writeBatch(firestore);

      products.forEach((product) => {
        const docRef = doc(collection(firestore, "orders", parentDocRef.id, "products"));
        batch.set(docRef, product);
      });

      await batch.commit();

      this.isWorking = false;

      this.$router.push({
        name: "CreateOrderSelectProductsView",
        params: {
          orderId: parentDocRef.id,
        },
      });
    },
    async getProducts() {
      const productsColRef = query(collection(firestore, "products"));
      const productsPromise = getDocs(productsColRef);

      const pricesColRef = collection(firestore, "resellers", this.$store.state.resellerData.firestoreId, "prices");
      const pricesPromise = getDocs(pricesColRef);

      const results = await Promise.all([productsPromise, pricesPromise]).catch((error) => {
        console.error(error);
      });

      if (results[0].empty || results[1].empty) {
        this.$store.dispatch("alertError", {
          message: "Es wurden keine Produkte gefunden.",
          show: true,
          noFooter: false,
        });
        return;
      }

      const products = [];
      results[0].forEach((product) => {
        products.push(product.data());
      });

      const prices = [];
      results[1].forEach((price) => {
        prices.push(price.data());
      });

      const productsUnique = [];

      products.forEach((product) => {
        const itemNumberSections = this.clArtNoObject(product.itemNumber);
        const productObject = {
          productBaseItemNumber: itemNumberSections.product,
          languageNumber: itemNumberSections.language,
          productType: itemNumberSections.productType,
          filters: product.filters,
          imageUrl: product?.partnerData?.imageUrl || product.imageUrl,
          productName: product.productName,
          quantity: product.quantity,
          prices: [],
          manufacturerItemNumber: product.manufacturerItemNumber,
        };

        productsUnique.push(productObject);
      });

      const allPrices = [];

      products.forEach((product) => {
        const itemNumberSections = this.clArtNoObject(product.itemNumber);
        const matchedPrice = prices.filter((price) => product.itemNumber === price.itemNumber);

        const priceObject = {
          price: matchedPrice[0].price,
          itemNumber: matchedPrice[0].itemNumber,
          itemNumberSections: itemNumberSections,
          manufacturerItemNumber: product.manufacturerItemNumber,
          endcustomerSalesPrice: product?.partnerData?.endcustomerSalesPrice ? product.partnerData.endcustomerSalesPrice : product.endcustomerSalesPrice,
          imputedCost: product?.partnerData?.imputedCost ? product.partnerData.imputedCost : product.imputedCost,
        };

        allPrices.push(priceObject);
      });

      productsUnique.forEach((product, index) => {
        const matchedPrices = allPrices.filter(
          (price) =>
            product.productBaseItemNumber === price.itemNumberSections.product &&
            product.languageNumber === price.itemNumberSections.language &&
            product.productType === price.itemNumberSections.productType
        );

        productsUnique[index].prices = matchedPrices;
      });

      return productsUnique;
    },
    clArtNoObject(itemNumber) {
      const itemNumberChunks = itemNumber.split("-");
      const artNrSplittet = itemNumberChunks[3].split("");
      return {
        product: artNrSplittet[0] + artNrSplittet[1] + artNrSplittet[2] + artNrSplittet[3],
        volumeLevel: parseInt(artNrSplittet[4]) + 1,
        language: artNrSplittet[5],
        productType: itemNumberChunks[2],
      };
    },
  },
}
</script>