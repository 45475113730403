<template>
  <b-card class="mb-4">
    <template #header v-if="index !== 0">
      <div class="d-flex justify-content-end">
        <b-button variant="danger" size="sm" v-on:click="removeContact(index)">{{ $t("delete") }}</b-button>
      </div>
    </template>
    <b-row>
      <b-col class="d-flex flex-column justify-content-center">
        <label class="mb-0">{{ $t("firstNameLabel") }}</label>
      </b-col>
      <b-col>
        <b-form-input
          id="input-firstname"
          type="text"
          v-model="contact.firstName"
          :state="states.firstName"
          aria-describedby="input-firstname-feedback"
          trim
          required
        />
        <b-form-invalid-feedback id="input-firstname-feedback">
          {{ $t("firstNameInputErrorText") }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col class="d-flex flex-column justify-content-center">
        <label class="mb-0">{{ $t("lastNameLabel") }}</label>
      </b-col>
      <b-col>
        <b-form-input
          id="input-lastname"
          type="text"
          v-model="contact.lastName"
          :state="states.lastName"
          aria-describedby="input-lastname-feedback"
          trim
          required
        />
        <b-form-invalid-feedback id="input-lastname-feedback">
          {{ $t("lastNameInputErrorText") }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col class="d-flex flex-column justify-content-center">
        <label class="mb-0">{{ $t("emailLabel") }}</label>
      </b-col>
      <b-col>
        <b-form-input
          id="input-contact-email"
          type="email"
          v-model="contact.email"
          :state="states.email"
          aria-describedby="input-contact-email-feedback"
          trim
          required
        />
        <b-form-invalid-feedback id="input-contact-email-feedback">
          {{ $t("emailInputErrorText") }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col class="d-flex align-items-center">
        <label class="mb-0">{{ $t("phoneNumberLabel") }}</label>
      </b-col>
      <b-col>
        <b-form-input v-model="contact.phoneNumber"></b-form-input>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import isEmail from "validator/es/lib/isEmail";

export default {
  name: "ContactComponent",
  props: {
    contact: {
      type: Object,
      default: () => {
        return {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        };
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "customer",
    }
  },
  data() {
    return {
      states: {
        firstName: null,
        lastName: null,
        email: null,
      },
    };
  },
  methods: {
    removeContact(index) {
      this.$emit("contactRemoved", index)
    }
  },
  watch: {
    contact: {
      deep: true,
      handler(contact) {
        if (contact.email === "") {
          this.states.email = null;
        } else {
          this.states.email = isEmail(contact.email);
        }

        if (contact.firstName === "") {
          this.states.firstName = null;
        } else {
          if (contact.firstName.match(this.$store.state.nameRegex)) {
            this.states.firstName = true;
          } else {
            this.states.firstName = false;
          }
        }

        if (contact.lastName === "") {
          this.states.lastName = null;
        } else {
          if (contact.lastName.match(this.$store.state.nameRegex)) {
            this.states.lastName = true;
          } else {
            this.states.lastName = false;
          }
        }

        this.$emit("contactUpdated", {
          data: this.contact,
          index: this.index,
        });
      },
    },
    states: {
      deep: true,
      handler(states) {
        this.$emit("stateUpdated", states);
      },
    },
  },
};
</script>
