<template>
  <div>Bitte warten</div>
</template>

<script>
import { auth } from "../plugins/firebase";
import { signInWithCustomToken, signOut } from "firebase/auth";

export default {
  name: "AdminHomepageView",
  async mounted() {
    let token = this.$route.params?.customerToken
    signInWithCustomToken(auth, token)
        .then(() => {
          this.$router.replace({ name: "HomepageView" })
        })
        .catch((error) => {
          console.error(error.message);
          signOut(auth)
            .then(() => this.$router.replace("/"))
            .catch((error) => console.error(error.message));
        });
  },

};
</script>