<template>
  <b-card
    header-tag="header"
    id="pending-3yc-component"
    header-bg-variant="primary"
    header-text-variant="white"
    no-body
  >
    <template #header>
      <h6 class="mb-0">
        <fa-icon :icon="['fa-regular', 'fa-cart-circle-plus']" class="mr-2 text-white"></fa-icon>{{ $t("pending3ycInvitations") }}
      </h6>
    </template>

    <b-card-body v-if="commitmentRequests.length > 0" class="with-content">
      <div
        class="d-flex justify-content-between mb-2 align-items-center"
        v-for="(item, index) in commitmentRequests"
        :key="`item-${index}`"
      >
        <div class="d-flex flex-column">
          <strong>{{ item.companyProfile.companyName }}</strong>
          <small>{{ item.customerId }}</small>
        </div>
        <b-button variant="dark" :to="{ name: 'CustomerSubscriptionsView', params: { customerId: item.customerId } }">
          <fa-icon :icon="['fa-regular', 'fa-arrow-right']" class="text-white"></fa-icon>
        </b-button>
      </div>
    </b-card-body>

    <b-card-body v-else class="d-flex flex-column justify-content-center text-center" v-html="$t('noPending3ycInvitations')"></b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "Pending3ycComponent",
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      commitmentRequests: [],
    };
  },
  mounted() {
    this.customers.forEach((docData) => {
      const benefits = docData.benefits;
      if (benefits && benefits.length > 0) {
        const firstBenefit = benefits[0];
        if (firstBenefit.type === "THREE_YEAR_COMMIT") {
          const commitmentRequest = firstBenefit.commitmentRequest;
          const recommitmentRequest = firstBenefit.recommitmentRequest;

          if (
            (commitmentRequest && commitmentRequest.status == "REQUESTED") ||
            (recommitmentRequest && recommitmentRequest.status === "REQUESTED")
          ) {
            this.commitmentRequests.push(docData);
          }
        }
      }
    });
  },
};
</script>

<style>
#pending-3yc-component .card-body {
  height: 240px;
}
#pending-3yc-component .card-body.with-content {
  overflow-y: scroll;
}
</style>
