<template>
  <div>
    <div class="mb-4 d-flex justify-content-end">
      <b-button variant="dark" v-on:click="addContact()"><fa-icon :icon="['fa-regular', 'fa-user-plus']" class="mr-2"></fa-icon>{{ $t("addContact") }}</b-button>
    </div>

    <b-form v-on:submit.prevent="updateCustomerAccount">
    <b-card footer-class="border-0 bg-light">
      <contact-component
        v-for="(contact, index) in contacts"
        :key="`contact-${index}`"
        :contact="contact"
        :index="index"
        :type="'customer'"
        v-on:stateUpdated="stateUpdated"
        v-on:contactUpdated="contactUpdated"
        v-on:contactRemoved="contactRemoved"
      ></contact-component>

      <template #footer>
        <div class="d-flex justify-content-end">
          <submit-button-component :state="submitButtonState" :label="$t('save')" :block="false" class="my-2"></submit-button-component>
        </div>
      </template>
    </b-card>
    </b-form>
  </div>
</template>

<script>
import ContactComponent from "../components/ContactComponent.vue";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";

export default {
  name: "CustomerContactsView",
  components: {
    ContactComponent,
    SubmitButtonComponent,
  },
  props: {
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      profile: this.customer,
      contacts: this.customer.companyProfile.contacts,
      isWorking: false,
      missingFields: true,
      states: {
        contactEmail: null
      }
    };
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      if (this.missingFields === true) {
        return "missingFields";
      }

      return "";
    },
  },
  methods: {
    async updateCustomerAccount() {
      this.isWorking = true;

      const updateCustomerAccount = httpsCallable(functions, "updateCustomerAccount");
      await updateCustomerAccount({
        customerId: this.profile.customerId,
        profile: {
          companyProfile: {
            companyName: this.profile.companyProfile.companyName,
            preferredLanguage: this.profile.companyProfile.preferredLanguage,
            address: this.profile.companyProfile.address,
            contacts: this.contacts,
          },
        },
      })
        .then((result) => {
          this.profile = result.data;
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });

        this.isWorking = false;
    },
    addContact() {
      this.contacts.push({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });

      this.missingFields = true;
    },
    contactUpdated(contact) {
      this.contacts.splice(contact.index, 1, contact.data);
    },
    contactRemoved(index) {
      this.contacts.splice(index, 1);
      this.missingFields = false;
    },
    stateUpdated(states) {
      if (Object.values(states).includes(false) || Object.values(states).includes(null)) {
        this.missingFields = true;
      } else {
        this.missingFields = false;
      }
    }
  },
};
</script>
