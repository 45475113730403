<template>
  <b-button variant="dark" v-on:click="createOrder()">{{ $t("createOrder") }}</b-button>
</template>

<script>
import moment from "moment-timezone";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { collection, addDoc } from "firebase/firestore";

export default {
  name: "CreateNewOrderComponent",
  props: {
    customerId: String,
  },
  methods: {
    async createOrder() {
      const getCustomerAccount = httpsCallable(functions, "customerAccount");
      const customerAccount = await getCustomerAccount({
        customerId: this.customerId,
      })
        .then((customerAccount) => {
          const cotermDateTimestamp = moment(customerAccount.data.cotermDate).tz("America/Los_Angeles").unix();
          const todayTimestamp = moment().tz("America/Los_Angeles").unix();

          if (cotermDateTimestamp < todayTimestamp) {
            const thisYear = moment().tz("America/Los_Angeles").format("YYYY");
            const cotermDateDayAndMonth = moment(customerAccount.data.cotermDate).format("MM-DD");
            const cotermDateYear = moment(customerAccount.data.cotermDate).format("YYYY");

            if (cotermDateYear === thisYear) {
              const nextYear = moment().tz("America/Los_Angeles").add(1, "years").format("YYYY");
              customerAccount.data.cotermDate = `${nextYear}-${cotermDateDayAndMonth}`;
            } else {
              const newCotermDateThisYear = `${thisYear}-${cotermDateDayAndMonth}`;
              const newCotermDateThisYearTimestamp = moment(newCotermDateThisYear).tz("America/Los_Angeles").unix();

              if (newCotermDateThisYearTimestamp < todayTimestamp) {
                const nextYear = moment().tz("America/Los_Angeles").add(1, "years").format("YYYY");
                customerAccount.data.cotermDate = `${nextYear}-${cotermDateDayAndMonth}`;
              } else {
                customerAccount.data.cotermDate = newCotermDateThisYear;
              }
            }
          }

          return customerAccount
        })
        .catch(() => {
          this.$store.dispatch("alertError", {
            message: "Die Daten konnten nicht abgerufen werden.",
            show: true,
            noFooter: false,
          });
          return;
        });

      if (customerAccount === null) return;

      const docRef = await addDoc(collection(firestore, "orders"), {
        finished: false,
        deluxeOrder: null,
        customer: customerAccount.data,
        creationDate: moment()
          .tz("Europe/Berlin")
          .format(),
      })

      this.$router.push({
        name: "CreateOrderSelectProductsView",
        params: {
          orderId: docRef.id,
        },
      });
    },
  }
}
</script>