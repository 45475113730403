<template>
  <div>
    <signed-out-navigation-component></signed-out-navigation-component>
    <b-container class="py-4">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col xl="8">
          <b-card
            class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary"
            body-class="p-5"
          >
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component :fill="'#212529'" :height="'40px'" :width="'40px'" :lineBreake="true" class="h2">
                Adobe Cloud Store
              </logo-component>
            </div>
            <b-form v-on:submit.prevent="signUp">
              <h3>{{ $t("signUpHeadline") }}</h3>
              <p
                class="text-muted"
                v-html="
                  $t('signUpText', {
                    companyName: settings.companyName,
                  })
                "
              ></p>
              <h4 class="mt-5">{{ $t("accessDataHeadline") }}</h4>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex flex-column justify-content-center">
                  <label class="mb-0">{{ $t("emailLabel") }}</label>
                  <small class="text-muted">{{ $t("accessDataEmailInfo") }}</small>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-email"
                    type="email"
                    v-model="signUpData.account.email"
                    :state="states.email"
                    aria-describedby="input-email-feedback"
                    required
                    trim
                  />
                  <b-form-invalid-feedback id="input-email-feedback">
                    {{ $t("emailInputErrorText") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex flex-column justify-content-center">
                  <label class="mb-0">{{ $t("confirmEmailLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-email-confirm"
                    type="email"
                    v-model="confirmEmail"
                    :state="states.confirmEmail"
                    aria-describedby="input-email-confirm-feedback"
                    required
                    trim
                  />
                  <b-form-invalid-feedback id="input-email-confirm-feedback">
                    {{ $t("confirmEmailInputErrorText") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <h4 class="mt-5">{{ $t("companyData") }}</h4>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{
                    $t("comlineCustomernumberLabel", {
                      companyName: settings.companyName,
                    })
                  }}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-comline-customernumber"
                    type="text"
                    v-model="signUpData.account.customernumber"
                    :state="states.customernumber"
                    aria-describedby="input-comline-customernumber-feedback"
                    trim
                    required
                  />
                  <b-form-invalid-feedback id="input-comline-customernumber-feedback">
                    {{
                      $t("comlineCustomernumberInputErrorText", {
                        companyName: settings.companyName,
                      })
                    }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex flex-column justify-content-center">
                  <label class="mb-0">{{ $t("companyLabel") }}</label>
                  <small class="text-muted">{{ $t("afterwardsNotChangeableInfo") }}</small>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-companyname"
                    type="text"
                    v-model="signUpData.companyProfile.companyName"
                    :state="states.companyName"
                    aria-describedby="input-companyname-feedback"
                    trim
                    required
                  />
                  <b-form-invalid-feedback id="input-companyname-feedback">
                    {{ $t("companyInputErrorText") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("addressLine1Label") }}</label>
                </b-col>
                <b-col>
                  <b-form-input type="text" v-model="signUpData.companyProfile.address.addressLine1" trim required />
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex flex-column justify-content-center">
                  <label class="mb-0">{{ $t("addressLine2Label") }}</label>
                  <small class="text-muted">{{ $t("addressLine2Info") }}</small>
                </b-col>
                <b-col>
                  <b-form-input type="text" v-model="signUpData.companyProfile.address.addressLine2" trim />
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("postalCodeLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-postalcode"
                    type="text"
                    v-model="signUpData.companyProfile.address.postalCode"
                    :state="states.postalCode"
                    aria-describedby="input-postalcode-feedback"
                    trim
                    required
                  />
                  <b-form-invalid-feedback id="input-postalcode-feedback">
                    {{ $t("postalCodeInputErrorText") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("cityLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input type="text" v-model="signUpData.companyProfile.address.city" trim required />
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex flex-column justify-content-center">
                  <label class="mb-0">{{ $t("countryLabel") }}</label>
                  <small class="text-muted">{{ $t("afterwardsNotChangeableInfo") }}</small>
                </b-col>
                <b-col>
                  <country-dropdown-component
                    :country="signUpData.companyProfile.address.country"
                    v-on:countrySelected="countrySelected"
                  ></country-dropdown-component>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("phoneNumberLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input type="text" v-model="signUpData.companyProfile.address.phoneNumber" trim required />
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("prefferredLanguageLabel") }}</label>
                </b-col>
                <b-col>
                  <preferred-language-dropdown-component
                    :preferredLanguage="signUpData.companyProfile.preferredLanguage"
                    v-on:languageSelected="languageSelected"
                  ></preferred-language-dropdown-component>
                </b-col>
              </b-row>
              <h4 class="mt-5">{{ $t("contactHeadline") }}</h4>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex flex-column justify-content-center">
                  <label class="mb-0">{{ $t("firstNameLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-firstname"
                    type="text"
                    v-model="signUpData.companyProfile.contacts[0].firstName"
                    :state="states.firstName"
                    aria-describedby="input-firstname-feedback"
                    trim
                    required
                  />
                  <b-form-invalid-feedback id="input-firstname-feedback">
                    {{ $t("firstNameInputErrorText") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex flex-column justify-content-center">
                  <label class="mb-0">{{ $t("lastNameLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-lastname"
                    type="text"
                    v-model="signUpData.companyProfile.contacts[0].lastName"
                    :state="states.lastName"
                    aria-describedby="input-lastname-feedback"
                    trim
                    required
                  />
                  <b-form-invalid-feedback id="input-lastname-feedback">
                    {{ $t("lastNameInputErrorText") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("emailLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-contact-email"
                    type="email"
                    v-model="signUpData.companyProfile.contacts[0].email"
                    :state="states.contactEmail"
                    aria-describedby="input-contact-email-feedback"
                    trim
                    required
                  />
                  <b-form-invalid-feedback id="input-contact-email-feedback">
                    {{ $t("emailInputErrorText") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("phoneNumberLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input type="text" v-model="signUpData.companyProfile.contacts[0].phoneNumber" trim required />
                </b-col>
              </b-row>
              <h4 class="mt-5">{{ $t("legalHeadline") }}</h4>
              <hr />
              <div class="mb-5">
                <b-form-checkbox v-model="acceptedComLineTermsAndConditions" switch>
                  {{ $t("termsAndConditionsLabel") }}
                  <router-link :to="{ name: 'ComlineTcView' }" target="_blank">{{
                    $t("termsAndConditionsComlineLink", {
                      companyName: settings.companyName,
                    })
                  }}</router-link>
                </b-form-checkbox>
                <b-form-checkbox v-model="acceptedAdobeTermsAndConditions" switch>
                  {{ $t("termsAndConditionsLabel") }}
                  <router-link :to="{ name: 'AdobeTcView' }" target="_blank">{{
                    $t("termsAndConditionsAdobeLink")
                  }}</router-link>
                </b-form-checkbox>
              </div>

              <submit-button-component
                :state="submitButtonState"
                :label="$t('signUpButton')"
                :block="true"
              ></submit-button-component>
            </b-form>
          </b-card>
          <div class="d-flex flex-column align-items-center mt-4">
            {{ $t("signInLinkText") }}
            <router-link :to="{ name: 'LogInView' }">{{ $t("signInViewLink") }}</router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PreferredLanguageDropdownComponent from "../components/PreferredLanguageDropdownComponent.vue";
import LogoComponent from "../components/LogoComponent.vue";
import CountryDropdownComponent from "../components/CountryDropdownComponent.vue";
import isEmail from "validator/es/lib/isEmail";
import equals from "validator/es/lib/equals";
import isNumeric from "validator/es/lib/isNumeric";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import SignedOutNavigationComponent from "../components/SignedOutNavigationComponent.vue";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import { mapState } from "vuex";

export default {
  name: "ResellerRegisterView",
  components: {
    LogoComponent,
    PreferredLanguageDropdownComponent,
    CountryDropdownComponent,
    SubmitButtonComponent,
    SignedOutNavigationComponent,
  },
  data() {
    return {
      signUpData: {
        account: {
          customernumber: "",
          email: "",
          confirmedByResellerDate: "",
          confirmedByDistributorDate: "",
        },
        companyProfile: {
          companyName: "",
          preferredLanguage: "de-DE",
          address: {
            addressLine1: "",
            addressLine2: "",
            postalCode: "",
            city: "",
            region: "00",
            country: this.settings?.defaultCountry || 'DE',
            phoneNumber: "",
          },
          contacts: [
            {
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
            },
          ],
        },
      },
      confirmEmail: "",
      isWorking: false,
      acceptedAdobeTermsAndConditions: false,
      acceptedComLineTermsAndConditions: false,
      states: {
        email: null,
        customernumber: null,
        contactEmail: null,
        confirmEmail: null,
        companyName: null,
        postalCode: null,
        firstName: null,
        lastName: null,
      },
    };
  },
  computed: {
    ...mapState(["settings"]),
    submitButtonState() {
      if (
        !this.states.postalCode ||
        !this.states.companyName ||
        !this.states.email ||
        !this.states.confirmEmail ||
        !this.states.customernumber ||
        !this.acceptedAdobeTermsAndConditions ||
        !this.acceptedComLineTermsAndConditions ||
        this.signUpData.account.customernumber === "" ||
        this.signUpData.companyProfile.address.addressLine1 === "" ||
        this.signUpData.companyProfile.address.city === "" ||
        this.signUpData.companyProfile.address.phoneNumber === "" ||
        !this.states.firstName ||
        !this.states.lastName ||
        this.signUpData.companyProfile.contacts[0].email === "" ||
        this.signUpData.companyProfile.contacts[0].phoneNumber === ""
      ) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    languageSelected(preferredLanguage) {
      this.signUpData.companyProfile.preferredLanguage = preferredLanguage;
    },
    countrySelected(country) {
      this.signUpData.companyProfile.address.country = country;
    },
    async signUp() {
      this.isWorking = true;

      const saveRegistration = httpsCallable(functions, "saveRegistration");
      await saveRegistration(this.signUpData)
        .then(() => {
          this.$store.dispatch("alertSuccess", {
            message: `
              Sie erhalten in Kürze eine E-Mail an ${this.signUpData.account.email}.<br /><br />
              Bitte überprüfen Sie auch Ihren Spam-Ordner.<br /><br />
              Bestätigen Sie Ihre Registrierung indem Sie auf den Link in der E-Mail klicken.
            `,
            show: true,
            noFooter: false,
          });

          this.clearForm();
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch("alertError", {
            message: `
              Es ist ein Fehler aufgetreten.<br />
              Bitte versuchen Sie es später erneut.
            `,
            show: true,
            noFooter: false,
          });
        });

      this.isWorking = false;
    },
    clearForm() {
      this.signUpData.account.customernumber = "";
      this.signUpData.account.email = "";
      this.signUpData.companyProfile.companyName = "";
      this.signUpData.companyProfile.preferredLanguage = "de-DE";
      this.signUpData.companyProfile.address.addressLine1 = "";
      this.signUpData.companyProfile.address.addressLine2 = "";
      this.signUpData.companyProfile.address.postalCode = "";
      this.signUpData.companyProfile.address.city = "";
      this.signUpData.companyProfile.address.country = this.settings?.defaultCountry || 'DE';
      this.signUpData.companyProfile.address.phoneNumber = "";
      this.signUpData.companyProfile.contacts[0].firstName = "";
      this.signUpData.companyProfile.contacts[0].lastName = "";
      this.signUpData.companyProfile.contacts[0].email = "";
      this.signUpData.companyProfile.contacts[0].phoneNumber = "";
      this.confirmEmail = "";
      this.acceptedAdobeTermsAndConditions = false;
      this.acceptedComLineTermsAndConditions = false;
      this.states.email = null;
      this.states.customernumber = null;
      this.states.contactEmail = null;
      this.states.confirmEmail = null;
      this.states.postalCode = null;
      this.states.companyName = null;
      this.states.firstName = null;
      this.states.lastName = null;
    },
  },
  watch: {
    "signUpData.account.email": function (email) {
      if (email === "") {
        this.states.email = null;
      } else {
        this.states.email = isEmail(email);
      }
    },
    "confirmEmail"(email) {
      if (email === "") {
        this.states.confirmEmail = null;
      } else {
        this.states.confirmEmail = equals(this.signUpData.account.email, this.confirmEmail);
      }
    },
    "signUpData.account.customernumber": function (customernumber) {
      if (customernumber === "") {
        this.states.customernumber = null;
      } else {
        this.states.customernumber = isNumeric(customernumber);
      }
    },
    "signUpData.companyProfile.contacts": {
      deep: true,
      handler: function (contacts) {
        if (contacts[0].email === "") {
          this.states.contactEmail = null;
        } else {
          this.states.contactEmail = isEmail(contacts[0].email);
        }

        if (contacts[0].firstName === "") {
          this.states.firstName = null;
        } else {
          if (contacts[0].firstName.match(this.$store.state.nameRegex)) {
            this.states.firstName = true;
          } else {
            this.states.firstName = false;
          }
        }

        if (contacts[0].lastName === "") {
          this.states.lastName = null;
        } else {
          if (contacts[0].lastName.match(this.$store.state.nameRegex)) {
            this.states.lastName = true;
          } else {
            this.states.lastName = false;
          }
        }
      },
    },
    "signUpData.companyProfile.companyName": function (companyName) {
      if (companyName === "") {
        this.states.companyName = null;
      } else {
        if (companyName.match(this.$store.state.companyNameRegex)) {
          this.states.companyName = true;
        } else {
          this.states.companyName = false;
        }
      }
    },
    "signUpData.companyProfile.address.country": function (country) {
      const postalCodeRegex = this.$store.state.postalCodeRegex;

      if (country === "") {
        this.states.postalCode = null;
      } else {
        if (this.signUpData.companyProfile.address.postalCode.match(postalCodeRegex[country])) {
          this.states.postalCode = true;
        } else {
          this.states.postalCode = false;
        }
      }
    },
    "signUpData.companyProfile.address.postalCode": function (postalCode) {
      const postalCodeRegex = this.$store.state.postalCodeRegex;

      if (postalCode === "") {
        this.states.postalCode = null;
      } else {
        if (postalCode.match(postalCodeRegex[this.signUpData.companyProfile.address.country])) {
          this.states.postalCode = true;
        } else {
          this.states.postalCode = false;
        }
      }
    },
    "$i18n.locale": function (locale) {
      if (locale === "de") {
        this.signUpData.companyProfile.preferredLanguage = "de-DE";
      } else {
        this.signUpData.companyProfile.preferredLanguage = "en-US";
      }
    },
  },
  created() {
    if (this.$i18n.locale === "de") {
      this.signUpData.companyProfile.preferredLanguage = "de-DE";
    } else {
      this.signUpData.companyProfile.preferredLanguage = "en-US";
    }
  },
  mounted() {
    const changeSelection =  this.settings?.defaultCountry || 'DE';
    this.signUpData.companyProfile.address.country = changeSelection
  }
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
