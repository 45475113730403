<template>
  <div class="py-4">
    <b-container class="h-100">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col xl="6">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">

            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreake="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>
            
            <div v-if="message" class="d-flex flex-column align-items-center">
              <div class="icon rounded-circle d-flex justify-content-center align-items-center alert-success text-success mb-3 flex-shrink-0 p-3">
                <fa-icon :icon="['fa-regular', 'fa-check']" size="2x"></fa-icon>
              </div>
              <div v-html="message"></div>
            </div>
            <b-spinner variant="secondary" v-else></b-spinner>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import moment from "moment-timezone";
import { firestore } from "../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";

export default {
  name: "ConfirmRegistrationView",
  components: {
    LogoComponent,
  },
  data() {
    return {
      message: "",
    };
  },
  async mounted() {
    const docRef = doc(firestore, "registrations", this.$route.params.registrationId);
    await updateDoc(docRef, {
      "account.confirmedByResellerDate": moment().tz("Europe/Berlin").format(),
    });

    this.message = this.$t("confirmSignUpMessage");
  },
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
