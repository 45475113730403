import LogInView from "../../views/LogInView.vue";
import ForgotPasswordView from "../../views/ForgotPasswordView.vue";
import ResetPasswordView from "../../views/ResetPasswordView.vue";
import ResetPasswordSuccessView from "../../views/ResetPasswordSuccessView.vue";
import LoggedInView from "../../views/LoggedInView.vue";
import HomepageView from "../../views/HomepageView.vue";
import AdminHomepageView from "../../views/AdminHomepageView.vue";
import FaqView from "../../views/FaqView.vue";
import CustomersView from "../../views/CustomersView.vue";
import CustomerView from "../../views/CustomerView.vue";
import CreateOrderView from "../../views/CreateOrderView.vue";
import CreateOrderSelectCustomerView from "../../views/CreateOrderSelectCustomerView.vue";
import CreateOrderSelectProductsView from "../../views/CreateOrderSelectProductsView.vue";
import CreateOrderCheckoutView from "../../views/CreateOrderCheckoutView.vue";
import CreateOrderCompletedView from "../../views/CreateOrderCompletedView.vue";
import ResellerRegisterView from "../../views/ResellerRegisterView.vue";
import ConfirmRegistrationView from "../../views/ConfirmRegistrationView.vue";
import ConfirmResellerView from "../../views/ConfirmResellerView.vue";
import ResellerCreateAccountView from "../../views/ResellerCreateAccountView.vue";
import ResellerCreateUserAccountView from "../../views/ResellerCreateUserAccountView.vue";
import ResellerProfileView from "../../views/ResellerProfileView.vue";
import ResellerContactsView from "../../views/ResellerContactsView.vue";
import ResellerNotificationsView from "../../views/ResellerNotificationsView.vue";
import ResellerUserManagementView from "../../views/ResellerUserManagementView.vue";
import ComlineTcView from "../../views/ComlineTcView.vue";
import AdobeTcView from "../../views/AdobeTcView.vue";
import ImprintView from "../../views/ImprintView.vue";
import PrivacyProtectionView from "../../views/PrivacyProtectionView.vue";
import CreateCustomerView from "../../views/CreateCustomerView.vue";
import CustomerSubscriptionsView from "../../views/CustomerSubscriptionsView.vue";
import CustomerOrdersView from "../../views/CustomerOrdersView.vue";
import CustomerProfileView from "../../views/CustomerProfileView.vue";
import CustomerContactsView from "../../views/CustomerContactsView.vue";
import CustomerRenewalOfferView from "../../views/CustomerRenewalOfferView.vue";
import CustomerRenewalOrderNumber from "../../views/CustomerRenewalOrderNumber.vue";
import Customer3YC from "../../views/Customer3YCView.vue";
import ExtendedTerm from "../../views/CustomerExtendedTermView.vue";
import TransferSubscriptionsView from "../../views/TransferSubscriptionsView.vue";
import TransferSubscriptionsPrevieOffersView from "../../views/TransferSubscriptionsPrevieOffersView.vue";
import TransfersView from "../../views/TransfersView.vue";
import ResellerEditLoginCredentialsView from "../../views/ResellerEditLoginCredentialsView.vue";
import ConfirmAccountEmailChangeView from "../../views/ConfirmAccountEmailChangeView.vue";

const routes = [
  {
    path: "*",
    redirect: "/log-in/",
  },
  {
    path: "/",
    redirect: "/log-in/",
  },
  {
    path: "/log-in/",
    name: "LogInView",
    component: LogInView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/token-log-in/:token",
    name: "TokenLogInView",
    component: LogInView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgot-password/",
    name: "ForgotPasswordView",
    component: ForgotPasswordView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password/",
    name: "ResetPasswordView",
    component: ResetPasswordView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password-success/",
    name: "ResetPasswordSuccessView",
    component: ResetPasswordSuccessView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/register/",
    name: "ResellerRegisterView",
    component: ResellerRegisterView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/confirm-registration/:registrationId/",
    name: "ConfirmRegistrationView",
    component: ConfirmRegistrationView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/confirm-reseller/:registrationId/",
    name: "ConfirmResellerView",
    component: ConfirmResellerView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/create-account/:registrationId/",
    name: "ResellerCreateAccountView",
    component: ResellerCreateAccountView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/change-account-email/:token/",
    name: "ConfirmAccountEmailChangeView",
    component: ConfirmAccountEmailChangeView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/create-user-account/",
    name: "ResellerCreateUserAccountView",
    component: ResellerCreateUserAccountView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/terms-and-conditions/",
    name: "ComlineTcView",
    component: ComlineTcView,
    meta: {
      requiresAuth: false,
      title: " - AGB ComLine",
    },
  },
  {
    path: "/adobe-terms-and-conditions/",
    name: "AdobeTcView",
    component: AdobeTcView,
    meta: {
      requiresAuth: false,
      title: " - AGB Adobe VIPMP",
    },
  },
  {
    path: "/imprint/",
    name: "ImprintView",
    component: ImprintView,
    meta: {
      requiresAuth: false,
      title: " - Impressum",
    },
  },
  {
    path: "/privacy-protection/",
    name: "PrivacyProtectionView",
    component: PrivacyProtectionView,
    meta: {
      requiresAuth: false,
      title: " - Datenschutz",
    },
  },
  {
    path: "/admin/:customerToken",
    name: "AdminHomepageView",
    component: AdminHomepageView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/-/homepage/",
    component: LoggedInView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/-/homepage/",
        name: "HomepageView",
        component: HomepageView,
      },
      {
        path: "/-/faq/",
        name: "FaqView",
        component: FaqView,
      },
      {
        path: "/-/customers/",
        name: "CustomersView",
        component: CustomersView,
      },
      {
        path: "/-/customers/:customerId/subscriptions/",
        component: CustomerView,
        children: [
          {
            path: "/-/customers/:customerId/subscriptions/",
            name: "CustomerSubscriptionsView",
            component: CustomerSubscriptionsView,
          },
          {
            path: "/-/customers/:customerId/orders/",
            name: "CustomerOrdersView",
            component: CustomerOrdersView,
          },
          {
            path: "/-/customers/:customerId/profile/",
            name: "CustomerProfileView",
            component: CustomerProfileView,
          },
          {
            path: "/-/customers/:customerId/contacts/",
            name: "CustomerContactsView",
            component: CustomerContactsView,
          },
          {
            path: "/-/customers/:customerId/renewal-offer/",
            name: "CustomerRenewalOfferView",
            component: CustomerRenewalOfferView,
          },
          {
            path: "/-/customers/:customerId/renewal-order-number/",
            name: "CustomerRenewalOrderNumber",
            component: CustomerRenewalOrderNumber,
          },
          {
            path: "/-/customers/:customerId/3yc/",
            name: "Customer3YC",
            component: Customer3YC,
          },
          {
            path: "/-/customers/:customerId/extended-term/",
            name: "ExtendedTerm",
            component: ExtendedTerm,
          },
        ],
      },
      {
        path: "/-/create-customer/",
        name: "CreateCustomerView",
        component: CreateCustomerView,
      },
      {
        path: "/-/transfers/",
        name: "TransfersView",
        component: TransfersView,
      },
      {
        path: "/-/transfer-subscriptions/",
        name: "TransferSubscriptionsView",
        component: TransferSubscriptionsView,
      },
      {
        path: "/-/transfer-subscriptions/:membershipId/:docId",
        name: "TransferSubscriptionsPrevieOffersView",
        component: TransferSubscriptionsPrevieOffersView,
      },
      {
        path: "/-/create-order/select-customer/",
        component: CreateOrderView,
        children: [
          {
            path: "/-/create-order/select-customer/",
            name: "CreateOrderSelectCustomerView",
            component: CreateOrderSelectCustomerView,
          },
          {
            path: "/-/create-order/:orderId/select-products/",
            name: "CreateOrderSelectProductsView",
            component: CreateOrderSelectProductsView,
          },
          {
            path: "/-/create-order/:orderId/checkout/",
            name: "CreateOrderCheckoutView",
            component: CreateOrderCheckoutView,
          },
          {
            path: "/-/create-order/completed/",
            name: "CreateOrderCompletedView",
            component: CreateOrderCompletedView,
          },
        ],
      },
      {
        path: "/-/settings/profile/",
        name: "ResellerProfileView",
        component: ResellerProfileView,
      },
      {
        path: "/-/settings/contactprofiles/",
        name: "ResellerContactsView",
        component: ResellerContactsView,
      },
      {
        path: "/-/settings/notifications/",
        name: "ResellerNotificationsView",
        component: ResellerNotificationsView,
      },
      {
        path: "/-/settings/users/",
        name: "ResellerUserManagementView",
        component: ResellerUserManagementView,
      },
      {
        path: "/-/settings/edit-login-credentials",
        name: "ResellerEditLoginCredentialsView",
        component: ResellerEditLoginCredentialsView,
      },
    ],
  },
];

export default routes;
