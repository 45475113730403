import { render, staticRenderFns } from "./CreateOrderCompletedView.vue?vue&type=template&id=212a3ffe&scoped=true&"
import script from "./CreateOrderCompletedView.vue?vue&type=script&lang=js&"
export * from "./CreateOrderCompletedView.vue?vue&type=script&lang=js&"
import style0 from "./CreateOrderCompletedView.vue?vue&type=style&index=0&id=212a3ffe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212a3ffe",
  null
  
)

export default component.exports