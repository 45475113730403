<template>
  <div>
    <signed-out-navigation-component></signed-out-navigation-component>
    <b-container class="py-4">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col md="8" lg="6" xl="5">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreake="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>
            <b-form v-on:submit.prevent="sendResetPasswordEmail">
              <h3>{{ $t("forgotPasswordHeadline") }}</h3>
              <p class="text-muted" v-html="$t('forgotPasswordText')"></p>
              <b-form-group>
                <label>{{ $t("emailLabel") }}</label>
                <b-form-input id="input-email" type="email" v-model="email" :state="states.email" aria-describedby="input-email-feedback" trim />
                <b-form-invalid-feedback id="input-email-feedback">
                  {{ $t("emailInputErrorText") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <submit-button-component
                :state="submitButtonState"
                :label="$t('resetPasswordButton')"
                :block="true"
              ></submit-button-component>
            </b-form>
          </b-card>
          <div class="d-flex flex-column align-items-center mt-4">
            <router-link :to="{ name: 'LogInView' }">{{ $t("signInViewLink") }}</router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import isEmail from "validator/es/lib/isEmail";
import SignedOutNavigationComponent from '../components/SignedOutNavigationComponent.vue';
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";

export default {
  name: "ForgotPasswordView",
  components: {
    LogoComponent,
    SubmitButtonComponent,
    SignedOutNavigationComponent,
  },
  data() {
    return {
      isWorking: false,
      email: "",
      language: "de-DE",
      states: {
        email: null,
      },
    };
  },
  computed: {
    submitButtonState() {
      if (!isEmail(this.email)) {
        return "missingFields";
      }

      if (this.isWorking) {
        return "working";
      }

      return "";
    },
  },
  watch: {
    email(email) {
      if (email === "") {
        this.states.email = null;
      } else {
        this.states.email = isEmail(email);
      }
    },
    "$i18n.locale": function(locale) {
      if (locale === "de") {
        this.language = "de-DE";
      } else {
        this.language = "en-US";
      }
    },
  },
  methods: {
    async sendResetPasswordEmail() {
      this.isWorking = true;

      const sendResetPasswordEmail = httpsCallable(functions, "sendResetPasswordEmail");
      await sendResetPasswordEmail({
        email: this.email,
        language: this.language,
      })
        .then(() => {
          this.isWorking = false;
          this.$store.dispatch("alertSuccess", {
            message: "Falls Ihre E-Mail-Adresse in unserem System ist, erhalten Sie in Kürze eine E-Mail zum zurücksetzen Ihres Passworts.",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {
          this.isWorking = false;
          console.error(error);
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });
    }
  },
  created() {
    if (this.$i18n.locale === "de") {
      this.language = "de-DE";
    } else {
      this.language = "en-US";
    }
  },
}
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
