<template>
  <b-form v-on:submit.prevent="updateCustomerAccount">
    <b-card footer-class="border-0 bg-light">
      <b-row>
        <b-col class="d-flex flex-column justify-content-center">
          <label class="mb-0">{{ $t("customerNumber") }}</label>
          <small class="text-muted">{{ $t("customerNumberIsNotRequired") }}</small>
        </b-col>
        <b-col>
          <b-form-input v-model="externalReferenceId"></b-form-input>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex flex-column justify-content-center">
          <label class="mb-0">{{ $t("companyLabel") }}</label>
          <small class="text-muted">{{ $t("notChangeableInfo") }}</small>
        </b-col>
        <b-col>
          <b-form-input disabled :value="profile.companyProfile.companyName"></b-form-input>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">{{ $t("addressLine1Label") }}</label>
        </b-col>
        <b-col>
          <b-form-input v-model="profile.companyProfile.address.addressLine1"></b-form-input>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">{{ $t("addressLine2Label") }}</label>
        </b-col>
        <b-col>
          <b-form-input v-model="profile.companyProfile.address.addressLine2"></b-form-input>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">{{ $t("postalCodeLabel") }}</label>
        </b-col>
        <b-col>
          <b-form-input
            id="input-postalcode"
            type="text"
            v-model="profile.companyProfile.address.postalCode"
            :state="states.postalCode"
            aria-describedby="input-postalcode-feedback"
            trim
            required
          />
          <b-form-invalid-feedback id="input-postalcode-feedback">
            {{ $t("postalCodeInputErrorText") }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">{{ $t("cityLabel") }}</label>
        </b-col>
        <b-col>
          <b-form-input v-model="profile.companyProfile.address.city"></b-form-input>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex flex-column justify-content-center">
          <label class="mb-0">{{ $t("countryLabel") }}</label>
          <small class="text-muted">{{ $t("notChangeableInfo") }}</small>
        </b-col>
        <b-col>
          <country-dropdown-component
            :country="profile.companyProfile.address.country"
            disabled
          ></country-dropdown-component>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">{{ $t("phoneNumberLabel") }}</label>
        </b-col>
        <b-col>
          <b-form-input v-model="profile.companyProfile.address.phoneNumber"></b-form-input>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">{{ $t("prefferredLanguageLabel") }}</label>
        </b-col>
        <b-col>
          <preferred-language-dropdown-component
            :preferredLanguage="profile.companyProfile.preferredLanguage"
            v-on:languageSelected="languageSelected"
            class="my-2"
          ></preferred-language-dropdown-component>
        </b-col>
      </b-row>

      <div v-if="customerHasNoSubscriptions">
        <h5 class="mt-5 font-weight-bold">{{ $t("marketSegment") }}</h5>
        <hr />

        <b-row>
          <b-col class="d-flex flex-column justify-content-top">
            <small class="text-muted">{{ $t("changeableIfCustomerHasNoActiveSubscriptions") }}</small>
          </b-col>
          <b-col>
            <b-form-radio v-model="profile.companyProfile.marketSegment" value="COM" v-if="checkIfResellerIsInMarketSegment('COM')">Commercial</b-form-radio>
            <b-form-radio v-model="profile.companyProfile.marketSegment" value="EDU" v-if="checkIfResellerIsInMarketSegment('EDU')">Education</b-form-radio>
            <b-form-radio v-model="profile.companyProfile.marketSegment" value="GOV" v-if="checkIfResellerIsInMarketSegment('GOV')">Government</b-form-radio>
          </b-col>
        </b-row>
      </div>

      <template #footer>
        <div class="d-flex justify-content-end">
          <submit-button-component :state="submitButtonState" :label="$t('save')" :block="false" class="my-2"></submit-button-component>
        </div>
      </template>
    </b-card>
  </b-form>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import PreferredLanguageDropdownComponent from "../components/PreferredLanguageDropdownComponent.vue";
import CountryDropdownComponent from "../components/CountryDropdownComponent.vue";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { getDocs, where, query, collection } from "firebase/firestore";

export default {
  name: "CustomerProfileView",
  components: {
    SubmitButtonComponent,
    PreferredLanguageDropdownComponent,
    CountryDropdownComponent,
  },
  props: {
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
    subscriptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      externalReferenceId: "",
      profile: this.customer,
      isWorking: false,
      states: {
        postalCode: null
      }
    };
  },
  mounted() {
    const postalCodeRegex = this.$store.state.postalCodeRegex;
    this.getCustomernumberInResellersystem()
    if ( this.profile.companyProfile.address.postalCode.match(postalCodeRegex[this.profile.companyProfile.address.country]) ) {
      this.states.postalCode = true;
    } else {
      this.states.postalCode = false;
    }
  },
  computed: {
    submitButtonState() {
      if (
        !this.states.postalCode ||
        this.profile.companyProfile.address.addressLine1 === "" ||
        this.profile.companyProfile.address.city === ""
      ) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
    customerHasNoSubscriptions() {
      return this.subscriptions.length === 0;
    },
  },
  methods: {
    checkIfResellerIsInMarketSegment(marketSegment) {
      return this.$store.state.resellerData.companyProfile.marketSegments.includes(marketSegment);
    },
    languageSelected(preferredLanguage) {
      this.profile.companyProfile.preferredLanguage = preferredLanguage;
    },
    async updateCustomerAccount() {
      this.isWorking = true;

      this.profile.companyProfile.marketSubSegments = this.profile.companyProfile.marketSubSegments.filter((subSegment) => subSegment !== "");

      const updateCustomerAccount = httpsCallable(functions, "updateCustomerAccount");
      await updateCustomerAccount({
        customerId: this.profile.customerId,
        profile: {
          externalReferenceId: this.externalReferenceId,
          companyProfile: this.profile.companyProfile,
        },
      })
        .then((result) => {
          this.profile = result.data;
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });

        this.isWorking = false;
    },
    async getCustomernumberInResellersystem() {
      const q = query(collection(firestore, "customers"), where("customerId", "==", this.customer.customerId));

      const querySnapshot = await getDocs(q);
      const dataFound = querySnapshot.docs[0].data() || {}

      this.externalReferenceId = dataFound?.customernumberInResellersystem
    }
  },
  watch: {
    "profile.companyProfile.address.postalCode": function(postalCode) {
      const postalCodeRegex = this.$store.state.postalCodeRegex;

      if (postalCode === "") {
        this.states.postalCode = null;
      } else {
        if ( postalCode.match(postalCodeRegex[this.profile.companyProfile.address.country]) ) {
          this.states.postalCode = true;
        } else {
          this.states.postalCode = false;
        }
      }
    },
  }
};
</script>
