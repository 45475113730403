import moment from "moment-timezone";
import i18n from "../i18n";

const accountStatus = (status) => {
  if (status === "1000") {
    return i18n.t("active");
  } else if (status === "1002") {
    return i18n.t("pending");
  } else if (status === "1004") {
    return i18n.t("inactive");
  } else if (status === "1010") {
    return i18n.t("inactiveInvalidAddress");
  } else if (status === "1012") {
    return i18n.t("inactiveAccountBlocked");
  } else if (status === "1014") {
    return i18n.t("inactiveCustomerAlreadyExists");
  }
};

const subscriptionStatus = (status) => {
  if (status === "1000") {
    return i18n.t("active");
  } else if (status === "1002") {
    return i18n.t("pending");
  } else if (status === "1004") {
    return i18n.t("inactive");
  } else if (status === "init") {
    return i18n.t("transferNotSubmitted");
  }
};

const orderStatus = (status) => {
  if (status === "1000") {
    return i18n.t("complete");
  } else if (status === "1002") {
    return i18n.t("open");
  } else if (status === "1004") {
    return i18n.t("failed");
  } else if (status === "1008") {
    return i18n.t("canceled");
  } else if (status === "1020") {
    return i18n.t("failedDistributorInactive");
  } else if (status === "1022") {
    return i18n.t("failedResellerInactive");
  } else if (status === "1024") {
    return i18n.t("failedCustomerInactive");
  } else if (status === "1026") {
    return i18n.t("failedCustomerIdInavild");
  }
};

const currency = (value, maximumFractionDigits) => {
  if (!maximumFractionDigits) {
    maximumFractionDigits = 2;
  }
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);
};

const dateTime = (date) => {
  if (!date) {
    return date
  }
  return moment(date).format("DD.MM.YYYY HH:MM:ss");
};

const date = (date) => {
  if (!date) {
    return date
  }
  return moment(date).format("DD.MM.YYYY");
};

const productName = (name) => {
  return name.replace(/(\*\*)/g, "");
};

const productNameWithoutLevel = (name) => {
  // const result = name.replace(/\s*\([^)/]*\)\s*(\**)/g, "");
  const regex1 = /\(MP\)/g; // (MP)
  const regex2 = /\*\*/g; // **
  const regex3 = /\([0-9]+[-][0-9]+\)/g; // (0-9999)
  const regex4 = /\s{2,}/g; // Zusammenhängende Leerzeichen
  const result = name.replace(regex1, "").replace(regex2, "").replace(regex3, "").replace(regex4, "");
  return result;
};

const itemNumberObject = (itemNumber) => {
  const itemNumberChunks = itemNumber.split("-");
  const productChunk = itemNumberChunks[3].split("");

  return {
    productCode: productChunk[0] + productChunk[1] + productChunk[2] + productChunk[3],
    volumeLevel: parseInt(productChunk[4]) + 1,
    language: productChunk[5],
    productType: itemNumberChunks[2],
    promoCode: productChunk[6],
    postfix: itemNumberChunks?.[4] || "",
    marketSegment: itemNumberChunks[1],
  };
}

const marketSegment = (segment) => { 
  if (segment === "COM") {
    return "Commercial"
  } else if (segment === "EDU") {
    return "Education"
  } else if (segment === "GOV") {
    return "Government"
  }
}

export {
  accountStatus,
  subscriptionStatus,
  orderStatus,
  currency,
  dateTime,
  date,
  productName,
  productNameWithoutLevel,
  itemNumberObject,
  marketSegment,
};
