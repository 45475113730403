<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <div>
          <h3 class="font-weight-bold mb-0">
            {{ $t("createOrder") }}
          </h3>
        </div>
      </b-container>
    </div>
    <b-container class="pt-4">
      <limit-check-component class="mb-4"></limit-check-component>

      <h5 class="font-weight-bold">{{ $t("activeCustomers") }}</h5>
      <div class="text-muted mb-4">{{ $t("activeCustomersCreateOrderInfo") }}</div>
      <b-card no-body>
        <b-card-header class="p-3 d-flex">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="bg-white pr-0"
                ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
              ></b-input-group-text>
            </template>
            <b-input :placeholder="$t('searchByCustomerCustomerId')" class="border-left-0"  @input="debounceInput"></b-input>
          </b-input-group>
          <b-pagination
            class="ml-3 mb-0"
            v-show="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table"
          ></b-pagination>
        </b-card-header>
        <b-table
          :items="tableDataArrayFiltered"
          :fields="customerFields"
          class="mb-0"
          show-empty
          :empty-filtered-text="$t('emptyFilteredText')"
          :empty-text="$t('noCustomers')"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :per-page="perPage"
          :current-page="currentPage"
          :no-local-sorting="true"
        >
          <template #empty="scope">
            <div class="text-center font-italic">{{ scope.emptyText }}</div>
          </template>

          <template #emptyfiltered="scope">
            <div class="text-center font-italic">
              {{ scope.emptyFilteredText }}
            </div>
          </template>

          <template #cell(companyName)="data">
            <b-button
              variant="link"
              class="p-0 d-inline-flex align-items-center"
              v-on:click="selectCustomer(data.item, data.index)"
            >
              <span class="mr-2">{{ data.item.companyProfile.companyName }}</span>
              <b-spinner
                v-show="showSpinnerForIndex === data.index"
                label="Spinning"
                variant="secondary"
                small
              ></b-spinner> </b-button
            ><br />
            <small class="text-muted" v-b-tooltip.hover :title="$t('customerId')">
              <fa-icon :icon="['fa-regular', 'fa-id-card']" class="mr-1"></fa-icon>{{ data.item.customerId }}
            </small>
          </template>

          <template #cell(customerId)="data">
            {{ data.item.customerId }}
          </template>
        </b-table>
      </b-card>

      <h5 class="font-weight-bold mt-4">{{ $t("ordersInProgress") }}</h5>
      <div class="text-muted mb-4">
        {{ $t("ordersInProgressText") }}
      </div>
      <b-card no-body>
        <b-card-header class="p-3">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="bg-white pr-0"
                ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
              ></b-input-group-text>
            </template>
            <b-input :placeholder="$t('searchByCustomerCustomerId')" class="border-left-0" v-model="ordersFilter"></b-input>
          </b-input-group>
        </b-card-header>
        <b-table
          ref="orders"
          :items="getOrders"
          :fields="ordersFields"
          class="mb-0"
          :filter="ordersFilter"
          show-empty
          :empty-filtered-text="$t('emptyFilteredText')"
          :empty-text="$t('noOrdersText')"
          sort-icon-left
        >
          <template #empty="scope">
            <div class="text-center font-italic">{{ scope.emptyText }}</div>
          </template>

          <template #emptyfiltered="scope">
            <div class="text-center font-italic">
              {{ scope.emptyFilteredText }}
            </div>
          </template>

          <template #cell(companyName)="data">
            <router-link
              :to="{
                name: 'CreateOrderSelectProductsView',
                params: {
                  orderId: data.item.orderId,
                },
              }"
            >
              {{ data.item.customer.companyProfile.companyName }} </router-link
            ><br />
            <small class="text-muted" v-b-tooltip.hover :title="$t('customerId')"
              ><fa-icon :icon="['fa-regular', 'fa-id-card']" class="mr-1"></fa-icon>{{ data.item.customer.customerId }}</small
            >
          </template>

          <template #cell(creationDate)="data">
            <fa-icon :icon="['fa-regular', 'fa-clock']" class="mr-1"></fa-icon>{{ data.item.creationDate | date }}
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-end">
              <b-button size="sm" variant="outline-danger" v-on:click="deleteOrderWithId(data.item)">
                <fa-icon :icon="['fa-regular', 'fa-trash']" class="mr-2"></fa-icon>{{ $t("delete") }}
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-container>
 
    <b-modal
      ref="delete-modal"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      v-on:cancel="clearOrderToBeDeleted()"
      v-on:hidden="clearOrderToBeDeleted()"
    >
      <div>{{ $t("deleteOrderText") }}</div>
      <div
        class="mt-3"
        v-if="orderToBeDeleted !== null && orderToBeDeleted.products.length > 0"
        v-html="
          $t('numberOfProductsInCart', {
            quantity: orderToBeDeleted.products.length,
          })
        "
      ></div>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" v-on:click="cancel()">
          {{ $t("abort") }}
        </b-button>

        <b-button v-on:click="deleteOrder()" variant="danger">
          {{ $t("delete") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import LimitCheckComponent from "../components/LimitCheckComponent.vue";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { collection, where, getDocs, query, addDoc, deleteDoc, onSnapshot, doc } from "firebase/firestore";
import { debounce } from "debounce";

export default {
  components: { LimitCheckComponent },
  name: "CreateOrderSelectCustomerView",
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      filter: "",
      filterDebounce: "",
      sortBy: "companyName",
      sortDesc: false,
      customersFilter: "",
      customerFields: [
        {
          key: "companyName",
          label: this.$t("customer"),
          sortable: true,
        },
      ],
      customers: [],
      ordersFilter: "",
      ordersFields: [
        {
          key: "companyName",
          label: this.$t("customer"),
          sortable: false,
        },
        {
          key: "creationDate",
          label: this.$t("creationTime"),
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
      orderToBeDeleted: null,
      showSpinnerForIndex: null,
      contentClass: ["border-0 shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
    };
  },
  async mounted() {
    let customersSorting = sessionStorage.getItem("customersSortingCreateOrder");
    if (customersSorting !== null) {
      customersSorting = JSON.parse(customersSorting);
      this.sortBy = customersSorting.sortBy;
      this.sortDesc = customersSorting.sortDesc;
    }
    await this.getCustomers()
  },
  computed: {
    tableDataArrayFiltered() {
      this.countUp()
      let filteredData = this.customers;

      if(this.sortBy === "companyName"){
        filteredData.sort((a, b) => {
          return ( this.sortDesc ? -1 : 1) * a.companyProfile.companyName.localeCompare(b.companyProfile.companyName);
        });
      }

      if (
        this.filterDebounce !== "" &&
        this.filterDebounce !== undefined &&
        this.filterDebounce !== null
      ) {
        const toreturn = filteredData.filter((obj) => {
          let check = false;
          let each = Object.keys(obj);

            for (var i = 0; i < each.length; i++) {
              String(obj[each[i]])
                .toLowerCase()
                .includes(String(this.filterDebounce.toLowerCase())) && (check = true);
            }

            if (obj.companyProfile !== undefined && obj.companyProfile[0] !== "") {
              let orderEach = Object.keys(obj.companyProfile);
              for (var l = 0; l < orderEach.length; l++) {
                  String(obj.companyProfile[orderEach[l]])
                    .toLowerCase()
                    .includes(String(this.filterDebounce.toLowerCase())) &&
                      (check = true )
              }
            }
            
          return check;
        });
        return(toreturn)
      } else {
        return filteredData;
      }
    },
    rows() {
        return this.tableDataArrayFiltered.length || 0
    }
  },
  watch: {
    sortBy(value) {
      sessionStorage.setItem(
        "customersSortingCreateOrder",
        JSON.stringify({
          sortBy: value,
          sortDesc: this.sortDesc,
        })
      );
    },
    sortDesc(value) {
      sessionStorage.setItem(
        "customersSortingCreateOrder",
        JSON.stringify({
          sortBy: this.sortBy,
          sortDesc: value,
        })
      );
    },
  },
  methods: {
    countUp(){
      this.count = this.count + 1
    },
    debounceInput: debounce(function (e) {
        this.filterDebounce = e;
      }, 300),
    async getOrders(ctx) {
      try {
        const colRef = query(collection(firestore, "orders"), where("customer.resellerId", "==", this.$store.state.resellerData.resellerId), where("deluxeOrder", "==", null))
        const orders = await getDocs(colRef);

        const result = [];

        if (!orders.empty) {
          const ordersDataset = [];

          orders.forEach(async (doc) => {
            ordersDataset.push({
              id: doc.id,
              data: doc.data(),
            });
          });

          for await (let order of ordersDataset) {
            order.data["orderId"] = order.id;
            order.data["products"] = await this.loadProducts(order.id);

            if (
              order.data.customer.companyProfile.companyName.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.data.customer.customerId.toLowerCase().includes(ctx.filter.toLowerCase())
            ) {
              result.push(order.data);
            }
          }
        }

        return result;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getCustomers() {
      try {
        const colRef = query(collection(firestore, "customers"), where("resellerId", "==", this.$store.state.resellerData.resellerId), where("status", "in", ["1000", "1002"]))
        const customers = await getDocs(colRef);

        let result = [];

        if (!customers.empty) {
          customers.forEach((customer) => {
            const customerData = customer.data();
              result.push(customerData);
          });
        }

        this.customers = result;
      } catch (error) {
        console.error(error);
        this.customers =  [];
      }
    },
    async selectCustomer(customer, index) {
      this.showSpinnerForIndex = index;

      const getCustomerAccount = httpsCallable(functions, "customerAccount");
      await getCustomerAccount({
        customerId: customer.customerId,
      })
        .then((customerAccount) => {
          const cotermDateTimestamp = moment(customerAccount.data.cotermDate).tz("America/Los_Angeles").unix();
          const todayTimestamp = moment().tz("America/Los_Angeles").unix();

          if (cotermDateTimestamp < todayTimestamp) {
            const thisYear = moment().tz("America/Los_Angeles").format("YYYY");
            const cotermDateDayAndMonth = moment(customerAccount.data.cotermDate).format("MM-DD");
            const cotermDateYear = moment(customerAccount.data.cotermDate).format("YYYY");

            if (cotermDateYear === thisYear) {
              const nextYear = moment().tz("America/Los_Angeles").add(1, "years").format("YYYY");
              customerAccount.data.cotermDate = `${nextYear}-${cotermDateDayAndMonth}`;
            } else {
              const newCotermDateThisYear = `${thisYear}-${cotermDateDayAndMonth}`;
              const newCotermDateThisYearTimestamp = moment(newCotermDateThisYear).tz("America/Los_Angeles").unix();

              if (newCotermDateThisYearTimestamp < todayTimestamp) {
                const nextYear = moment().tz("America/Los_Angeles").add(1, "years").format("YYYY");
                customerAccount.data.cotermDate = `${nextYear}-${cotermDateDayAndMonth}`;
              } else {
                customerAccount.data.cotermDate = newCotermDateThisYear;
              }
            }
          }

          customer = customerAccount.data;
        })
        .catch(() => {
          this.$store.dispatch("alertError", {
            message: "Die Daten konnten nicht abgerufen werden.",
            show: true,
            noFooter: false,
          });
          return;
        });

      const docRef = await addDoc(collection(firestore, "orders"), {
        finished: false,
        customer: customer,
        deluxeOrder: null,
        creationDate: moment()
          .tz("Europe/Berlin")
          .format(),
      })

      this.$router.push({
        name: "CreateOrderSelectProductsView",
        params: {
          orderId: docRef.id,
        },
      });
    },
    deleteOrderWithId(order) {
      this.orderToBeDeleted = order;
      this.$refs["delete-modal"].show();
    },
    async deleteOrder() {
      const docRef = doc(firestore, "orders", this.orderToBeDeleted.orderId);
      await deleteDoc(docRef);

      this.clearOrderToBeDeleted();
      this.$refs.orders.refresh();
      this.$refs["delete-modal"].hide();
    },
    clearOrderToBeDeleted() {
      this.orderToBeDeleted = null;
    },
    loadProducts(id) {
      return new Promise((resolve) => {
        const colRef = collection(firestore, "orders", id, "products");
        onSnapshot(colRef, (querySnapshot) => {
          let productsList = [];
          querySnapshot.forEach((doc) => {
            productsList.push(doc.data());
          });
          resolve(productsList);
        });
      });
    },
  },
};
</script>
