<template>
  <div v-if="isWorking === false">
    <div class="bg-light py-4 header-box" v-b-visible="visibleHandler">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">
          {{ order.customer.companyProfile.companyName }}
        </h3>

        <shopping-cart-component :cotermDate="order.customer.cotermDate" :index="0"></shopping-cart-component>
      </b-container>
    </div>

    <div class="bg-light pb-4 header-box" v-b-visible="visibleHandler">
      <b-container class="d-flex align-items-center">
        <div class="d-flex flex-column mr-4">
          <small class="text-muted">{{ $t("creationTime") }}</small>
          <div>
            <fa-icon :icon="['fa-regular', 'fa-clock']" class="mr-1"></fa-icon>{{ order.creationDate | date }}
          </div>
        </div>

        <div class="d-flex flex-column">
          <small class="text-muted">{{ $t("anniversaryDate") }}</small>
          <div><fa-icon :icon="['fa-regular', 'fa-calendar-days']" class="mr-1"></fa-icon>{{ cotermDate }}</div>
        </div>
      </b-container>
    </div>

    <div id="fixed-header-box" class="bg-light shadow-sm py-4" :class="fixedHeaderBoxVisibility">
      <b-container class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <div class="d-flex flex-column mr-4">
            <small class="text-muted">{{ $t("newOrderFor") }} </small>
            <div class="font-weight-bold">
              {{ order.customer.companyProfile.companyName }}
            </div>
          </div>

          <div class="d-flex flex-column mr-4">
            <small class="text-muted">{{ $t("creationTime") }}</small>
            <div>
              <fa-icon :icon="['fa-regular', 'fa-clock']" class="mr-1"></fa-icon>{{ order.creationDate | date }}
            </div>
          </div>

          <div class="d-flex flex-column">
            <small class="text-muted">{{ $t("anniversaryDate") }}</small>
            <div><fa-icon :icon="['fa-regular', 'fa-calendar-days']" class="mr-1"></fa-icon>{{ cotermDate }}</div>
          </div>
        </div>

        <shopping-cart-component :cotermDate="order.customer.cotermDate" :index="1"></shopping-cart-component>
      </b-container>
    </div>

    <b-container class="pt-4 d-flex flex-row">
      <div class="mr-4" style="min-width: 222px;">
        <b-card body-class="d-flex justify-content-between">
          <b-nav vertical pills>
            <b-nav-item 
              v-on:click="changeFilters(['license', 'teams'])"
              :active="filtersContains(['license', 'teams'])">
              <span v-html="$t('teamsLicenses')"></span>
            </b-nav-item>
            <b-nav-item 
              v-on:click="changeFilters(['license', 'teams', 'pro'])"
              :active="filtersContains(['license', 'teams', 'pro'])" 
              v-if="customerIsInMarketSegment(['COM', 'GOV'])">
              <span v-html="$t('teamsLicensesProEditions')"></span>
            </b-nav-item>
            <b-nav-item 
              v-on:click="changeFilters(['license', 'enterprise'])"
              :active="filtersContains(['license', 'enterprise'])">
              <span v-html="$t('enterpriseLicenses')"></span>
            </b-nav-item>
            <b-nav-item 
              v-on:click="changeFilters(['license', 'enterprise', 'pro'])"
              :active="filtersContains(['license', 'enterprise', 'pro'])" 
              v-if="customerIsInMarketSegment(['COM', 'GOV'])">
              <span v-html="$t('enterpriseLicensesProEditions')"></span>
            </b-nav-item>
            <b-nav-item 
              v-on:click="changeFilters(['consumable', 'transaction'])"
              :active="filtersContains(['consumable', 'transaction'])">
              {{ $t("signTransactions") }}
            </b-nav-item>
            <b-nav-item 
              v-on:click="changeFilters(['consumable', 'teams'])"
              :active="filtersContains(['consumable', 'teams'])"
              v-if="customerIsInMarketSegment(['COM', 'EDU'])">
              {{ $t("creditPacksTeams") }}
            </b-nav-item>
            <b-nav-item 
              v-on:click="changeFilters(['consumable', 'enterprise'])"
              :active="filtersContains(['consumable', 'enterprise'])">
              {{ $t("creditPacksEnterprise") }}
            </b-nav-item>
            <b-nav-item 
              v-on:click="changeFilters(['frl','license', 'enterprise'])"
              :active="filtersContains(['frl','license', 'enterprise'])"
              v-if="customerIsInMarketSegment(['GOV'])">
              FRL
            </b-nav-item>
            <b-nav-item 
              v-on:click="changeFilters(['k12','license', 'enterprise'])"
              :active="filtersContains(['k12','license', 'enterprise'])"
              v-if="customerIsInMarketSegment(['K_12'])">
              K12
            </b-nav-item>
          </b-nav>
        </b-card>
      </div>

      <div>
        <b-card body-class="d-flex justify-content-between" class="mb-4">
          <b-input-group class="w-25">
            <template #prepend>
              <b-input-group-text class="bg-white pr-0"><fa-icon
                  :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon></b-input-group-text>
            </template>
            <b-input :placeholder="$t('browse')" v-model="search" class="border-left-0" debounce="600"></b-input>
          </b-input-group>

          <b-button-group class="flex-shrink-0">
            <b-button variant="secondary" v-on:click="gridView = 'single-col'" :disabled="gridView === 'single-col'">
              <fa-icon :icon="['fa-regular', 'fa-table-list']" class="mr-2"></fa-icon>{{ $t("list") }}
            </b-button>
            <b-button variant="secondary" v-on:click="gridView = ''" :disabled="gridView === ''">
              <fa-icon :icon="['fa-regular', 'fa-table-cells-large']" class="mr-2"></fa-icon>{{ $t("grid") }}
            </b-button>
          </b-button-group>
        </b-card>

        <grid-component :products="products" :cotermDate="order.customer.cotermDate" :gridView="gridView"
          :filters="filters" :search="search" :subscribedOfferIds="subscribedOfferIds"
          :blockedProducts="blockedProducts"></grid-component>

      </div>
    </b-container>
  </div>
  <div class="h-100 d-flex align-items-center justify-content-center py-4" v-else>
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
</template>

<script>
import moment from "moment-timezone";
import ShoppingCartComponent from "../components/ShoppingCartComponent.vue";
import GridComponent from "../components/GridComponent.vue";
import { functions, firestore } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, updateDoc, collection, orderBy, query, getDocs, where, serverTimestamp } from "firebase/firestore";

export default {
  components: { ShoppingCartComponent, GridComponent },
  name: "CreateOrderSelectProductsView",
  data() {
    return {
      search: "",
      products: [],
      isWorking: true,
      order: {},
      gridView: "single-col",
      filters: ["license", "teams"],
      fixedHeaderBoxVisibility: "hide",
      subscribedOfferIds: [],
      hiddenProducts: [],
      renewalOrderFromCurrentYear: false,
      customerHasNoOrders: false,
      hiddenRenewalOfferProducts: [],
      blockedProducts: [],
      marketSegments: null,
    };
  },
  computed: {
    cotermDate() {
      if (this.order.customer.cotermDate === "") {
        return this.$t("anniversaryDateNotSet");
      }
      return this.$options.filters.date(this.order.customer.cotermDate);
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    customerIsInMarketSegment(marketSegments) {
      const isInMarketSegment = marketSegments.includes(this.order.customer.companyProfile.marketSegment);
      let isInMarketSubSegments = false;
      
      if (this.order.customer.companyProfile.marketSubSegments) {
        isInMarketSubSegments = marketSegments.includes(this.order.customer.companyProfile.marketSubSegments.find((marketSubSegment) => marketSubSegment === marketSegments[0]));
      }

      return isInMarketSegment || isInMarketSubSegments;
    },
    async fetchMarketSegmentProductTypes() {
      const colRef = doc(firestore, "generalSettings", "marketSegments");
      const snap = await getDoc(colRef);
      this.marketSegments = snap.data()[this.order.customer.companyProfile.marketSegment] || null;
    },
    async getBlockedProducts() {
      const colRef = doc(firestore, "generalSettings", "blockedProducts");
      const snap = await getDoc(colRef);
      this.blockedProducts = snap.data().offerIds || [];
    },
    async getHiddenProducts() {
      const colRef = doc(firestore, "generalSettings", "hiddenProducts");
      const snap = await getDoc(colRef);
      this.hiddenProducts = snap.data().productCodes || [];
    },
    async getHiddenRenewalOfferProducts() {
      const colRef = doc(firestore, "generalSettings", "hiddenRenewalOfferProducts");
      const snap = await getDoc(colRef);
      this.hiddenRenewalOfferProducts = snap.data().productCodes || [];
    },
    visibleHandler(isVisible) {
      if (isVisible === false) {
        this.fixedHeaderBoxVisibility = "show";
      } else {
        this.fixedHeaderBoxVisibility = "hide";
      }
    },
    async getData() {
      await this.getHiddenProducts();
      await this.getBlockedProducts();
      await this.getHiddenRenewalOfferProducts();
      this.order = await this.getOrder();
      this.order.customer = await this.getCustomerAccount(this.order.customer.customerId);
      if (this.order.customer) {
        await this.saveCustomerToOrder(this.order.customer);
        await this.updateCustomerAccount(this.order.customer);
      }
      await this.fetchMarketSegmentProductTypes();
      await this.getCustomerSubscriptions()
      await this.checkForRenewalOrder();
      await this.checkForOrders();
      await this.getProducts();
    },
    // Method to check if the coterm date is within the last seven days
    cotermDateInLastSevenDays(cotermDate) {
      // Calculate the number of days between today and the coterm date
      const daysBetweenTodayAndCotermDate = moment().tz("America/Los_Angeles").diff(moment(cotermDate).tz("America/Los_Angeles"), 'days')

      // Return true if the renewal is within 7 days of the current year or within 7 days of the next year
      return (daysBetweenTodayAndCotermDate <= 7 && !daysBetweenTodayAndCotermDate < 0) || // Renewal is within 7 days (current year)
        (daysBetweenTodayAndCotermDate >= -365 && daysBetweenTodayAndCotermDate <= -358) // Renewal is within 7 days (next year)
    },
    // Method to check if there is a renewal order for the current year
    async checkForRenewalOrder() {
      // Create a callable function to get the customer's order history
      const getCustomerOrderHistory = httpsCallable(functions, "getCustomerOrderHistory");

      // Call the function with the customer ID and the query parameters
      const orderHistory = await getCustomerOrderHistory({
        customerId: this.order.customer.customerId,
        query: {
          "order-type": "RENEWAL",
          "start-date": moment().tz("America/Los_Angeles").format("YYYY") + "-01-01",
          "end-date": moment().tz("America/Los_Angeles").format("YYYY") + "-12-31",
        }
      });

      // If there are no renewal orders for the current year, set renewalOrderFromCurrentYear to false
      if (orderHistory.data.count === 0) {
        this.renewalOrderFromCurrentYear = false;
      } else {
        // If there are renewal orders for the current year, set renewalOrderFromCurrentYear to true
        this.renewalOrderFromCurrentYear = true;
      }
    },
    async checkForOrders() {
      const getCustomerOrderHistory = httpsCallable(functions, "getCustomerOrderHistory");

      const orderHistory = await getCustomerOrderHistory({
        customerId: this.order.customer.customerId,
      });

      const orders = orderHistory.data.items.filter((item) => item.orderType !== "TRANSFER");

      if (orders.length === 0) {
        this.customerHasNoOrders = true;
      } else {
        this.customerHasNoOrders = false;
      }
    },
    async getOrder() {
      try {
        const docRef = doc(firestore, "orders", this.$route.params.orderId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          return docSnapshot.data();
        } else {
          this.$store.dispatch("alertError", {
            message: "Der Auftrag konnte nicht abgerufen werden.",
            show: true,
            noFooter: false,
          });
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch("alertError", {
          message: "Der Auftrag konnte nicht abgerufen werden.",
          show: true,
          noFooter: false,
        });
      }
    },
    getCustomerAccount(customerId) {
      const getCustomerAccount = httpsCallable(functions, "customerAccount");
      return getCustomerAccount({
        customerId: customerId,
      })
        .then((customerAccount) => {
          const cotermDateTimestamp = moment(customerAccount.data.cotermDate)
            .tz("America/Los_Angeles")
            .unix();
          const todayTimestamp = moment()
            .tz("America/Los_Angeles")
            .unix();

          if (cotermDateTimestamp < todayTimestamp) {
            const thisYear = moment()
              .tz("America/Los_Angeles")
              .format("YYYY");
            const cotermDateDayAndMonth = moment(customerAccount.data.cotermDate).format("MM-DD");
            const cotermDateYear = moment(customerAccount.data.cotermDate).format("YYYY");

            if (cotermDateYear === thisYear) {
              const nextYear = moment()
                .tz("America/Los_Angeles")
                .add(1, "years")
                .format("YYYY");
              customerAccount.data.cotermDate = `${nextYear}-${cotermDateDayAndMonth}`;
            } else {
              const newCotermDateThisYear = `${thisYear}-${cotermDateDayAndMonth}`;
              const newCotermDateThisYearTimestamp = moment(newCotermDateThisYear)
                .tz("America/Los_Angeles")
                .unix();

              if (newCotermDateThisYearTimestamp < todayTimestamp) {
                const nextYear = moment()
                  .tz("America/Los_Angeles")
                  .add(1, "years")
                  .format("YYYY");
                customerAccount.data.cotermDate = `${nextYear}-${cotermDateDayAndMonth}`;
              } else {
                customerAccount.data.cotermDate = newCotermDateThisYear;
              }
            }
          }

          return customerAccount.data;
        })
        .catch(() => {
          this.$store.dispatch("alertError", {
            message: "Die Daten konnten nicht abgerufen werden.",
            show: true,
            noFooter: false,
          });
          return;
        });
    },
    async updateCustomerAccount(customerAccount) {
      const colRef = query(collection(firestore, "customers"), where("customerId", "==", customerAccount.customerId));
      const querySnapshot = await getDocs(colRef);

      if (!querySnapshot.empty) {
        let docId = null;

        querySnapshot.forEach((doc) => {
          docId = doc.id;
        });

        if (docId !== null) {
          const docRef = doc(firestore, "customers", docId)
          updateDoc(docRef, {
            companyProfile: customerAccount.companyProfile,
            cotermDate: customerAccount.cotermDate,
            status: customerAccount.status,
            discounts: customerAccount.discounts,
            resellerId: customerAccount.resellerId,
            lastFetchFromAdobe: serverTimestamp(),
          });
        }
      }
    },
    saveCustomerToOrder(customer) {
      try {
        const docRef = doc(firestore, "orders", this.$route.params.orderId);
        updateDoc(docRef, {
          customer: customer,
        });
      } catch (error) {
        console.error(error);
        this.$store.dispatch("alertError", {
          message: "Die Kundendaten konnten nicht gespeichert werden.",
          show: true,
          noFooter: false,
        });
      }
    },
    async getProducts() {
      const productsColRef = query(collection(firestore, "products"), orderBy("productName"));
      const products = await getDocs(productsColRef);

      if (products.empty) {
        this.$store.dispatch("alertError", {
          message: "Es wurden keine Produkte gefunden.",
          show: true,
          noFooter: false,
        });
        return;
      }

      const requestProducts = [];

      products.forEach((product) => {
        const productData = product.data();

        const itemNumberObject = this.$options.filters.itemNumberObject(productData.itemNumber);

        // Check if the volume level of the product is 1
        const isVolumeLevelOne = itemNumberObject.volumeLevel === 1;

        // Check if the product type is not 3YC
        const isNot3YC = !itemNumberObject.productType.match(/^[0-9]{2}3/g);

        // Checks if coterm date is within last seven days and a renewal from current year exists.
        const isWithinLastSevenDaysAndRenewalFromCurrentYearExists = this.cotermDateInLastSevenDays(this.order.customer.cotermDate) && this.renewalOrderFromCurrentYear;

        // Checks if the customer has no order in general.
        const customerHasNoOrders = this.customerHasNoOrders;

        // Checks if the customer's coterm date in the order is an empty string.
        const cotermDateIsEmpty = this.order.customer.cotermDate === "";

        // Checks if the customer's coterm date is in the future and within the current year.
        const cotermDateIsInFutureAndWithinCurrentYear = moment(this.order.customer.cotermDate).isAfter(moment()) && moment(this.order.customer.cotermDate).isSame(moment(), 'year');

        // Checks if the customer's coterm date is after the current year.
        const cotermDateIsAfterCurrentYear = moment(this.order.customer.cotermDate).isAfter(moment().format("YYYY"));

        // Check if the product is not included in the hidden products list.
        const isNotIncludedInHiddenProducts = !this.hiddenProducts.includes(`${itemNumberObject.productType}-${itemNumberObject.product}`);

        // Check if the product is not included in the hidden renewal offer products list
        const isNotIncludedInHiddenRenewalOfferProducts = !this.hiddenRenewalOfferProducts.includes(`${itemNumberObject.productType}-${itemNumberObject.product}`);

        // Check if the product type of the current item number object is included in the market segment product types
        const isIncludedInMarketSegments = this.marketSegments.includes(itemNumberObject.marketSegment);

        if (isVolumeLevelOne && isNot3YC && isIncludedInMarketSegments) {
          if (isWithinLastSevenDaysAndRenewalFromCurrentYearExists || customerHasNoOrders || cotermDateIsEmpty || cotermDateIsInFutureAndWithinCurrentYear || cotermDateIsAfterCurrentYear) {
            // Is allowed to order promotional products
            if (isNotIncludedInHiddenProducts) {
              // If the product's special price function is not equal to the new price postfix, add the product data to the request products list
              requestProducts.push(productData);
            }
          } else {
            // Is not allowed to order promotional products
            if (isNotIncludedInHiddenRenewalOfferProducts) {
              // If the product's special price function is not equal to the new price postfix, add the product data to the request products list
              requestProducts.push(productData);
            }
          }
        }
      });

      const getPrices = httpsCallable(functions, "getPrices");
      const productsWithPrices = await getPrices({
        benefits: this.order.customer.benefits,
        cotermDate: this.order.customer.cotermDate,
        discounts: this.order.customer.discounts,
        products: requestProducts,
        resellerId: this.order.customer.resellerId,
        marketSegment: this.order.customer.companyProfile.marketSegment,
      });

      this.products = productsWithPrices.data;

      this.isWorking = false;
    },
    changeFilters(values) {
      this.filters = values;
    },
    filtersContains(values) {
      const results = [];
      values.forEach((value) => results.push(this.filters.includes(value)));

      if (results.includes(false) || values.length !== this.filters.length) {
        return false;
      } else {
        return true;
      }
    },
    removeVolumeLevelFromOfferId(offerId) {
      return offerId.replace(/CA\d\d/, "CA00").replace(/CAT\d/, "CAT0");
    },
    async getCustomerSubscriptions() {
      const getCustomerSubscriptions = httpsCallable(functions, "getCustomerSubscriptions");
      const subscriptionsRequest = await getCustomerSubscriptions({
        customerId: this.order.customer.customerId,
      });
      subscriptionsRequest.data.items.forEach(el => {
        if (el.status == 1000) {
          this.subscribedOfferIds.push(this.removeVolumeLevelFromOfferId(el.offerId))
        }
      })
    },
  },
};
</script>

<style scoped>
#fixed-header-box {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

#fixed-header-box.hide {
  transform: translateY(-100%);
}

#fixed-header-box.show {
  transform: translateY(0);
  transition: transform 0.3s ease;
}</style>
