export default {
  partnerStore: false,
  companyName: "Demo - ComLine",
  noPriceRenewalOfferProducts: [
    "5-11-001-0300000",
    "5-11-001-0300050",
    "5-11-001-0301000",
    "5-11-001-0301050",
    "5-11-001-0304000",
    "5-11-001-0304050",
    "5-11-001-0305000",
    "5-11-001-0305050",
    "5-11-001-0306000",
    "5-11-001-0306050",
    "5-11-001-0307000",
    "5-11-001-0307050",
    "5-11-001-0308000",
    "5-11-001-0308050",
    "5-11-001-0309000",
    "5-11-001-0309050",
    "5-11-001-0310000",
    "5-11-001-0310050",
    "5-11-001-0311000",
    "5-11-001-0311050",
    "5-11-001-0320000",
    "5-11-001-0320050",
    "5-11-001-0324000",
    "5-11-001-0324050",
    "5-11-001-0325000",
    "5-11-001-0300100",
    "5-11-001-0300150",
    "5-11-001-0301100",
    "5-11-001-0301150",
    "5-11-001-0304100",
    "5-11-001-0304150",
    "5-11-001-0305100",
    "5-11-001-0305150",
    "5-11-001-0306100",
    "5-11-001-0306150",
    "5-11-001-0307100",
    "5-11-001-0307150",
    "5-11-001-0308100",
    "5-11-001-0308150",
    "5-11-001-0309100",
    "5-11-001-0309150",
    "5-11-001-0310100",
    "5-11-001-0310150",
    "5-11-001-0311100",
    "5-11-001-0311150",
    "5-11-001-0320150",
    "5-11-001-0324100",
    "5-11-001-0324150",
    "5-11-001-0300200",
    "5-11-001-0300250",
    "5-11-001-0301200",
    "5-11-001-0301250",
    "5-11-001-0304200",
    "5-11-001-0304250",
    "5-11-001-0305200",
    "5-11-001-0305250",
    "5-11-001-0306200",
    "5-11-001-0306250",
    "5-11-001-0307200",
    "5-11-001-0307250",
    "5-11-001-0308200",
    "5-11-001-0308250",
    "5-11-001-0309200",
    "5-11-001-0309250",
    "5-11-001-0310200",
    "5-11-001-0310250",
    "5-11-001-0311200",
    "5-11-001-0311250",
    "5-11-001-0320200",
    "5-11-001-0320250",
    "5-11-001-0324200",
    "5-11-001-0324250",
    "5-11-001-0300300",
    "5-11-001-0300350",
    "5-11-001-0301300",
    "5-11-001-0301350",
    "5-11-001-0304300",
    "5-11-001-0304350",
    "5-11-001-0305300",
    "5-11-001-0305350",
    "5-11-001-0306300",
    "5-11-001-0306350",
    "5-11-001-0307300",
    "5-11-001-0307350",
    "5-11-001-0308300",
    "5-11-001-0308350",
    "5-11-001-0309300",
    "5-11-001-0309350",
    "5-11-001-0310300",
    "5-11-001-0310350",
    "5-11-001-0311300",
    "5-11-001-0311350",
    "5-11-001-0320300",
    "5-11-001-0320350",
    "5-11-001-0324300",
    "5-11-001-0324350",
    "5-11-001-1300000",
    "5-11-101-0308050",
    "5-11-101-0308150",
    "5-11-101-0308250",
    "5-11-101-0308350",
    "5-11-101-0308000",
    "5-11-101-0308100",
    "5-11-101-0308200",
    "5-11-101-0308300",
    "5-11-101-0311050",
    "5-11-101-0311150",
    "5-11-101-0311250",
    "5-11-101-0311350",
    "5-11-101-0311000",
    "5-11-101-0311100",
    "5-11-101-0311200",
    "5-11-101-0311300",
    "5-11-101-0300000",
    "5-11-101-0300100",
    "5-11-101-0300200",
    "5-11-101-0300300",
    "5-11-101-0300050",
    "5-11-101-0300150",
    "5-11-101-0300250",
    "5-11-101-0300350",
    "5-11-101-0306000",
    "5-11-101-0306100",
    "5-11-101-0306200",
    "5-11-101-0306300",
    "5-11-101-0306050",
    "5-11-101-0306150",
    "5-11-101-0306250",
    "5-11-101-0306350",
    "5-11-101-0310000",
    "5-11-101-0310100",
    "5-11-101-0310200",
    "5-11-101-0310300",
    "5-11-101-0310050",
    "5-11-101-0310150",
    "5-11-101-0310250",
    "5-11-101-0310350",
    "5-11-101-0320000",
    "5-11-101-0320100",
    "5-11-101-0320200",
    "5-11-101-0320300",
    "5-11-101-0320050",
    "5-11-101-0320150",
    "5-11-101-0320250",
    "5-11-101-0320350",
    "5-11-101-0309050",
    "5-11-101-0309150",
    "5-11-101-0309250",
    "5-11-101-0309350",
    "5-11-101-0309000",
    "5-11-101-0309100",
    "5-11-101-0309200",
    "5-11-101-0309300",
    "5-11-101-0307000",
    "5-11-101-0307100",
    "5-11-101-0307200",
    "5-11-101-0307300",
    "5-11-101-0307050",
    "5-11-101-0307150",
    "5-11-101-0307250",
    "5-11-101-0307350",
    "5-11-101-0324000",
    "5-11-101-0324100",
    "5-11-101-0324200",
    "5-11-101-0324300",
    "5-11-101-0324050",
    "5-11-101-0324150",
    "5-11-101-0324250",
    "5-11-101-0324350",
    "5-11-101-0304000",
    "5-11-101-0304100",
    "5-11-101-0304200",
    "5-11-101-0304300",
    "5-11-101-0304050",
    "5-11-101-0304150",
    "5-11-101-0304250",
    "5-11-101-0304350",
    "5-11-101-0305050",
    "5-11-101-0305150",
    "5-11-101-0305250",
    "5-11-101-0305350",
    "5-11-101-0305000",
    "5-11-101-0305100",
    "5-11-101-0305200",
    "5-11-101-0305300",
    "5-11-011-0311000",
    "5-11-011-0311100",
    "5-11-011-0311200",
    "5-11-011-0311300",
    "5-11-011-0311050",
    "5-11-011-0311150",
    "5-11-011-0311250",
    "5-11-011-0311350",
    "5-11-011-0300050",
    "5-11-011-0300150",
    "5-11-011-0300250",
    "5-11-011-0300350",
    "5-11-011-0300000",
    "5-11-011-0300100",
    "5-11-011-0300200",
    "5-11-011-0300300",
    "5-11-011-0307000",
    "5-11-011-0307100",
    "5-11-011-0307200",
    "5-11-011-0307300",
    "5-11-011-0307050",
    "5-11-011-0307150",
    "5-11-011-0307250",
    "5-11-011-0307350",
    "5-11-011-0310000",
    "5-11-011-0310100",
    "5-11-011-0310200",
    "5-11-011-0310300",
    "5-11-011-0310050",
    "5-11-011-0310150",
    "5-11-011-0310250",
    "5-11-011-0310350",
    "5-11-011-0304000",
    "5-11-011-0304100",
    "5-11-011-0304200",
    "5-11-011-0304300",
    "5-11-011-0304050",
    "5-11-011-0304150",
    "5-11-011-0304250",
    "5-11-011-0304350",
    "5-11-011-0308000",
    "5-11-011-0308100",
    "5-11-011-0308200",
    "5-11-011-0308300",
    "5-11-011-0308050",
    "5-11-011-0308150",
    "5-11-011-0308250",
    "5-11-011-0308350",
    "5-11-011-0324050",
    "5-11-011-0324150",
    "5-11-011-0324250",
    "5-11-011-0324350",
    "5-11-011-0324000",
    "5-11-011-0324100",
    "5-11-011-0324200",
    "5-11-011-0324300",
    "5-11-011-0306050",
    "5-11-011-0306150",
    "5-11-011-0306250",
    "5-11-011-0306350",
    "5-11-011-0306000",
    "5-11-011-0306100",
    "5-11-011-0306200",
    "5-11-011-0306300",
    "5-11-011-0305000",
    "5-11-011-0305100",
    "5-11-011-0305200",
    "5-11-011-0305300",
    "5-11-011-0305050",
    "5-11-011-0305150",
    "5-11-011-0305250",
    "5-11-011-0305350",
    "5-11-011-0320050",
    "5-11-011-0320150",
    "5-11-011-0320250",
    "5-11-011-0320350",
    "5-11-011-0320000",
    "5-11-011-0320100",
    "5-11-011-0320200",
    "5-11-011-0320300",
    "5-11-011-0309050",
    "5-11-011-0309150",
    "5-11-011-0309250",
    "5-11-011-0309350",
    "5-11-011-0309000",
    "5-11-011-0309100",
    "5-11-011-0309200",
    "5-11-011-0309300",
    "5-11-111-0308050",
    "5-11-111-0308150",
    "5-11-111-0308250",
    "5-11-111-0308350",
    "5-11-111-0308000",
    "5-11-111-0308100",
    "5-11-111-0308200",
    "5-11-111-0308300",
    "5-11-111-0311050",
    "5-11-111-0311150",
    "5-11-111-0311250",
    "5-11-111-0311350",
    "5-11-111-0311000",
    "5-11-111-0311100",
    "5-11-111-0311200",
    "5-11-111-0311300",
    "5-11-111-0300000",
    "5-11-111-0300100",
    "5-11-111-0300200",
    "5-11-111-0300300",
    "5-11-111-0300050",
    "5-11-111-0300150",
    "5-11-111-0300250",
    "5-11-111-0300350",
    "5-11-111-0306000",
    "5-11-111-0306100",
    "5-11-111-0306200",
    "5-11-111-0306300",
    "5-11-111-0306050",
    "5-11-111-0306150",
    "5-11-111-0306250",
    "5-11-111-0306350",
    "5-11-111-0310000",
    "5-11-111-0310100",
    "5-11-111-0310200",
    "5-11-111-0310300",
    "5-11-111-0310050",
    "5-11-111-0310150",
    "5-11-111-0310250",
    "5-11-111-0310350",
    "5-11-111-0320000",
    "5-11-111-0320100",
    "5-11-111-0320200",
    "5-11-111-0320300",
    "5-11-111-0320050",
    "5-11-111-0320150",
    "5-11-111-0320250",
    "5-11-111-0320350",
    "5-11-111-0309050",
    "5-11-111-0309150",
    "5-11-111-0309250",
    "5-11-111-0309350",
    "5-11-111-0309000",
    "5-11-111-0309100",
    "5-11-111-0309200",
    "5-11-111-0309300",
    "5-11-111-0307000",
    "5-11-111-0307100",
    "5-11-111-0307200",
    "5-11-111-0307300",
    "5-11-111-0307050",
    "5-11-111-0307150",
    "5-11-111-0307250",
    "5-11-111-0307350",
    "5-11-111-0324000",
    "5-11-111-0324100",
    "5-11-111-0324200",
    "5-11-111-0324300",
    "5-11-111-0324050",
    "5-11-111-0324150",
    "5-11-111-0324250",
    "5-11-111-0324350",
    "5-11-111-0304000",
    "5-11-111-0304100",
    "5-11-111-0304200",
    "5-11-111-0304300",
    "5-11-111-0304050",
    "5-11-111-0304150",
    "5-11-111-0304250",
    "5-11-111-0304350",
    "5-11-111-0305050",
    "5-11-111-0305150",
    "5-11-111-0305250",
    "5-11-111-0305350",
    "5-11-111-0305000",
    "5-11-111-0305100",
    "5-11-111-0305200",
    "5-11-111-0305300",
    "5-11-001-0325300",
    "5-11-001-0325200",
    "5-11-001-0325100",
    "5-11-011-1300000",
    "5-11-011-1300050",
    "5-11-011-1300100",
    "5-11-011-1300150",
    "5-11-011-1300200",
    "5-11-011-1300250",
    "5-11-011-1300300",
    "5-11-011-1300350",
    "5-11-011-1304000",
    "5-11-011-1304050",
    "5-11-011-1304100",
    "5-11-011-1304150",
    "5-11-011-1304200",
    "5-11-011-1304250",
    "5-11-011-1304300",
    "5-11-011-1304350",
    "5-11-011-1305000",
    "5-11-011-1305050",
    "5-11-011-1305100",
    "5-11-011-1305150",
    "5-11-011-1305200",
    "5-11-011-1305250",
    "5-11-011-1305300",
    "5-11-011-1305350",
    "5-11-011-1306000",
    "5-11-011-1306050",
    "5-11-011-1306100",
    "5-11-011-1306150",
    "5-11-011-1306200",
    "5-11-011-1306250",
    "5-11-011-1306300",
    "5-11-011-1306350",
    "5-11-011-1307000",
    "5-11-011-1307050",
    "5-11-011-1307100",
    "5-11-011-1307150",
    "5-11-011-1307200",
    "5-11-011-1307250",
    "5-11-011-1307300",
    "5-11-011-1307350",
    "5-11-011-1308000",
    "5-11-011-1308050",
    "5-11-011-1308100",
    "5-11-011-1308150",
    "5-11-011-1308200",
    "5-11-011-1308250",
    "5-11-011-1308300",
    "5-11-011-1308350",
    "5-11-011-1309000",
    "5-11-011-1309050",
    "5-11-011-1309100",
    "5-11-011-1309150",
    "5-11-011-1309200",
    "5-11-011-1309250",
    "5-11-011-1309300",
    "5-11-011-1309350",
    "5-11-011-1310000",
    "5-11-011-1310050",
    "5-11-011-1310100",
    "5-11-011-1310150",
    "5-11-011-1310200",
    "5-11-011-1310250",
    "5-11-011-1310300",
    "5-11-011-1310350",
    "5-11-011-1311000",
    "5-11-011-1311050",
    "5-11-011-1311100",
    "5-11-011-1311150",
    "5-11-011-1311200",
    "5-11-011-1311250",
    "5-11-011-1311300",
    "5-11-011-1311350",
    "5-11-011-1320000",
    "5-11-011-1320050",
    "5-11-011-1320100",
    "5-11-011-1320150",
    "5-11-011-1320200",
    "5-11-011-1320250",
    "5-11-011-1320300",
    "5-11-011-1320350",
    "5-11-011-1324000",
    "5-11-011-1324050",
    "5-11-011-1324100",
    "5-11-011-1324150",
    "5-11-011-1324200",
    "5-11-011-1324250",
    "5-11-011-1324300",
    "5-11-011-1324350",
    "5-11-101-0345000",
    "5-11-101-0345050",
    "5-11-101-0345100",
    "5-11-101-0345150",
    "5-11-101-0345200",
    "5-11-101-0345250",
    "5-11-101-0345300",
    "5-11-101-0345350",
  ],
  newPricePostfix: "NEW",
};