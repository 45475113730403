<template>
  <div v-if="isWorking === false">
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <div>
          <div class="d-flex align-items-center">
            <b-button class="mr-3" variant="dark" :to="{ name: 'CustomersView' }"><fa-icon :icon="['far','angle-left']"></fa-icon></b-button>
            <h3 class="font-weight-bold mb-0">
              {{ customer.companyProfile.companyName }}
            </h3>
          </div>
        </div>

        <create-new-order-component :customerId="customer.customerId" v-show="customer.status == 1000 || customer.status == 1002"></create-new-order-component>
      </b-container>
    </div>
    <div class="bg-light pb-4 header-box">
      <b-container class="d-flex align-items-center">
        <div class="d-flex flex-column mr-4">
          <small class="text-muted">{{ $t("customerId") }}</small>
          <div><fa-icon :icon="['fa-regular', 'fa-id-card']" class="mr-1"></fa-icon>{{ customer.customerId }}</div>
        </div>

        <div class="d-flex flex-column mr-4">
          <small class="text-muted">{{$t("resellerReferenceId")}}</small>
          <b-input size="sm" v-model="resellerReferenceId"></b-input>
        </div>

        <div class="d-flex flex-column mr-4"> 
          <small class="text-muted">{{ $t("anniversaryDate") }}</small>
          <div><fa-icon :icon="['fa-regular', 'fa-calendar-days']" class="mr-1"></fa-icon>{{ cotermDate }}</div>
        </div>

        <div :class="`d-flex flex-column mr-4 ${statusClass(customer.status)}`">
          <small class="text-muted">Status</small>
          <div>
            <fa-icon :icon="['fa-regular', statusIcon(customer.status)]" class="mr-1"></fa-icon>{{ customer.status | accountStatus }}
          </div>
        </div>

        <div class="d-flex flex-column mr-4" v-if="customer && customer?.benefits.length > 0 && customer?.benefits.filter(d => d.type === 'THREE_YEAR_COMMIT') && customer.benefits?.filter(d => d.type === 'THREE_YEAR_COMMIT')[0].commitment?.startDate">
          <small class="text-muted">{{$t("threeYCOption")}}</small>
          <div>
            {{ returnDate(customer.benefits?.filter(d => d.type === "THREE_YEAR_COMMIT")[0].commitment.startDate)  }} - {{ returnDate(customer.benefits?.filter(d => d.type === "THREE_YEAR_COMMIT")[0].commitment.endDate) || "-" }}
          </div>
        </div>

        <div class="d-flex flex-column mr-4">
          <small class="text-muted">{{$t("marketSegment")}}</small>
          <div>
            {{ customer.companyProfile.marketSegment | marketSegment }}
          </div>
        </div>
        
      </b-container>
    </div>
    <b-container>
      <resend-customer-invitation-component v-if="customer.status === '1002'" :profile="customer"></resend-customer-invitation-component>
      <div class="d-flex pt-4">
        <b-nav vertical class="mb-4 mr-4 tab-nav" style="max-widht: 100px;">
          <b-nav-item :active="$route.name === 'CustomerSubscriptionsView'" :to="{ name: 'CustomerSubscriptionsView' }">
            {{ $t("subscriptions") }}
          </b-nav-item>
          <b-nav-item
            :active="$route.name === 'CustomerOrdersView' || $route.name === 'CustomerOrderView'"
            :to="{ name: 'CustomerOrdersView' }"
          >
            {{ $t("orderHistory") }}
          </b-nav-item>
          <b-nav-item :active="$route.name === 'CustomerProfileView'" :to="{ name: 'CustomerProfileView' }">
            {{ $t("companyData") }}
          </b-nav-item>
          <b-nav-item :active="$route.name === 'CustomerContactsView'" :to="{ name: 'CustomerContactsView' }">
            {{ $t("contacts") }}
          </b-nav-item>
          <b-nav-item :active="$route.name === 'CustomerRenewalOfferView'" :to="{ name: 'CustomerRenewalOfferView' }">
            {{ $t("renewalOffer") }}
          </b-nav-item>
          <b-nav-item :active="$route.name === 'CustomerRenewalOrderNumber'" :to="{ name: 'CustomerRenewalOrderNumber' }">
            {{ $t("renewalOrderNumber") }}
          </b-nav-item>
          <b-nav-item :active="$route.name === 'Customer3YC'" :to="{ name: 'Customer3YC' }">
            {{ $t("threeyearcommit") }}
          </b-nav-item>
          <b-nav-item :active="$route.name === 'ExtendedTerm'" :to="{ name: 'ExtendedTerm' }" v-if="customer.cotermDate === ''">
            {{ $t("extendedTerm") }}
          </b-nav-item>
        </b-nav>
        <router-view
          class="w-75"
          :customer="customer"
          :subscriptions="subscriptions"
          :orders="orders"
          :autoRenewalSubscriptions="autoRenewalSubscriptions"
          v-on:refreshSubscriptions="getSubscriptions"
        ></router-view>
      </div>
    </b-container>
  </div>
  <div class="h-100 d-flex align-items-center justify-content-center py-4" v-else>
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
</template>

<script>
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { query, collection, where, getDocs, doc, updateDoc, serverTimestamp } from "firebase/firestore";
import ResendCustomerInvitationComponent from '../components/ResendCustomerInvitationComponent.vue';
import CreateNewOrderComponent from '../components/CreateNewOrderComponent.vue';
import { debounce } from 'lodash-es';

export default {
  name: "CustomerView",
  components: {
    ResendCustomerInvitationComponent,
    CreateNewOrderComponent
},
  data() {
    return {
      isWorking: true,
      customer: null,
      subscriptions: [],
      orders: [],
      resellerReferenceId: "",
      autoRenewalSubscriptions: 0,
    };
  },
  mounted() {
    this.getCustomerAccount();
    this.getCustomerAccountFromDb();
    this.getSubscriptions();
  },
  computed: {
    cotermDate() {
      if (this.customer.cotermDate === "") {
        return this.$t("anniversaryDateNotSet");
      }
      return this.$options.filters.date(this.customer.cotermDate);
    },
  },
  watch: {
    resellerReferenceId: {
      handler: debounce(async function (newVal, oldVal) {
        if (newVal !== oldVal && newVal !== undefined) {
          const updateData = {
            resellerReferenceId: newVal,
          }

          await this.updateCustomerAccount(updateData);
        }
      }, 500),
    },  
  },
  methods: {
    getCustomerAccount() {
      const customerAccount = httpsCallable(functions, "customerAccount");
      customerAccount({
        customerId: this.$route.params.customerId,
      })
        .then(async (result) => {
          if (result.data.resellerId !== this.$store.state.resellerData.resellerId) {
            this.$router.replace({ name: "CustomersView", });
          }

          this.customer = result.data;

          const updateData = {
            companyProfile: result.data.companyProfile,
            cotermDate: result.data.cotermDate,
            status: result.data.status,
            discounts: result.data.discounts,
            resellerId: result.data.resellerId,
            lastFetchFromAdobe: serverTimestamp(),
          }

          await this.updateCustomerAccount(updateData);

          this.isWorking = false;
        })
        .catch(() => {
          this.$store.dispatch("alertError", {
            message: "Die Daten konnten nicht abgerufen werden.",
            show: true,
            noFooter: false,
          });
        });
    },
    async updateCustomerAccount(updateData) {
      const colRef = query(collection(firestore, "customers"), where("customerId", "==", this.$route.params.customerId));
      const querySnapshot = await getDocs(colRef);

      if (!querySnapshot.empty) {
        let docId = null;

        querySnapshot.forEach((doc) => {
          docId = doc.id;
        });

        if (docId !== null) {
          const docRef = doc(firestore, "customers", docId)
          updateDoc(docRef, updateData);
        }
      }
    },
    async getCustomerAccountFromDb() {
      const colRef = query(collection(firestore, "customers"), where("customerId", "==", this.$route.params.customerId));
      const querySnapshot = await getDocs(colRef);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          this.resellerReferenceId = doc.data()?.resellerReferenceId;
        });
      }
    },
    statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    returnDate(date) {
      let dateToReturn = new Date(date)
      return dateToReturn.toLocaleDateString('de-DE')
    },
    async getSubscriptions() {
      const getCustomerSubscriptions = httpsCallable(functions, "getCustomerSubscriptions");
      const subscriptionsRequest = getCustomerSubscriptions({
        customerId: this.$route.params.customerId,
        resellerId: 124
      });

      const getCustomerOrderHistory = httpsCallable(functions, "getCustomerOrderHistory");
      const adobeOrderHistoryRequest = getCustomerOrderHistory({
        customerId: this.$route.params.customerId,
      });

      const productsColRef = collection(firestore, "products");
      let products = getDocs(productsColRef);

      const promises = [subscriptionsRequest, adobeOrderHistoryRequest, products];

      return await Promise.all(promises)
        .then((responses) => {
          const subscriptions = responses[0].data.items;

          this.orders = responses[1].data.items;
          products = [];
          if (!responses[2].empty) {
            responses[2].forEach((doc) => {
              products.push(doc.data());
            });
          }

          this.subscriptions = [];

          if (subscriptions.length > 0) {
            subscriptions.forEach((subscription) => {
              const matchingProduct = products.filter((product) => product.manufacturerItemNumber === subscription.offerId);

              if (subscription.autoRenewal.enabled) {
                this.autoRenewalSubscriptions += subscription.autoRenewal.renewalQuantity;
              }

              if (matchingProduct.length > 0) {
                subscription["productName"] = matchingProduct[0].productName;
              } else {
                subscription["productName"] = "Produkt nicht identifizierbar"
              }

              this.subscriptions.push(subscription);
            });
          }
        })
        .catch((error) => {
          console.error(error);
          return [];
        });
    },
  },
};
</script>

<style>
.tab-nav a {
  color: #6c757d;
  padding-left: 0px;
  margin-bottom: 2px;
}
.tab-nav a.active {
  color: #4089c9;
}
</style>
