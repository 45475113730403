<template>
  <div class="py-4">
    <b-container class="h-100">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col md="7">
          <b-card
            class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary"
            body-class="p-5"
          >
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreake="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>
            <b-form v-on:submit.prevent="resetPassword">
              <h3>{{ $t("forgotPasswordHeadline") }}</h3>
              <p class="text-muted mb-5">
                {{ $t("resetPasswordText") }}
              </p>
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("emailLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input type="email" v-model="email" readonly />
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("passwordLabel") }}</label>
                </b-col>
                <b-col>
                  <b-popover target="input-password" triggers="hover" placement="top">
                    <template #title>{{ $t("requirements") }}</template>
                    {{ $t("requirementsText") }}
                  </b-popover>
                  <b-form-input
                    id="input-password"
                    type="password"
                    v-model="password"
                    :state="states.password"
                    aria-describedby="input-password-feedback"
                    required
                    trim
                  />
                  <b-form-invalid-feedback id="input-password-feedback">
                    {{ $t("passwordInsecure") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <hr />
              <b-row class="mb-5">
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("confirmPassword") }}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-password-confirm"
                    type="password"
                    v-model="confirmPassword"
                    :state="states.confirmPassword"
                    aria-describedby="input-password-confirm-feedback"
                    trim
                    required
                  />
                  <b-form-invalid-feedback id="input-password-confirm-feedback">
                    {{ $t("passwordsNotIdentical") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <submit-button-component
                :state="submitButtonState"
                :label="$t('resetPasswordButton')"
                :block="true"
              ></submit-button-component>
            </b-form>
          </b-card>
          <div class="d-flex flex-column align-items-center mt-4">
            <router-link :to="{ name: 'LogInView' }">{{ $t("toTheLoginForm") }}</router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import isEmail from "validator/es/lib/isEmail";
import isStrongPassword from "validator/es/lib/isStrongPassword";
import equals from "validator/es/lib/equals";
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";

export default {
  name: "ResetPasswordView",
  components: {
    LogoComponent,
    SubmitButtonComponent,
  },
  data() {
    return {
      isWorking: false,
      password: "",
      confirmPassword: "",
      states: {
        email: null,
        password: null,
        confirmPassword: null,
      },
    };
  },
  computed: {
    submitButtonState() {
      if (!isEmail(this.email)) {
        return "missingFields";
      }

      if (!this.states.password) {
        return "missingFields";
      }

      if (!this.states.confirmPassword) {
        return "missingFields";
      }

      if (this.isWorking) {
        return "working";
      }

      return "";
    },
    email() {
      return this.$route.query.email;
    },
    prt() {
      return this.$route.query.prt;
    },
  },
  watch: {
    password(password) {
      if (password === "") {
        this.states.password = null;
      } else {
        this.states.password = isStrongPassword(password, {
          minLength: 8,
          minLowercase: 2,
          minUppercase: 2,
          minNumbers: 2,
          minSymbols: 0,
        });
      }
    },
    confirmPassword(password) {
      if (password === "") {
        this.states.confirmPassword = null;
      } else {
        this.states.confirmPassword = equals(password, this.password);
      }
    },
  },
  methods: {
    async resetPassword() {
      this.isWorking = true;

      const resetPassword = httpsCallable(functions, "resetPassword");
      await resetPassword({
        email: this.email,
        password: this.password,
        prt: this.prt,
      })
        .then(() => {
          this.isWorking = false;
          this.$router.replace({ name: "ResetPasswordSuccessView" })
        })
        .catch((error) => {
          this.isWorking = false;
          console.error(error);
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          });
        });
    },
  },
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
