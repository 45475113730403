export default {
  companyName: "ComLine",
  imprint: `
    <h3>Impressum</h3>
    <p>
      <strong>ComLine GmbH Value Added Distribution</strong><br />
      Lise-Meitner-Straße 16<br />
      24941 Flensburg<br />
      Deutschland
    </p>
    <p>
      Telefon: +49 461 77 303 300<br />
      Fax: +49 461 77 303 390<br />
      E-Mail: info@comline-shop.de
    </p>
    <p>
      Geschäftsführer: Harald Rapp, Richard Gäbel, Anja Sobottka
    </p>
    <p>
      Registergericht: Amtsgericht Flensburg<br />
      Registernummer: HRB 9025 FL<br />
      Umsatzsteuer-ID: DE 815306628
    </p>
    <p>
      Verantwortliche i.S.d. § 55 II RStV: Harald Rapp, Richard Gäbel, Anja Sobottka
    </p>

    <h3>
      Rechtlicher Hinweis
    </h3>
    <p>
      Die ComLine GmbH übernimmt keine Haftung oder Garantie für die Aktualität, Vollständigkeit und Richtigkeit
      der auf dieser Website bereitgestellten Informationen.
    </p>
    <p>
      Die ComLine GmbH behält sich das Recht vor, ohne vorherige Ankündigung, Änderungen oder Ergänzungen der
      bereitgestellten Informationen vorzunehmen.
    </p>
    <p>
      Der Inhalt dieser Website ist urheberrechtlich geschützt. Sie dürfen hier bereitgestellte Texte und
      Grafiken ohne die schriftliche Erlaubnis des Eigentümers ganz oder ausschnittsweise weder speichern, noch
      vervielfältigen. Hierbei bitten wir zu beachten, dass das Urheberecht für Bilder und Texte, der in unserem
      Online-Shop angebotenen Produkte, bei dem jeweiligen Hersteller liegt. Eine Genehmigung für die Nutzung
      dieser Inhalte ist daher direkt beim Hersteller zu erfragen.
    </p>
    <p>
      Die ComLine GmbH haftet nicht für direkte oder indirekte Schäden, einschließlich entgangenen Gewinns,
      welche aufgrund von oder in sonstiger Verbindung mit Informationen entstehen, die auf dieser Web-Site
      bereitgehalten werden.
    </p>
  `,
  adobeTc: `
  <h3>ADOBE VALUE INCENTIVE PLAN – ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h3>
  <p><br> Diese Teilnahmevereinbarung für Unternehmen („Vertrag“) für den Value Incentive Plan („VIP“ oder „Programm“) legt die Bedingungen Ihrer Teilnahme am Programm dar. Der Vertrag gilt ab dem Datum der Online-Anmeldung. Dieser Vertrag wird zwischen Adobe und dem Kunden geschlossen, der in der Online-Anmeldung als Teilnehmer genannt ist. „Adobe“ steht für Adobe Inc., ein Unternehmen nach dem Recht von Delaware mit Geschäftssitz in 345 Park Avenue, San Jose, CA 95110-2704, USA, wenn der Vertrag mit einer Organisation eines Teilnehmers abgeschlossen wird, die in den Vereinigten Staaten, Kanada oder Mexiko ansässig ist, für ADOBE SYSTEMS SOFTWARE IRELAND LIMITED, ein Unternehmen in Irland mit Geschäftssitz in 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Irland, als Bevollmächtigten von Adobe Systems Pty Ltd (ABN 72 054 247 835), wenn der Vertrag mit einer Organisation eines Teilnehmers abgeschlossen wird, die in Australien ansässig ist, oder anderenfalls für ADOBE SYSTEMS SOFTWARE IRELAND LIMITED, ein Unternehmen in Irland mit Geschäftssitz in 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Irland. Der Teilnehmer erwirbt die Lizenz für sämtliche Software, Dienste und Angebote von Adobe U.S., die im Rahmen des Programms (im Folgenden „Produkte“) verfügbar sind und innerhalb der USA (einschließlich US-Territorien und Militärstützpunkte weltweit), Kanada oder Mexiko installiert und verwendet werden. Der Teilnehmer erwirbt die Lizenz für alle Produkte, die in Australien installiert und verwendet werden, von Adobe Ireland, als Bevollmächtigten von Adobe Systems Pty Ltd (ABN 72 054 247 835). Der Teilnehmer erwirbt die Lizenz für sämtliche Produkte außerhalb der USA von Adobe Ireland.</p>
  <h4><br> 1.&nbsp;Beschreibung des Programms.</h4>
  <p><br> <b>1.1&nbsp;<i>Allgemeines und Programmlaufzeit</i></b><i>.</i> Das VIP-Programm ist ein flexibles Lizenzprogramm, das darauf ausgelegt ist, qualifizierten und berechtigten Kunden die Verwaltung und die Bereitstellung von über das Programm erworbenen Produktlizenzen zu ermöglichen. Die Teilnahme am VIP-Programm ist ein Abonnement mit Lizenz-Abonnementlaufzeiten, wie in Abschnitt&nbsp;3.4 dieses Vertrags beschrieben. Hat die Organisation über die Benutzeroberfläche der VIP Administration die Teilnahme beantragt und hat Adobe dieser zugestimmt, ist die Organisation Teilnehmer des Programms („Teilnehmer“) bis (a) zur Kündigung des Programms durch Adobe oder (b) zum Auslaufen dieses Vertrags, wobei das jeweils früher eintretende Ereignis ausschlaggebend ist. Die Teilnahme am Programm unterliegt des Weiteren den im Leitfaden zum Programm enthaltenen Bedingungen, die von Zeit zu Zeit aktualisiert werden können und unter <a href="https://www.adobe.com/go/vip_program_guide_en" target="_blank">www.adobe.com/go/vip_program_guide_de</a> („Leitfaden zum Programm“) abrufbar sind. Der Leitfaden zum Programm wird hiermit durch Bezugnahme Bestandteil des Vertrags. Adobe kann die Programmbedingungen nach eigenem Ermessen ändern. Bei einer Änderung der Programmbedingungen kann es erforderlich sein, dass der Teilnehmer die Programmbedingungen in der Konsole erneut akzeptiert.</p>
  <p><br> <b>1.2&nbsp;<i>Kündigung.</i></b> Ungeachtet der vorstehenden Bestimmungen kann jede Vertragspartei den Vertrag unter Einhaltung einer Kündigungsfrist von dreißig (30) Tagen durch eine ordentliche oder außerordentliche Kündigung kündigen. Die Kündigung dieses Vertrags hat keine Auswirkung auf die Verpflichtungen des Teilnehmers bezüglich der vor dem Kündigungsdatum bestellten Produkte, einschließlich u. a. aller Abonnementbedingungen oder Zahlungsverpflichtungen. Des Weiteren kann Adobe diesen Vertrag bei einer wesentlichen Vertragsverletzung durch schriftliche Benachrichtigung mit sofortiger Wirkung kündigen (einschließlich, aber nicht beschränkt auf Veruntreuung oder Verletzung der Rechte am geistigen Eigentum von Adobe). Bei Kündigung dieses Vertrags muss die Organisation des Teilnehmers die Nutzung der Produkte umgehend einstellen, die Produkte von allen Computersystemen und von der gesamten IT-Ausrüstung, auf denen diese installiert sind, löschen und Datenträger, die die Produkte enthalten, sowie zugehörige Materialien an den Account-Manager des Teilnehmers zurückgeben. Der Account-Manager ist entweder (i) ein Fachhändler, wenn eine Transaktion über einen Fachhändler erfolgt, oder (Ii) ein Adobe-Vertreter, wenn eine Transaktion mit Adobe erfolgt.</p>
  <p>&nbsp;</p>
  <p><b>1.3&nbsp;<i>Nutzungsbedingungen.</i></b> Der Zugang zu den Produkten und deren Verwendung unterliegen den entsprechenden Nutzungsbedingungen von Adobe („Nutzungsbedingungen“), die Sie unter <a href="https://www.adobe.com/legal/terms.html">https://www.adobe.com/de/legal/terms.html</a> einsehen können. Die entsprechenden Nutzungsbedingungen werden hiermit durch Bezugnahme (einschließlich der Bedingungen hinsichtlich des geltenden Rechts und Gerichtsstands) Bestandteil dieses Vertrags. Im Fall eines Konflikts zwischen den Bedingungen dieses Vertrags und den Nutzungsbedingungen haben die Bedingungen dieses Vertrags Vorrang.</p>
  <p><br> <b>1.4&nbsp;<i>Programmprodukte.</i> </b>Eine vollständige Liste der über das Programm erhältlichen Produkte kann der Teilnehmer von seinem Account-Manager beziehen oder auf den Webseiten zum Programm auf Adobe.com unter <a href="http://www.adobe.com/howtobuy/buying-programs/vip.html">http://www.adobe.com/de/howtobuy/buying-programs/vip.html</a> abrufen. Alle Produkte, die durch das Programm erworben wurden, sind ausschließlich zur Verwendung in der Organisation des Teilnehmers bestimmt, und alle Weiterverkäufe und Unterlizenzierungen bzw. eine sonstige Weitergabe sind verboten, wenn nicht anderweitig in Abschnitt&nbsp;4.1 und/oder im Programmleitfaden angegeben. Bestimmte Angebote zum Erwerb einer Lizenz können durch den Kauf von Gutschriften wie im Programmleitfaden beschrieben verfügbar sein.</p>
  <p>&nbsp;</p>
  <p><b>1.5&nbsp;<i>Tochter- und Schwesterorganisationen.</i></b> Adobe bestätigt, dass Tochter- und Schwesterorganisationen des Teilnehmers die im Rahmen des Programms bereitgestellten Produkte bestellen können. Der Kunde bleibt aber letztendlich für etwaige Handlungen oder Unterlassungen einer derartigen Tochter- oder Schwesterorganisation haftbar. „Tochter- oder Schwesterorganisation“ bezeichnet im Zusammenhang mit einem Teilnehmer eine juristische Person, die den Teilnehmer kontrolliert, vom Teilnehmer kontrolliert wird oder mit dem Teilnehmer unter gemeinsamer Kontrolle steht. Im Sinne von Abschnitt 1.5 bezeichnet „Kontrolle“ die direkte oder indirekte Befugnis, die Angelegenheiten der anderen juristischen Person durch den Besitz von mindestens 50&nbsp;% der Aktien, Stimmrechte, Beteiligungen oder Anteile an dieser juristischen Person zu lenken.</p>
  <h4><br> 2.&nbsp;Teilnahme.</h4>
  <p><br> <b>2.1&nbsp;<i>Adobe&nbsp;ID und VIP-ID.</i></b> Zur Anmeldung beim Programm ist eine Adobe&nbsp;ID erforderlich. Jedem Teilnehmer wird eine VIP-ID zugewiesen, die in allen Bestellungen angegeben werden muss. Der Teilnehmer haftet für alle Handlungen und Versäumnisse von Personen, die Zugang zu Produkten erhalten, sowie für die Nutzung der Produkte durch den Teilnehmer.</p>
  <p><br> <b>2.2&nbsp;<i>Konsole.</i></b> Die Benutzeroberfläche für die Programmverwaltung ist die „Admin Console“. Nach Annahme dieses Vertrags wird der Person, die diesen Vertrag im Namen der Organisation angenommen hat, die Rolle des Vertragsverantwortlichen zugewiesen. Der Vertragsverantwortliche kann Systemadministratoren (jeweils ein „Administrator“) hinzufügen. Der Vertragsverantwortliche und jeder Administrator erhalten Zugang zur Admin Console, durch den sie Zugang zum Produkt haben, die Abonnements verwalten und Kontoinformationen anzeigen können. Die Admin Console ermöglicht es dem Administrator, zusätzliche Nutzer in seiner Organisation einzuladen, auf die Admin Console zuzugreifen. Der Teilnehmer erteilt jedem Administrator oder Vertragsverantwortlichen die Erlaubnis, im Namen des Teilnehmers zu handeln.</p>
  <p><br> <b>2.3&nbsp;<i>Vertraulichkeit</i>.</b> Der Teilnehmer muss die VIP-ID vertraulich behandeln und darf diese nicht weitergeben oder offenlegen.</p>
  <p><br> <b>2.4&nbsp;<i>Mitgliedschaft</i></b>. Die Bestimmungen von Anhang B gelten für die Teilnahme von Bildungseinrichtungen, Behörden und gemeinnützigen Organisationen. Der Teilnehmer muss einen gesonderten VIP-Mitgliedsvertrag für alle Produkte verwenden, die für die Nutzung in der Volksrepublik China verfügbar gemacht und bestellt werden.</p>
  <h4><br> 3.&nbsp;Bestellung, Preisinformationen und Ausführung.</h4>
  <p><br> <b>3.1<i>&nbsp;Bestellung und Preise</i>.</b> Der Teilnehmer gibt Produktbestellungen beim Account-Manager auf. Alle Gebühren werden vom Account-Manager des Teilnehmers festgesetzt. Angelegenheiten wie Preise, Lieferung und Zahlungsbedingungen müssen zwischen dem Teilnehmer und dem Account-Manager des Teilnehmers vereinbart werden. Adobe kann keinen bestimmten Preisnachlass garantieren, es sei denn, Adobe ist der Account-Manager des Teilnehmers.</p>
  <p><br> <b>3.2&nbsp;<i>Zugriff, Admin Console-Bereitstellung und Erfüllung.</i></b><i></i> Nach Erwerb der Mitgliedschaft erhält der Administrator des Teilnehmers über die Admin Console Zugang zu den verfügbaren Produkten. Der Teilnehmer kann beliebig viele Produkte aus der Admin Console hinzufügen und sofortigen Zugriff auf solche Produkte erhalten. Adobe muss innerhalb der Nachfrist eine Bestellung für solche Produkte erhalten.&nbsp;</p>
  <p><br> <b>3.3 <i>Nachfrist für Produkte, die in der Admin Console hinzugefügt wurden.</i></b><i></i> Die Nachfrist beträgt 14 Tage nach dem Hinzufügen solcher Produkte. Erhält Adobe innerhalb der Nachfrist keine Bestellung für diese Produkte, dann kann der Teilnehmer keine weiteren Produkte mehr hinzufügen, bis die Bezahlung aller hinzugefügten Produkte erfolgt ist. Der Teilnehmer kann die Anzahl der bereitgestellten Produkte in der Konsole verwalten.</p>
  <p><br> <b>3.4&nbsp;<i>Vertragsjahrestag, Abonnementzeitraum und Verlängerungen.</i></b></p>
  <p>&nbsp;</p>
  <p><b>3.4.1&nbsp;<i>Jahrestag</i>.</b>&nbsp;Sofern von Adobe nicht anders angegeben, ist der Jahrestag des Teilnehmers der Tag zwölf Monate, nachdem Adobe die Erstbestellung vom Teilnehmer angenommen hat („Jahrestag“).</p>
  <p>&nbsp;</p>
  <p><b>3.4.2&nbsp;<i>Abonnementlaufzeit.</i> </b>Die Abonnementlaufzeit bezeichnet den Zeitraum, in dem ein Teilnehmer die Produkte nutzen kann. Sie umfasst die Abonnement-Erstlaufzeit sowie alle etwaigen Verlängerungslaufzeiten. Die Abonnement-Erstlaufzeit bezeichnet den Zeitraum, der mit dem Datum der Erstbestellung des Teilnehmers beginnt und am Tag vor dem Jahrestag endet. Mit der Verlängerung des Produktabonnements beginnt am Jahrestag eine Abonnement-Verlängerungslaufzeit, die bis zum Tag vor dem nächsten Jahrestag läuft. Die Verwendung von Abonnementprodukten und verbundenen Diensten endet gemeinsam mit der Abonnementlaufzeit am Tag vor dem Jahrestag. Die meisten Gutschriften müssen innerhalb einer einzigen Abonnementlaufzeit verwendet werden, und alle nicht genutzten Gutschriften verfallen am letzten Tag der Abonnementlaufzeit. Weitere Informationen finden Sie im Programmleitfaden.</p>
  <p>&nbsp;</p>
  <p><b>3.4.3&nbsp;<i>Abonnementverlängerungen</i>.</b>&nbsp;Adobe bzw. der Account-Manager werden angemessene Anstrengungen unternehmen, um den Teilnehmer vor dem Ende der Abonnementlaufzeit zu benachrichtigen. Abonnements müssen vor dem Jahrestag verlängert werden, um eine kontinuierliche Verwendung der Produkte zu gewährleisten.&nbsp;</p>
  <p><br> <b>3.5&nbsp;<i>Upgrade-Schutz</i>.</b> Der Erwerb von Abonnementprodukten umfasst das Recht auf Upgrades, d.&nbsp;h. der Teilnehmer ist zum Empfang der neuesten allgemein erhältlichen Version eines im Rahmen des Programms erworbenen Abonnementprodukts berechtigt, solange das Produktabonnement zum Zeitpunkt der Markteinführung der neuen Produktversion durch Adobe bezahlt und aktiv ist.</p>
  <p><br> <b>3.6&nbsp;<i>Rückgabe</i>.</b> Unbeschadet eventueller Rechte eines Teilnehmers nach den Verbraucherschutzgesetzen des Landes, in dem der Teilnehmer ansässig ist, kann der Teilnehmer ein einmal installiertes Produkt oder ein Produkt, auf das bereits ein Zugriff erfolgt ist, nicht zurückgeben. Wenn ein Teilnehmer ein Produkt vor der Installation zurückgeben möchte, muss die gesamte Bestellung zurückgegeben werden. Der Teilnehmer muss die Rückgabe von im Rahmen des Vertrags erworbenen Produkten beim Account-Manager des Teilnehmers beantragen. Vorbehaltlich etwaiger Gewährleistungsansprüche müssen Anträge auf Rückgabe innerhalb von vierzehn (14) Tagen nach dem Datum der ursprünglichen Produktbestellung durch den Teilnehmer beim Account-Manager des Teilnehmers beantragt werden. Adobe muss jede beantragte Rückgabe wie im Programmleitfaden näher beschrieben genehmigen, bevor die Rückgabe zulässig ist.</p>
  <p><br> <b>3.7&nbsp;<i>Direktbestellungen bei Adobe</i>. </b>Wenn ein Teilnehmer eine Bestellung direkt bei Adobe aufgibt, gilt dieser Abschnitt. Teilnehmer können jederzeit Produkte über die Admin Console oder einen Vertreter von Adobe hinzufügen, solange eine Bestellung für alle Produkte bei einem Vertreter von Adobe aufgegeben wird. Für Produkte gelten die zum Zeitpunkt der Bestellung aktuellen Preise, und zwar anteilig auf der Grundlage der noch verbleibenden Tage der Abonnementlaufzeit. Ungeachtet des Wortlauts in den Klauseln 3.2, 3.3 und 4.2, kann Adobe Ihnen beim Hinzufügen von Produkten über Admin Console diese Produkte zum dann aktuellen Preis direkt in Rechnung stellen. Für Ihre Bestellung kann eine Prüfung der Kreditwürdigkeit erforderlich sein. Dem Teilnehmer wird der Produktpreis zuzüglich anwendbarer Steuern in Rechnung gestellt. Sämtliche bestellten Produkte müssen vom Teilnehmer innerhalb von 30 Tagen ab Rechnungsdatum gemäß Rechnung in voller Höhe bezahlt werden. Für alle Beträge, die nicht bei Fälligkeit bezahlt werden, fallen ab dem Fälligkeitsdatum bis zum Zeitpunkt, zu dem der überfällige Betrag einschließlich der entsprechenden Zinsen in voller Höhe bezahlt ist, Zinsen zu einem Zinssatz von 1,0&nbsp;% pro Monat oder zum höchsten gesetzlich zulässigen Zinssatz an, je nachdem, welcher Zinssatz niedriger ist.</p>
  <p>&nbsp;</p>
  <h4>4. VIP Marketplace.</h4>
  <p>&nbsp;</p>
  <p><b>4.1</b>.&nbsp; Abschnitt&nbsp;4 „VIP Marketplace“ gilt für Produkte, die über VIP Marketplaces lizenziert wurden.&nbsp; Sofern von Adobe nicht anders angegeben, verlängern sich Abonnements, die über VIP Marketplaces angeboten werden, trotz des Wortlauts in Klausel&nbsp;3.4.3 oben automatisch am Jahrestag des Teilnehmers.&nbsp; Der Teilnehmer kann Produktabonnements gemeinsam mit dem Account-Manager anpassen.</p>
  <p>&nbsp;</p>
  <p><b>4.2 <i>Nachfrist für VIP Marketplace</i>.</b>&nbsp; Ungeachtet des Wortlauts in den Klauseln 3.2 und 3.3 beträgt die Nachfrist für VIP&nbsp;Marketplace-Produkte sieben Tage nach dem Hinzufügen von Produkten und nicht 14 Tage. &nbsp; Erhält Adobe innerhalb der Nachfrist keine Bestellung für Produkte, die über den VIP Marketplace hinzugefügt wurden, werden diese Produkte entfernt.&nbsp;&nbsp;</p>
  <h4>&nbsp;</h4>
  <h4>5.&nbsp;Verschiedenes.</h4>
  <p>&nbsp;</p>
  <p><b>5.1&nbsp;<i>Lizenzübertragung.</i></b> Nutzungsbedingungen von Produkten regeln keine Beschränkungen bezüglich Übertragung von Produkten, die im Rahmen oder in Verbindung mit diesem Vertrag lizenziert werden. Unter gewissen Umständen kann Adobe die Übertragung von Produktlizenzen im Rahmen dieses Vertrags nach eigenem und alleinigem Ermessen gestatten. Anträge auf Lizenzübertragung sind an den Kundendienst von Adobe zu richten, einschließlich einer Beschreibung des Grundes für die geplante Übertragung und der Kontaktinformationen des Übertragungsempfängers. Weitere Informationen finden Sie im Programmleitfaden. Zur Klarstellung wird darauf hingewiesen, dass alle Produkte lizenziert und nicht verkauft werden.</p>
  <p><br> <b>5.2&nbsp;<i>Lizenzüberprüfung.</i></b> Der Teilnehmer muss über Systeme und/oder Verfahren verfügen, die eine ordnungsgemäße Aufzeichnung der Anzahl der installierten und/oder bereitgestellten Produktkopien gewährleisten, und die Aufzeichnungen über die Installation und/oder die Bereitstellung der Produkte müssen bis zwei (2) Jahre nach Kündigung oder Ablauf des Vertrags aufbewahrt werden. Adobe und/oder seine Vertreter sind berechtigt, höchstens einmal im Jahr eine mindestens dreißig (30) Tage im Voraus schriftlich angekündigte Überprüfung der Produktinstallation/Produktbereitstellung beim Teilnehmer durchzuführen. Im Zuge einer derartigen Überprüfung muss der Teilnehmer einen nicht überarbeiteten korrekten Bericht über alle vom Teilnehmer installierten/bereitgestellten Produkte sowie Produkte, auf die der Teilnehmer zugreift, sowie alle gültigen Kaufbelege für die gesamten Produkte innerhalb von dreißig (30) Tagen nach einer entsprechenden Aufforderung vorlegen. Wenn die Überprüfung ergibt, dass die Bestimmungen für die Produktlizenzen nicht eingehalten werden, erwirbt der Teilnehmer die erforderlichen Lizenzen innerhalb von dreißig (30) Tagen nach einer entsprechenden Benachrichtigung. Adobe behält sich ungeachtet des Vorstehenden das Recht vor, die Lizenzinstallation und -bereitstellung durch den Teilnehmer nach schriftlicher Benachrichtigung zehn (10) Werktage im Voraus während der normalen Geschäftszeiten vor Ort zu überprüfen. Dieser Abschnitt 4.2 gilt über die Beendigung dieses Vertrags hinaus für einen Zeitraum von zwei (2) Jahren fort.</p>
  <p><br> <b>5.3&nbsp;<i>Verwendung von Informationen.</i></b> Adobe darf Informationen über den Teilnehmer oder die Tochter- oder Schwesterorganisation einschließlich Name und Kontaktdaten zur Erfüllung vertraglicher Verpflichtungen verwenden. Weitere Informationen finden Sie im Datenschutz-Center von Adobe (<a href="https://www.adobe.com/privacy.html">adobe.com/de/privacy</a>).</p>
  <p><br> <b>5.4&nbsp;<i>Klagebefugnis.</i></b> Die Vertragsparteien vereinbaren, dass das Übereinkommen der Vereinten Nationen über Verträge über den internationalen Warenkauf auf diesen Vertrag keine Anwendung findet. Wenn ein Teilnehmer ein Organ der Bundesregierung der Vereinigten Staaten von Amerika ist, ist der Teilnehmer damit einverstanden, dass Adobe die Klagebefugnis und das Recht hat, jeden Vertragsbruch im Zusammenhang mit diesem Vertrag unter dem Contracts Disputes Act [Gesetz zu Vertragsstreitigkeiten] von 1978 („Gesetz zu Vertragsstreitigkeiten“) geltend zu machen und sich darauf zu berufen.</p>
  <p><br> <b>5.5&nbsp;<i>Allgemeines.</i> </b>Die Vertragsparteien handeln unabhängig voneinander, und dieser Vertrag kann nicht dahingehend ausgelegt werden, dass eine Vertragspartei der Vertreter oder Mitunternehmer der anderen Vertragspartei ist. Der Teilnehmer ist nicht berechtigt, diesen Vertrag (kraft Gesetzes oder auf andere Weise) ohne die vorherige schriftliche Zustimmung von Adobe zu übertragen, und jede unzulässige Übertragung ist null und nichtig. Adobe kann diesen Vertrag nach eigenem Ermessen ohne eine vorherige schriftliche Genehmigung des Teilnehmers übertragen. Dieser Vertrag ist für zulässige Nachfolger bzw. Rechtsnachfolger bindend und kommt diesen zugute. Änderungen bedürfen der Schriftform. Dieser Vertrag (einschließlich des Programmleitfadens, der anwendbaren Nutzungsbedingungen und Anhänge oder ggf. Online-Anmeldeinformationen) ist der vollständige Vertrag zwischen den Vertragsparteien bezüglich des Vertragsgegenstands. Sollte sich eine Bestimmung dieses Vertrags als unwirksam erweisen, bleibt der Rest des Vertrags vollumfänglich in Kraft und wirksam.</p>
  <p><br> Dieser Vertrag wird nur in englischer Sprache aufgesetzt und ausgefertigt. Die englischsprachige Version ist unter allen Umständen rechtskräftig und jede andere Version dieses Vertrags in einer anderen Sprache ist nicht verbindlich und nicht wirksam. Außerdem stimmt jede der Vertragsparteien zu, dass durch eine Unterschrift von einem Teilnehmer oder von Adobe unter eine nicht-englischsprachige Version die Rechtsgültigkeit und Verbindlichkeit der englischsprachigen Version von den Vertragsparteien anerkannt wird, selbst wenn die eigentliche englischsprachige Version nicht unterzeichnet wurde. Ohne Einschränkung der vorherigen Ausführungen hat die englischsprachige Version und ihre Auslegung Rechtsgültigkeit, falls zwischen der englischsprachigen Version dieses Vertrags und jeglicher übersetzten Version dieses Vertrags Widersprüche oder Unvereinbarkeiten bestehen. Alle Ankündigungen oder Benachrichtigungen in Zusammenhang mit diesem Vertrag sowie alle aus diesem Vertrag entstehenden Rechtsstreitigkeiten werden in englischer Sprache ausgeführt oder beigelegt.</p>
  <p><br> <b>5.6&nbsp;<i>Dienste.</i></b> Das Produkt kann mit einer Reihe von Diensten integriert sein, die von Adobe oder Dritten betrieben werden und von Anwendern erstellte Inhalte enthalten können, die (a) für Minderjährige ungeeignet, (b) in einigen Ländern verboten oder (c) zum Betrachten am Arbeitsplatz unpassend sein können. Eine vollständige Liste der integrierten Dienste finden Sie unter <a href="https://www.adobe.com/go/integratedservices">www.adobe.com/go/integratedservices_de</a>. Wenn der Teilnehmer verhindern möchte, dass Dienste mit von Anwendern erstellten Inhalten angezeigt oder aufgerufen werden, kann er (i) den Zugriff auf den Dienst im Creative Cloud Packager deaktivieren, wo diese Funktion verfügbar ist, oder (ii) den Zugang zu den Diensten über seine Netzwerk-Firewall sperren. Dienste sind für Anwender unter dreizehn (13) Jahren unter keinen Umständen verfügbar. Adobe übernimmt keinerlei Haftung für von Anwendern erstellte Inhalte, die über die Dienste verfügbar sind. Der Teilnehmer muss sicherstellen, dass die Verwendung der Dienste den gesetzlichen Vorschriften in der Rechtsordnung des Teilnehmers entspricht. Der Teilnehmer hat keinen Anspruch auf Rückerstattung, und Adobe übernimmt keinerlei Haftung, (i) wenn der Zugang zu den Diensten durch Maßnahmen von Behörden oder des Dienstanbieters verlangsamt oder gesperrt wird oder (ii) wenn Adobe angemessenerweise der Ansicht ist, dass eine derartige Sperre des Zugangs zu einigen oder allen Diensten erforderlich ist.</p>
  <p>&nbsp;</p>
  <h3>ANLAGE B<br> TEILNAHME VON BILDUNGSEINRICHTUNGEN, BEHÖRDEN UND GEMEINNÜTZIGEN ORGANISATIONEN.</h3>
  <p>&nbsp;</p>
  <h4><b>A. TEILNAHME VON BILDUNGSEINRICHTUNGEN.</b></h4>
  <p><br> Für einen Teilnehmer, der eine Bildungseinrichtung ist (siehe Definition unten), gelten die folgenden Zusatzbedingungen. Adobe behält sich das Recht vor, die Teilnahme von Bildungseinrichtungen zu kündigen, wenn der Teilnehmer keine Bildungseinrichtung ist.</p>
  <h4><br>1. Definitionen für Bildungseinrichtungen.</h4>
  <p><br> <b>1.1&nbsp;<i>Bildungseinrichtung.</i></b> Nachstehend folgt eine nicht vollständige Auflistung qualifizierter Bildungseinrichtungen: (a) (von offiziellen Zulassungsstellen) zugelassene öffentliche oder private Grund- oder weiterführende Schulen, die Vollzeitunterricht bieten; (b) zugelassene öffentliche oder private Universitäten oder Hochschulen (einschließlich akademischer Einrichtungen oder Fachhochschulen), die Titel verleihen, für deren Erlangung eine Studienzeit erforderlich ist, die mindestens zwei Jahren Vollzeitstudium entspricht; (c) von Adobe genehmigte, namentlich benannte Bildungseinrichtungen, wenn die benannten Einrichtungen von Adobe schriftlich genehmigt wurden; (d) Krankenhäuser, die sich im Alleineigentum einer anderweitig qualifizierten Bildungseinrichtung befinden und von dieser betrieben werden, wobei „im Alleineigentum befindlich und betrieben“ bedeutet, dass die Bildungseinrichtung der Alleineigentümer des Krankenhauses und die einzige Einrichtung ist, die über den täglichen Betrieb Kontrolle ausübt; und (e) akademische Forschungslabors, die eine öffentliche Einrichtung sind und von einer Bildungsbehörde des Bundes oder eines Bundeslandes anerkannt werden.</p>
  <p><br> Nachstehend folgt eine nicht vollständige Auflistung von Einrichtungen, die keine qualifizierten Bildungseinrichtungen sind: (a) nicht zugelassene Schulen; (b) Museen oder Bibliotheken; (c) Krankenhäuser, die sich nicht im Alleineigentum einer anderweitig qualifizierten Bildungseinrichtung befinden oder nicht von einer solchen betrieben werden; (d) Kirchen oder religiöse Einrichtungen, bei denen es sich nicht um zugelassene Schulen handelt; (e) Einrichtungen der Berufs- und Weiterbildung oder Berufsschulen, die Zeugnisse für Kurse wie Computersoftwareschulung oder berufliche Ausbildung vergeben und keine zugelassenen Schulen sind oder die Titel verleihen, für deren Erlangung eine Studienzeit erforderlich ist, die weniger als zwei Jahren Vollzeitstudium entspricht; (f) Militärschulen, die keine akademischen Titel verleihen, und (g) Forschungslabors, die von keiner Bildungsbehörde des Bundes oder eines Bundeslandes anerkannt werden. Einrichtungen, die von anderen Regierungsinstanzen anerkannt werden, sind beispielsweise nicht teilnahmeberechtigt.</p>
  <p><br> Die obigen Listen gelten nicht für die im unten stehenden Abschnitt 1.2 (Regionenspezifische Definition) aufgeführten Länder.</p>
  <p><br> <b>1.2&nbsp;<i>Regionenspezifische Definition für Bildungseinrichtungen.</i></b></p>
  <p>(a)&nbsp;<i></i>Länder in der Asien-/Pazifikregion mit Ausnahme von südostasiatischen Ländern wie im unten stehenden Absatz (b) definiert. Wenn die Bildungseinrichtung in Australien, Neuseeland, Indien, Sri Lanka, Festlandchina, der Sonderverwaltungszone Hongkong der Volksrepublik China, der Region Taiwan, der Republik Korea, der Volksrepublik Bangladesch, der Demokratischen Bundesrepublik Nepal, der Republik der Union von Myanmar, Pakistan, der Mongolei oder einem anderen von Adobe von Zeit zu Zeit bestimmten Land ansässig ist, hat „Bildungseinrichtung“ die von Adobe für „qualifizierte Bildungseinrichtung“ (mit Ausnahme der Abschnitte „Vollzeit- und Teilzeitlehrkörper und -personal“ sowie „Schüler“) unter <a href="http://www.adobe.com/ap/education/purchasing/qualify.html">http://www.adobe.com/ap/education/purchasing/qualify.html</a> (oder auf der Nachfolge-Website) festgelegte Bedeutung in der von Zeit zu Zeit von Adobe aktualisierten Fassung.</p>
  <p><br> (b)&nbsp;<i>Südostasiatische Länder.</i> Wenn die Bildungseinrichtung in Indonesien, Malaysia, auf den Philippinen, Singapur, Thailand oder Vietnam ansässig ist, hat „Bildungseinrichtung(en)“ die jeweilige von Adobe auf <a disablelinktracking="false" href="https://www.adobe.com/go/edu_entity_sea" target="_blank">https://www.adobe.com/go/edu_entity_sea_de</a>&nbsp;(oder auf der Nachfolge-Website) festgelegte Bedeutung in der von Adobe gelegentlich aktualisierten Fassung.</p>
  <p><br> (c)&nbsp;<i>Japan</i>. Wenn die Bildungseinrichtung in Japan ansässig ist, hat „Bildungseinrichtung“ oder „Bildungsinstitution“ die jeweilige von Adobe unter <a href="https://helpx.adobe.com/jp/x-productkb/policy-pricing/cq081918191.html">https://helpx.adobe.com/jp/x-productkb/policy-pricing/cq081918191.html</a> (oder auf der Nachfolge-Website) festgelegte Bedeutung in der von Zeit zu Zeit von Adobe aktualisierten Fassung.</p>
  <p>&nbsp;</p>
  <p><b>1.3&nbsp;<i>Grund- und weiterführende Schulen.</i></b> Grund- und weiterführende Schulen sind im Programmleitfaden definiert. Adobe kann Teilnehmern, bei denen es sich um Grund- und weiterführende Schulen handelt, Angebote zur Verfügung stellen. Adobe behält sich das Recht vor, Lizenzen und Teilnahmen von Grund- und weiterführenden Schulen zu kündigen, falls der Teilnehmer keine Grund- oder weiterführende Schule ist, wie im Programmleitfaden festgelegt. Weitere Details sind dem Leitfaden zum VIP-Programm für Bildungseinrichtungen zu entnehmen.</p>
  <h4><br> <b>B.&nbsp;TEILNAHME VON BEHÖRDEN.</b></h4>
  <p><br> Für den Teilnehmer, der eine Regierungsinstanz (siehe unten stehende Definition) darstellt, gelten die folgenden zusätzlichen Bedingungen. Adobe behält sich das Recht vor, die Teilnahme von Regierungseinrichtungen zu kündigen, wenn der Teilnehmer keine Regierungseinrichtung ist.</p>
  <h4><br> 1.&nbsp;Für teilnehmende Behörden geltende Definitionen.</h4>
  <p><b><i>Regierungseinrichtung</i>.</b>&nbsp;Eine Teilnahme ist nur dann möglich, wenn der Teilnehmer (und jede Tochter- oder Schwesterorganisation) eine „Behörde“ ist. Unter einer Behörde ist Folgendes zu verstehen: (a) Ämter, Abteilungen, Kommissionen, Dienststellen, Einrichtungen, Räte oder Behörden der Legislative, Exekutive oder Judikative auf Bundes- bzw. nationaler Ebene; (b) Ämter, Abteilungen, Kommissionen, Dienststellen, Einrichtungen, Räte, Organisationen oder Behörden der Legislative, Exekutive oder Judikative auf regionaler und kommunaler Ebene, die aufgrund der Verfassung oder Gesetze des jeweiligen Staates bestehen, und zwar einschließlich der Verwaltungsstellen von Bezirken, Regionen und Bundesländern oder (c) von bundesstaatlichen, einzelstaatlichen oder lokalen Regierungen errichtete und/oder finanzierte staatliche Stellen oder Einrichtungen, die berechtigt sind, Regierungsgewalt über Bürger, Unternehmen oder andere Behörden auszuüben oder diese zu unterstützen. Zur Klarstellung wird darauf hingewiesen, dass „gewinnorientierte“ privatwirtschaftliche Unternehmen, gemeinnützige Organisationen, Berufs- und Wirtschaftsverbände sowie Gewerkschaften nicht teilnahmeberechtigt sind. Des Weiteren ausgeschlossen sind private Organisationen, die im Auftrag von oder gemeinsam mit Behörden Aufträge ausführen. Der Teilnehmer erklärt gegenüber Adobe, dass es sich beim Teilnehmer und seinen Tochter- und Schwesterorganisationen um Regierungsinstanzen handelt. Eine Liste qualifizierter „Behörden“ für Japan ist abrufbar unter:&nbsp;<a href="http://www.adobe.com/jp/aboutadobe/volumelicensing/pdfs/cl5_government_license_table.pdf" target="_blank">http://www.adobe.com/jp/aboutadobe/volumelicensing/pdfs/cl5_government_license_table.pdf</a>.</p>
  <p><br> <b>Für Frankreich:</b> Eine Behörde ist entweder ein Amt, ein Minister, eine Kommission, ein Vorstand, eine Dienststelle oder ein Rat (auf nationaler, regionaler oder lokaler Ebene); eine Stadt; eine Region oder eine Stelle, die dem öffentlichen Recht von Frankreich und der Verwaltung einer Regierungsstelle unterliegt.</p>
  <h4><br> 2.&nbsp;Für teilnehmende Behörden geltende Bedingungen.</h4>
  <p><br> <b>2.1&nbsp;<i>Zusätzliche Einschränkungen</i>.</b> Für die US-Bundesregierung als Teilnehmer gilt, dass alle Bestellungen FAR&nbsp;52.232-18 (Verfügbarkeit von Mitteln) und FAR&nbsp;52.232-19 (Verfügbarkeit von Mitteln für das nachfolgende Fiskaljahr) unterliegen und die US-Bundesregierung als Teilnehmer kein Produkt bereitstellen wird, sofern nicht die entsprechenden Mittel zur Verfügung stehen. Soweit staatliche oder lokale Behörden ähnlichen Anforderungen unterliegen, dürfen diese Einrichtungen keine Produkte bereitstellen, wenn sie nicht über die entsprechenden Mittel verfügen.</p>
  <p><br> <b>2.2&nbsp;<i>Kündigung</i>.</b>Dieser Vertrag kann von einem Teilnehmer, der der US-Bundesregierung angehört, gemäß FAR 52.249-1 (Ordentliche Kündigung durch die Regierungsbehörde) gekündigt werden. Adobe kann die Vertragsbedingungen nach eigenem Ermessen ändern.</p>
  <p><br> <b>2.3&nbsp;<i>US-Bundesbehörden als Mitglieder</i>.</b> Hinweis für US-Bundesbehörden als Endanwender (Handelsartikel): Die vertragsgemäß bereitgestellten Produkte sind „Handelsware(n)“ im Sinne von 48 C.F.R. § 2.101, bestehend aus „kommerzieller Computer-Software“ und „Begleitmaterial für kommerzielle Computer-Software“ und damit verbundenen Diensten im Sinne von 48 C.F.R. § 12.212 bzw. 48 C.F.R. § 227.7202, wie jeweils anwendbar. In Übereinstimmung mit 48 C.F.R. § 12.212 oder 48 C.F.R. §§ 227.7202-1 bis 227.7202-4, soweit anwendbar, wird US-amerikanischen Bundesbehörden als Endbenutzer eine Lizenz über handelsübliche Computersoftware und die Dokumentation zu handelsüblicher Computer-Software zur Verfügung gestellt, und zwar (a) ausschließlich als Handelsartikel und (b) ausschließlich mit solchen Rechten versehen zur Verfügung gestellt, die alle anderen Endbenutzer gemäß den hier aufgeführten Vertragsbestimmungen und den Nutzungsbedingungen erhalten. Unveröffentlichte Rechte bleiben gemäß den Gesetzen der Vereinigten Staaten von Amerika Adobe Inc., 345 Park Avenue, San Jose, CA 95110-2704, USA, vorbehalten.</p>
  <h4><br> C. GEMEINNÜTZIGE ORGANISATIONEN.</h4>
  <p><br> Weitere Bedingungen, die für gemeinnützige Teilnehmer gelten, sind im Programmleitfaden enthalten. Adobe behält sich das Recht vor, Mitgliedschaften zu kündigen, falls der Teilnehmer keine teilnahmeberechtigte gemeinnützige Organisation ist, wie in <a href="https://helpx.adobe.com/buying-programs/non-profit.html">https://helpx.adobe.com/de/buying-programs/non-profit.html</a> beschrieben.<br>
  </p>
  `,
  comlineTc:`
  <ol class="agb">
    <li>
      <strong>Allgemeines</strong>
      <ol>
        <li>
          Die nachfolgenden Allgemeinen Geschäftsbedingungen sind Bestandteil jedes von uns abgeschlossenen Vertrages.
        </li>
        <li>
          Etwaige Einkaufsbedingungen des Käufers gelten nicht, es sei denn, sie werden von uns ausdrücklich schriftlich anerkannt.
        </li>
        <li>
          Für die gesamten Rechtsbeziehungen mit dem Käufer gilt ausschließlich deutsches Recht. Die Bestimmungen des UN–Kaufrechts (CISG) gelten im Verhältnis zwischen uns und dem Käufer nicht.
        </li>
        <li>
          Diese Bedingungen gelten für den Rechtsverkehr mit Unternehmern. Wir beliefern ausschließlich Kunden, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbstständigen beruflichen Tätigkeit handeln sowie juristische Personen des öffentlichen Rechts und öffentlich–rechtliche Sondervermögen (beidseitige Handelsgeschäfte).
        </li>
        <li>
          Als Erfüllungsort für Lieferung und Zahlung sowie als Gerichtsstand wird Flensburg vereinbart, mit der Maßgabe, dass wir berechtigt sind, auch am Ort des Sitzes oder einer Niederlassung des Käufers zu klagen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Angebote, Vertragsabschluß, Preise</strong>
      <ol>
        <li>
          Unsere Angebote sind nach Menge, Preis und Lieferzeit frei bleibend. Mit seiner Bestellung erklärt der Käufer verbindlich, die von ihm bestellte Ware erwerben zu wollen. Die zum Vertragsabschluß führende Annahme kann durch die Auslieferung der Ware oder durch die Erbringung der Dienstleistung oder dadurch erklärt werden, dass wir dem Käufer in sonstiger Weise die Annahme seiner Beststellung bestätigen. Mit der Annahme durch uns ist der Vertrag zustande gekommen.
        </li>
        <li>
          Ein Widerrufsrecht steht Unternehmern und folglich dem Käufer nicht zu.
        </li>
        <li>
          Technische und gestalterische Abweichungen von Beschreibungen und Angaben in Unterlagen, sowie Modell–, Konstruktions– und Materialänderungen im Zuge des technischen Fortschritts bleiben vorbehalten, ohne dass hieraus Rechte gegen uns hergeleitet werden können.
        </li>
        <li>
          Unsere Preise sind Nettopreise. Sofern sich aus unserer Auftragsbestätigung nichts anderes ergibt, gelten sie  ab Lager Flensburg oder bei Direktversand ab deutscher Grenze bzw. deutschem Einfuhrhafen zuzüglich der am Liefertage geltenden Mehrwertsteuer, ausschließlich Verpackung, Versicherung, Fracht und ggf. Mindermengenzuschlag. Diese Positionen werden gesondert in Rechnung gestellt. Die Entsorgung der Verpackung übernimmt der Kunde auf eigene Kosten.
        </li>
      </ol>
    </li>
    <li>
      <strong>Versand</strong>
      <ol>
        <li>
          Die  Abholung  des  Liefergegenstandes hat grundsätzlich durch  den  Käufer und unverzüglich  nach  Anzeige der Bereitstellung im Werk oder Lager zu erfolgen.
        </li>
        <li>
          Soweit die Lieferung durch uns gewünscht wird, erfolgt sie grundsätzlich ab Lager und – soweit nicht im Einzelfall anders vereinbart – auf Kosten des Käufers. Wird der Liefergegenstand auf Wunsch des Käufers an diesen versandt, so geht die Gefahr des zufälligen Unterganges und der zufälligen Verschlechterung des Liefergegenstandes mit der Übergabe des Liefergegenstandes an den Spediteur, Frachtführer, Versandbeauftragten oder Abholer auf den Käufer über. Das gilt auch dann, wenn die Frachtkosten aufgrund individueller Vereinbarung von uns getragen werden oder wir den Versand durch eine Transportperson selbst durchführen.
        </li>
        <li>
          Kommt  der  Käufer  in  Annahmeverzug,  so sind wir berechtigt,  Ersatz  der uns entstehenden Aufwendungen   zu   verlangen. Mit   Eintritt   des   Annahmeverzuges   geht   die   Gefahr   der   zufälligen Verschlechterung und des zufälligen Untergangs auf den Käufer über. 
        </li> 
        <li> 
          Verzögert sich der Versand aus Gründen, die wir nicht zu vertreten haben, geht die Gefahr mit der Anzeige der Versandbereitschaft auf den Käufer über.
        </li>
      </ol>
    </li>
    <li>
      <strong>Lieferung, Rücktritt</strong>
      <ol>
        <li>
          Angaben zum Liefertermin sind unsererseits unverbindlich und stellen lediglich eine unverbindliche Schätzung dar. Die Vereinbarung fester Liefertermine bedarf der Schriftform.
        </li>
        <li>
          Der Vertragsabschluß erfolgt unter dem Vorbehalt richtiger und rechtzeitiger Selbstbelieferung. Wir sind daher zum Rücktritt berechtigt, wenn unser Lieferant den mit uns vor Abschluss des jeweiligen Verkaufsvertrages geschlossenen Einkaufsvertrag aus von uns nicht vertretenden Gründen nicht erfüllt.
        </li>
        <li>
          Darüber hinaus sind wir berechtigt, von geschlossenen Verträgen zurückzutreten, wenn sich infolge von Katastrophen, Kriegsereignissen oder ähnlichen Umständen die Warenbeschaffung gegenüber dem Zeitpunkt des Vertragsabschlusses wesentliche erschwert. Als eine wesentliche Erschwerung gilt es in jedem Falle, wenn der Marktpreis des Kaufgegenstandes zwischen dem Abschluss des jeweiligen Verkaufsvertrages und dem vorgesehenen Liefertermin um 25% oder mehr gestiegen ist.
        </li>
        <li>
          Bei von uns nicht zu vertretenden Störungen in unserem Betrieb oder Lager sowie bei behindernden behördlichen Maßnahmen wird die Lieferfrist um die Dauer der Störung verlängert. Darüber hinaus sind wir berechtigt, von abgeschlossenen Verträgen zurückzutreten, wenn die Störung ohne unser Verschulden über eine Zeitraum von mehr als vier Wochen andauert. Störung im vorgenannten Sinne schließt auch solche Betriebsunterbrechungen oder –einschränkungen ein, die durch Personalausfall größeren Umfangs infolge von Krankheiten, Arbeitskampfmaßnahmen oder ähnlichem verursacht werden.
        </li>
      </ol>
    </li>
    <li>
      <strong>Abnahme und Übernahme, Untersuchung, Mängelrüge</strong>
      <ol>
        <li>
          Der Käufer ist verpflichtet, auch Teillieferungen entgegenzunehmen. Teillieferungen können sofort in Rechnung gestellt werden.
        </li>
        <li>
          Bei Lieferungen auf Abruf stellt der Abruf innerhalb der vereinbarten Frist eine Hauptpflicht dar.
        </li>
        <li>
          Liefergegenstände   sind,   auch   wenn   sie   unwesentliche   Mängel   aufweisen,   vom   Kunden unbeschadet des Bestehens etwaiger Gewährleistungsansprüche entgegenzunehmen. 
        </li>
        <li>
          Der Käufer ist verpflichtet, den Liefergegenstand bei Übergabe auf etwaige Mängel zu untersuchen und uns solche  unverzüglich  schriftlich  anzuzeigen.  Die  einschlägigen  Regelungen  und Rechtsfolgen des HGB gelten entsprechend.  
        </li>
      </ol>
    </li>
    <li>
      <strong>Eigentumsvorbehalt</strong>
      <ol>
        <li>
          Bis zur vollständigen Bezahlung unserer Kaufpreisforderung sowie aller anderen uns gegen den Käufer zustehenden Forderungen bleibt die gelieferte Ware unser Eigentum. Der Eigentumsvorbehalt bleibt auch dann bestehen, wenn einzelne Forderungen in eine laufende Rechnung aufgenommen werden und der Saldo gezogen und anerkannt ist.
        </li>
        <li>
          Wird die von uns gelieferte Vorbehaltsware von dem Käufer be– oder verarbeitet, so erfolgt die Be– und Verarbeitung für uns als "Hersteller" im Sinne des § 950 BGB.
        </li>
        <li>
          Wird unsere Vorbehaltsware mit eigener Ware des Käufers oder mit fremder Vorbehaltsware verbunden, vermischt oder zusammen mit solcher Ware verarbeitet, so erwerben wir das Miteigentum ab der neuen Sache oder an dem vermischten Bestand im Verhältnis des Wertes unserer Vorbehaltsware zu der anderen Ware zu der Zeit der Verbindung, Vermischung oder Verarbeitung. Auf die durch Verbindung, Vermischung oder Verarbeitung herbeigeführte Wertsteigerung erheben wir keinen Anspruch.
        </li>
        <li>
          <ol>
            <li>
              Der Käufer tritt seine Forderung mit allen Nebenrechten aus dem Weiterverkauf unserer Vorbehaltsware sowie gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehenden Ware zur Sicherheit für alle uns im Zeitpunkt der Weiterveräußerung gegen den Käufer zustehenden Ansprüche bereits jetzt an uns ab.
            </li>
            <li>
              Im Falle der Weiterveräußerung der Ware, die gemäß Ziffer 6.3 in unserem Miteigentum steht, gilt als abgetreten jedoch nur der Teil der Forderung, der dem Wert unseres Miteigentumsanteils entspricht.
            </li>
            <li>
              Hat der Käufer die Forderung aus dem Weiterverkauf im Rahmen des echten Factoring verkauft, so tritt er die an ihre Stelle tretende Forderung gegen den Factor an uns ab.
            </li>
            <li>
              Die vorstehenden Abtretungen nehmen wir hiermit ausdrücklich an.
            </li>
            <li>
              Übersteigt der Wert der uns zur Sicherheit abgetretenen Forderungen unsere Ansprüche gegen den Käufer um mehr als 20%, so sind wir auf Verlangen des Käufers verpflichtet, darüber hinaus bestehende Sicherheiten freizugeben.
            </li>
          </ol>
        </li>
        <li>
          Der Käufer ist zur Weiterveräußerung unserer Vorbehaltsware sowie der gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehende Waren nur im Rahmen seines gewöhnlichen Geschäftsverkehrs und nur unter der Voraussetzung berechtigt, dass die Kaufpreisforderung aus dem Weiterverkauf gemäß Ziffer 6.4 auf uns übergeht.
        </li>
        <li>
          Der Käufer ist verpflichtet, unsere Vorbehaltsware sowie die gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehende Ware gegen Verlust und Beschädigung aufgrund Feuer, Diebstahl, Wasser und ähnlicher Gefahren ausreichend zu versichern und uns auf Verlangen den Versicherungsschutz nachzuweisen. Der Käufer tritt hiermit seine Entschädigungsansprüche, die ihm gegen Versicherungsgesellschaften oder sonstige Ersatzverpflichtete zustehen – gegebenenfalls anteilig – an uns ab. Auch die vorstehende Abtretung wird hiermit angenommen.
        </li>
        <li>
          Irgendeine Beeinträchtigung unserer Vorbehaltsware sowie der gemäß Ziffer 6.2 in unserem Eigentum bzw. gemäß Ziffer 6.3 in unserem Miteigentum stehende Ware ist uns ebenso unverzüglich bekanntzugeben, wie Zugriffe dritter darauf.
        </li>
        <li>
          Nehmen wir aufgrund des Eigentumsvorbehalts den Kaufgegenstand zurück, so gilt das nicht als Rücktritt vom Vertrag. Wir können uns aus der zurückgenommenen Vorbehaltsware durch freihändigen Verkauf befriedigen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Zahlung</strong>
      <ol>
        <li>
          Die Forderungen aus unseren Rechnungen sind nach unserer Wahl, sofern nichts anderes vereinbart ist, per Vorauskasse, per Bargeldnachnahme oder per SEPA–Firmenlastschrift zahlbar. Unbeschadet einer einzelvertraglichen Ware sofort fällig bei Abholung bzw. Lieferung.
        </li>
        <li>
          Wir sind berechtigt, trotz anderslautender Zahlungsbestimmung des Käufers, Zahlungen zunächst auf dessen älteste Schuld anzurechnen. Sind bereits Kosten und Zinsen entstanden, so sind wir berechtigt, die Zahlung zunächst auf die Kosten, dann auf die Zinsen und zuletzt auf die Hauptforderung anzurechnen.
        </li>
        <li> 
          Der Käufer kommt auch ohne Mahnung in Verzug, wenn er nach Ablauf von 30 Tagen nach Zugang der Rechnung nicht gezahlt hat. Mit Zugang einer Mahnung tritt Verzug gegebenenfalls auch vor Ablauf von 30 Tagen nach Zugang der Rechnung ein. Verzugszinsen werden in Höhe von 8 Prozentpunkten über dem jeweiligen Basiszinssatz berechnet. Die Geltendmachung eines höheren Zinsschadens bleibt vorbehalten.
        </li>
        <li>
          Kommt der Käufer seinen Zahlungsverpflichtungen nicht vertragsgemäß nach, oder stellt er seine Zahlungen ein oder werden uns andere Umstände bekannt, die die Kreditwürdigkeit des Käufers in Frage stellen, so sind wir berechtigt, die gesamte Restschuld fällig zu stellen. § 321 BGB findet mit der Maßgabe Anwendung, dass uns die dort vorgesehene Einrede auch dann zusteht, wenn die Vermögenslage des Käufers bereits bei Vertragsschluß schlecht war, dies uns jedoch nicht bekannt gewesen ist.
        </li>
        <li>
          Der Käufer kann Zurückbehaltungsrechte gegenüber unseren Forderungen nicht geltend machen. Ferner ist die Aufrechnung gegenüber unseren Forderungen ausgeschlossen, es sei denn, die Gegenforderung ist unbestritten oder rechtskräftig festgestellt. 
        </li>
      </ol>
    </li>
    <li>
      <strong>Gewährleistung</strong>
      <ol>
        <li>
          Angaben zu unseren Waren sind generell reine Beschaffenheitsangaben, es sei denn, sie werden ausdrücklich als zugesicherte Eigenschaften bezeichnet.
        </li>
        <li>
          Übernommene oder gelieferte Waren sind unverzüglich vom Käufer auf vorhandene Mängel zu überprüfen. Sind solche vorhanden, sind sie unverzüglich schriftlich uns gegenüber anzuzeigen. Im Übrigen gelten die § 377 ff. HGB. Daneben sind Gewährleistungsansprüche generell ausgeschlossen, wenn infolge von Weiterversand oder Be– bzw. Verarbeitung der von uns gelieferten Ware oder anderer Umstände unsererseits nicht mehr einwandfrei geprüft und festgestellt werden kann, ob ein Mangel der Ware tatsächlich vorliegt.
        </li>
        <li>
          Gewährleistungsansprüche verjähren binnen 12 Monaten, beginnend mit Übergabe der Sache.
        </li>
        <li>
          Die Gewährleistung beim Verkauf gebrauchter Gegenstände ist generell ausgeschlossen.
        </li>
        <li>
          Nimmt uns der Käufer auf Gewährleistung in Anspruch, und stellt sich heraus, dass ein Gewährleistungsanspruch nicht besteht (zum Beispiel Anwenderfehler, unsachgemäße Behandlung des Kaufgegenstandes, Nichtbestehen eines Mangels), so hat uns der Käufer alle im Zusammenhang mit der Überprüfung des Kaufgegenstandes entstehenden Kosten zu ersetzen.
        </li>
        <li>
          Soweit der Hersteller für die gelieferte Ware eine freiwillige Garantie gegenüber dem Käufer gewährt, richten sich Art und Umfang der Garantieleistungen ausschließlich nach dem Inhalt der Herstellergarantie. Aus dieser Garantie kann – soweit diese Garantie über die gesetzlichen Gewährleistungsrechte hinaus geht – ausschließlich der Hersteller in Anspruch genommen werden.
        </li>
      </ol>
    </li>
    <li>
      <strong>Haftung</strong>
      <ol>
        <li>
          <ol>
            <li>
              In allen Fällen, in denen wir im Geschäftsverkehr aufgrund vertraglicher oder gesetzlicher Anspruchsgrundlagen zum Schadens– oder Aufwendungsersatz verpflichtet sind, haften wir nur, soweit uns, unseren Organen, gesetzlichen Vertretern oder Erfüllungsgehilfen Vorsatz oder grobe Fahrlässigkeit zur Last fällt. 
            </li>
            <li>
              Unberührt hiervon bleibt die Haftung für die schuldhafte Verletzung wesentlicher Vertragspflichten. Wesentliche Vertragspflichten sind solche, die den Vertragsparteien die Rechte zubilligen, die der Vertrag nach seinem Inhalt und Zweck gerade zu  gewähren  hat,  insbesondere  die  Pflichten,  deren  Erfüllung  die  ordnungsgemäße  Durchführung  des Vertrags überhaupt erst ermöglichen und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und vertrauen darf. 
            </li>
            <li>
              Soweit eine zurechenbare Pflichtverletzung auf einfacher Fahrlässigkeit beruht und eine wesentliche Vertragspflicht schuldhaft verletzt ist, ist unsere  Schadensersatzhaftung jedoch auf den vorhersehbaren Schaden beschränkt, der typischerweise in vergleichbaren Fällen eintritt. 
            </li>
            <li>
              Die vorstehenden Haftungsausschlüsse gelten nicht für   den   Fall   der   Tötung,   der   Verletzung   der   Gesundheit   oder   des   Körpers,   für garantierte Beschaffenheitsmerkmale und   im   Fall   einer etwaigen   Haftung   nach   dem   Produkthaftungsgesetz. In diesen Fällen haften wir nach den gesetzlichen Bestimmungen. 
            </li>
          </ol>
        </li>
        <li>
          Im Übrigen ist jegliche Haftung unsererseits ausgeschlossen.
        </li>
        <li>
          Die vorstehenden Haftungsausschlüsse und –beschränkungen gelten in gleichem Umfang zugunsten unserer Organe, gesetzlichen Vertreter, Angestellten und sonstigen Erfüllungsgehilfen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Schutz– oder Urheberrechte</strong>
      <ol>
        <li>
          Der Käufer wird uns unverzüglich und schriftlich unterrichten, falls er auf die Verletzung von Schutz– oder Urheberrechten durch ein von uns geliefertes Produkt hingewiesen wird. Wir sind allein berechtigt und verpflichtet, den Käufer gegen die Ansprüche des Inhabers derartiger Rechte zu verteidigen und diese Ansprüche auf eigene Kosten zu regeln, soweit sie auf die unmittelbare Verletzung durch ein von uns geliefertes Produkt gestützt sind. Grundsätzlich werden wir uns bemühen, dem Käufer das Recht zur Benutzung zu verschaffen. Falls uns dies zu wirtschaftlich angemessenen Bedingungen nicht möglich ist, werden wir nach eigener Wahl dieses Produkt derart abändern oder ersetzen, dass das Schutzrecht nicht verletzt wird oder das Produkt zurücknehmen und den Kaufpreis abzüglich eines etwaigen Betrages für die gewährte Nutzungsmöglichkeit erstatten.
        </li>
        <li>
          Umgekehrt wird der Käufer uns gegenüber allen Ansprüchen des Inhabers derartiger Rechte verteidigen bzw. freistellen, welche gegen uns dadurch entstehen, dass wir Instruktionen des Käufers befolgt haben oder der Käufer das Produkt ändert oder in das System integriert.
        </li>
        <li>
          Von uns zur Verfügung gestellte Programme und dazugehörige Dokumentationen sind nur für den eigenen Gebrauch des Käufers im Rahmen einer einfachen, nicht übertragbaren Lizenz bestimmt, und zwar ausschließlich auf von uns gelieferten Produkten. Der Käufer darf diese Programme und Dokumentationen ohne unsere schriftliche Einwilligung Dritten nicht zugänglich machen, auch nicht bei Weiterveräußerung unserer Hardware. Kopien dürfen – ohne Übernahme von Kosten und Haftung durch uns – lediglich für Archivzwecke, als Ersatz oder zur Fehlersuche angefertigt werden. Sofern Originale einen auf Urheberschutz hinweisenden Vermerk tragen, ist dieser vom Kunden auch auf Kopien anzubringen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Export</strong>
      <ol>
        <li>
          Der Export unserer Waren in Nicht–EU–Länder bedarf unserer schriftlichen Einwilligung, unabhängig davon, dass der Käufer für das Einholen jeglicher behördlicher Ein– und Ausfuhrgenehmigungen selbst zu sorgen hat.
        </li>
      </ol>
    </li>
  </ol>
  `,
  privacyProtection: `
  <h3>Datenschutzhinweise</h3>
  <h4>I. Präambel</h4>
  <p>
    Eine vertrauensvolle Zusammenarbeit beginnt für uns bereits im Kleinen. Das beinhaltet für uns
    selbstverständlich auch den Schutz Ihrer Daten von Anfang an. Mit dieser Datenschutzerklärung möchten wir
    Sie daher über Art, Umfang und Zweck der Verarbeitung Ihrer personenbezogenen Daten aufklären, die durch
    den Besuch unserer Website entstehen und von uns zum Teil genutzt werden, um unser Angebot für unsere
    Kunden so angenehm wie möglich zu gestalten.<br />
    Wir orientieren uns dabei an den Vorgaben der Datenschutzgrundverordnung der Europäischen Union (im
    Folgenden: DSGVO), dem deutschen Bundesdatenschutzgesetz (im Folgenden: BDSG), sowie dem deutschen
    Telemediengesetz (im Folgenden: TMG).
  </p>
  <h4>II. Allgemeines</h4>
  <h5>a. Verantwortlicher</h5>
  <p>
    Verantwortlicher im Sinne der DSGVO ist:<br /><br />
    ComLine GmbH Value Added Distribution<br />
    Geschäftsführer: Harald Rapp, Richard Gäbel, Anja Sobottka<br />
    Lise-Meitner-Straße 16<br />
    24941 Flensburg<br />
    Tel.: +49 461 77 303 300<br />
    Fax: +49 461 77 303 390<br />
    E-Mail: info@comline-shop.de
  </p>
  <h5>b. Datenschutzbeauftragter</h5>
  <p>
    Bei Fragen zum Datenschutz werden wir durch die Datros GmbH beraten. Sie erreichen unseren externen
    Datenschutzbeauftragten in der Lise-Meitner-Straße 15, 24941 Flensburg, beziehungsweise unter der
    E-Mail-Adresse info@datros.de.
  </p>
  <h5>c. Begrifflichkeiten</h5>
  <p>
    Die in dieser Datenschutzerklärung verwendeten Begrifflichkeiten entsprechen denen der DSGVO bzw. des
    BDSG. Die wesentlichen sind:
  </p>
  <ul>
    <li>
      „personenbezogene Daten“: alle Informationen, die sich auf eine identifizierte oder identifizierbare
      natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
      Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem
      Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren
      besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
      wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert
      werden kann (Art. 4 Nr. 1 DSGVO);
    </li>
    <li>
      „Verarbeitung“: jeden mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede
      solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die
      Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen,
      die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
      Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung
      (Art. 4 Nr. 2 DSGVO);
    </li>
    <li>
      „Dritter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, außer der
      betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der
      unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die
      personenbezogenen Daten zu verarbeiten (Art. 4 Nr. 10 DSGVO);
    </li>
    <li>
      „Einwilligung“: der betroffenen Person jede freiwillig für den bestimmten Fall, in informierter Weise
      und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen
      eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der
      Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist (Art. 4 Nr. 11 DSGVO).
    </li>
  </ul>
  <h5>d. Datenarten</h5>
  <p>Auf unserer Website verarbeiten wir insb. die folgenden Datenarten:</p>
  <ul>
    <li>
      Bestandsdaten (Daten, die für die Begründung, inhaltliche Ausgestaltung, Änderung oder Beendigung eines
      Vertragsverhältnisses notwendig sind; bspw. Name und Adresse)
    </li>
    <li>
      Kontaktdaten (Daten, unter denen man Sie oder Ihr Unternehmen erreichen kann; bspw. E-Mail-Adresse oder
      Telefonnummer)
    </li>
    <li>Inhaltsdaten (Inhalte von übertragenen Nachrichten; bspw. Texte, die Sie eingeben, Bild, Videos)</li>
    <li>Nutzungsdaten (technische Informationen über bspw. Zugriffszeiten und besuchte Websites)</li>
    <li>
      Kommunikationsdaten (Daten, die uns Informationen über den Kommunikationsweg geben, bspw. der von Ihnen
      verwendete Browser, Geräte-Informationen aber auch IP-Adressen)
    </li>
  </ul>
  <p>Im Rahmen von geschäftsbezogenen Verarbeitungen fallen zusätzlich folgende Datenarten an:</p>
  <ul>
    <li>Vertragsdaten (den Vertragstext und -gegenstand, Laufzeit, etc.)</li>
    <li>Zahlungsdaten (bspw. Bankverbindungen und Buchungshistorie)</li>
  </ul>
  <h5>e. Betroffene Personengruppen &amp; allg. Zweck der Verarbeitung</h5>
  <p>
    Personenbezogene Daten werden von den Nutzern dieser Website erhoben. Die von Ihnen erhobenen Daten werden
    zur Verfügungstellung dieser Website und zur Beantwortung von Kontaktanfragen verarbeitet. Darüber hinaus
    erfassen wir einige Daten zum Zwecke der Reichweitenmessung und um unser Angebot noch attraktiver für
    unsere Kunden zu gestalten. Welche Daten konkret erfasst werden, auf welche Rechtsgrundlage dies geschieht
    und wie wir diese verarbeiten entnehmen Sie bitte den speziellen Erläuterungen im Verlauf dieser
    Datenschutzerklärung.
  </p>
  <h5>f. Widerrufsrecht</h5>
  <p>
    Sie haben natürlich das Recht jederzeit ohne Angaben von Gründen Ihre erteilte Einwilligung gem. Art. 7
    Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen. Der Widerruf ist nicht an eine bestimmte Form
    gebunden und wird unabhängig von dem benutzen Medium mit Zugang wirksam und von uns beachtet. Zu Ihrer und
    unserer Arbeitserleichterung bitten wir den Widerruf jedoch an folgende E-Mail-Adresse zu senden:
    info@comline-shop.de.
  </p>
  <h5>g. Cookies</h5>
  <p>
    Zudem verwenden wir auf unserer Website sogenannte „Cookies“. Wir weisen darauf zu Beginn der Website in
    einer entsprechenden Einblendung hin. Dies sind kleine Textdateien, welche lokal auf Ihrem Endgerät
    gespeichert werden und grundsätzlich auch Daten über den Besuch auf der jeweiligen Website hinaus bei
    Ihnen hinterlegen können. „Cookies“ dienen im Allgemeinen dazu, einen Nutzer auf einer Website wieder zu
    erkennen und Ihm so beispielsweise die erneute Eingabe von Informationen oder Einstellungen zu ersparen.
    Sie können jedoch auch als sogenannter „Sessions-Cookie“ lediglich für die Dauer des Besuchs auf einer
    Website bei Ihnen hinterlegt werden und beispielsweise zur korrekten Funktionalität des Warenkorbs eines
    Webshops dienen. Ein solcher „Session-Cookie“ wird mit dem Beenden Ihres Browsers automatische entfernt.
    Welche Cookies verwendet werden, welche Daten hinterlegt werden und zu welchem Zweck diese verwendet
    werden, entnehmen Sie bitte den nachfolgenden Ausführungen.<br />
    Sollten Sie generell auf die Nutzung von Cookies verzichten wollen, so bieten die gängigen Browser auch
    diese Option an. Wir weisen jedoch darauf hin, dass dies unter Umständen die Funktionalität dieser und
    anderer Websites beeinträchtigen kann.<br />
    Daneben haben Sie auch die Möglichkeit, dem Setzen eines Cookies von einigen größeren Anbietern zu Zwecken
    des Online-Marketings und des Trackings präventiv zu widersprechen. Diese Möglichkeit bieten zu einem die
    europäische Website
    <a href="http://www.youronlinechoices.com" target="_blank" rel="noopener noreferrer"
      >http://www.youronlinechoices.com</a
    >
    oder die US-amerikanische Website
    <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer"
      >http://www.aboutads.info/choices/</a
    >.
  </p>
  <h5>h. Genereller (Werbe-) Widerspruch</h5>
  <p>
    Wir weisen darauf hin, dass Sie selbstverständlich auch losgelöst vom Besuch unserer Website stets das
    Recht haben, der Verarbeitung Ihrer personenbezogenen Daten nach Maßgabe des Art. 21 DSGVO zu
    widersprechen.<br />
    Sie haben zudem das Recht des Widerspruchs gegen die Verarbeitung Ihrer personenbezogenen Daten zum Zwecke
    der Direktwerbung gem. Art. 21 Abs. 2 DSGVO. Auch der Widerspruch ist nicht an eine bestimmte Form
    gebunden und wird unabhängig von dem benutzen Medium mit Zugang wirksam und von uns beachtet. Zu Ihrer und
    unserer Arbeitserleichterung bitten wir den Widerruf jedoch an folgende E-Mail-Adresse zu senden:
    info@comline-shop.de.
  </p>
  <h5>i. Generelle Lösch- und Sperrvorgabe</h5>
  <p>
    Ihre personenbezogenen Daten löschen bzw. sperren wir gem. Art. 17 und 18 DSGVO. Die Daten werden von uns
    insbesondere gelöscht, sobald der Zweck, für den wir die Daten ursprünglich erhoben haben, weggefallen ist
    und keine gesetzlichen Aufbewahrungspflichten bestehen. Beispielsweise sind wir gesetzlich gem. § 257 Abs.
    1 HGB verpflichtet Handelsbücher, Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Einzelabschlüsse nach §
    325 Abs. 2a, Lageberichte, Konzernabschlüsse, Konzernlageberichte sowie die zu ihrem Verständnis
    erforderlichen Arbeitsanweisungen und sonstigen Organisationsunterlagen, Handelsbriefen, sowie
    Buchungsbelege für einen Zeitraum von 6 Jahren aufzubewahren. Im Falle von gesetzlichen
    Aufbewahrungspflichten sperren wir die Daten, sodass wir diese ausschließlich zu dem gesetzlich
    vorgeschriebenen Zweck verarbeiten werden.
  </p>
  <h5>j. Datensicherheit</h5>
  <p>
    Um die Sicherheit Ihrer Daten zu gewährleisten, verwenden wir zur Übertragung der Website-Inhalte als auch
    der von Ihnen eingegebenen Daten das verbreitete Secure-Socket-Layer-Verfahren (SSL-Verfahren). Die
    zwischen Ihnen und unserem Hostinganbieter übertragenen Daten werden mit einer 256-bit Verschlüsselung
    codiert. Falls Ihr Browser keine 256-bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-bit
    v3 Technologie zurück.
  </p>
  <h4>III. Ihre Rechte</h4>
  <p>
    Sinn und Zweck unserer Bemühungen um den Schutz Ihrer Daten ist stets Ihre vollständige Herrschaft über
    die von Ihnen gelieferten Angaben sicherzustellen. Sie haben daher, neben den bereits oben dargestellten
    Rechten, auch darüber hinausgehende Rechte in Bezug auf die bei uns hinterlegten personenbezogenen Daten,
    die wir im Folgenden gern einzeln beschreiben.
  </p>
  <h5>a. Recht auf Bestätigung der Verarbeitung</h5>
  <p>
    Sie haben gem. Art 15 DSGVO das Recht, von uns eine Bestätigung über die Verarbeitung Ihrer
    personenbezogenen Daten zu bekommen.
  </p>
  <h5>b. Recht auf Auskunft</h5>
  <p>
    Solten wir personenbezogene Daten von Ihnen verarbeiten, haben Sie gem. Art. 15 DSGVO zudem das Recht zu
    erfahren, welche Daten uns vorliegen. Wir werden Ihnen gem. den Vorgaben des Art. 15 DSGVO die
    Informationen zur Verfügung stellen.
  </p>
  <h5>c. Recht auf Vervollständigung &amp; Berichtigung</h5>
  <p>
    Sie haben gem. Art. 16 DSGVO das Recht, von uns unverzüglich die Korrektur der Sie betreffenden
    unrichtigen personenbezogenen Daten zu verlangen. Darüber hinaus haben Sie das Recht, die bei uns
    hinterlegten unvollständigen Daten zu vervollständigen.
  </p>
  <h5>d. Recht auf Löschung</h5>
  <p>
    Sie haben gem. Art. 17 DSGVO das Recht, von uns unverzüglich die Löschung Ihrer personenbezogenen Daten zu
    verlangen, sofern einer der folgenden Punkte zutrifft und keine Ausnahmeregelung gem. Art. 17 Abs. 2,3
    DSGVO gegeben ist:
  </p>
  <ul>
    <li>
      Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet
      wurden, nicht mehr notwendig.
    </li>
    <li>
      Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Artikel 6 Absatz
      1 Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a stützte, und es fehlt an einer anderweitigen
      Rechtsgrundlage für die Verarbeitung.
    </li>
    <li>
      Die betroffene Person legt gemäß Artikel 21 Absatz 1 Widerspruch gegen die Verarbeitung ein und es
      liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt
      gemäß Artikel 21 Absatz 2 Widerspruch gegen die Verarbeitung ein.
    </li>
    <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
    <li>
      Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem
      Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
    </li>
    <li>
      Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß
      Artikel 8 Absatz 1 erhoben.
    </li>
  </ul>
  <h5>e. Recht auf Einschränkung der Verarbeitung</h5>
  <p>
    Sie haben gem. Art. 18 DSGVO das Recht, von uns unverzüglich die Einschränkung der Verarbeitung Ihrer
    personenbezogenen Daten zu verlangen, sofern eine der folgenden Voraussetzungen gegeben ist:
  </p>
  <ul>
    <li>
      die Richtigkeit der personenbezogenen Daten von der betroffenen Person bestritten wird, und zwar für
      eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu
      überprüfen,
    </li>
    <li>
      die Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung der personenbezogenen Daten
      ablehnt und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt;
    </li>
    <li>
      der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt,
      die betroffene Person sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
      benötigt, oder die betroffene Person Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1
      eingelegt hat, solange noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber
      denen der betroffenen Person überwiegen.
    </li>
  </ul>
  <p>
    Wurde die Verarbeitung gemäß Absatz 1 eingeschränkt, so dürfen diese personenbezogenen Daten — von ihrer
    Speicherung abgesehen — nur mit Ihrer Einwilligung der betroffenen Person oder zur Geltendmachung,
    Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
    juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines
    Mitgliedstaats verarbeitet werden. Bevor eine Sperrung aufgehoben wird, werden wir Sie entsprechend
    unterrichten.
  </p>
  <h5>f. Recht auf Datenübertragbarkeit</h5>
  <p>
    Sie haben gem. Art. 20 DSGVO das Recht, die Sie betreffenden personenbezogenen Daten von uns in einem
    strukturierten, gängigen und maschinenlesbaren Format bereitgestellt zu bekommen und zu verlangen, dass
    diese einem Dritten ohne Behinderung bereitgestellt werden. Einschränkungen dieses Rechts können sich aus
    Art. 20 DSGVO ergeben.
  </p>
  <h5>g. Beschwerderecht</h5>
  <p>
    Sie haben gem. Art. 77 DSGVO das Recht, Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen. Die
    zuständige Behörde erreichen Sie unter folgender Adresse:<br />
    ULD – Unabhängiges Landeszentrum für Datenschutz Schleswig-Holstein; Holstenstraße 98, 24103 Kiel; Tel.:
    0431-9881200: Fax.: 0431-9881223; E-Mail: mail@datenschutzzentrum.de.
  </p>
  <h4>IV. Verarbeitung zum Zwecke der technischen Funktionalität</h4>
  <h5>a. Hosting der Website</h5>
  <p>
    Um Ihnen diese Website zur Verfügung stellen zu können, arbeiten wir mit einem Hostinganbieter zusammen.
    Dieser liefert die Infrastruktur für den störungsfreien Betrieb dieser Website und stellt mit regelmäßigen
    Wartungen sicher, dass Ihre Daten nicht verloren gehen. Um den Schutz Ihrer Daten und die Einhaltung von
    Datenschutzstandards zu gewährleisten, haben wir auch unseren Hostinganbieter im Rahmen eines
    Auftragsverarbeitungsvertrags gem. Art. 28 DSGVO zur Wahrung unserer hohen Datenschutzstandards
    verpflichtet. Dieser verarbeitet auf der Grundlage unseres berechtigten Interesses gem. Art. 6 Abs. 1 lit.
    f DSGVO an der Aufrechterhaltung und des Betriebs, sowie der Sicherheit dieser Website in unserem Auftrag
    Bestands-, Kontakt-, Inhalts-, Vertrags-, Nutzungs- und Kommunikationsdaten der Nutzer dieser Website.<br />
    Daneben entstehen bei jedem Besuch unserer Website Nutzungsdaten. Diese beinhalten insb. den Namen der
    aufgerufenen Website, heruntergeladene Dateien, Datum und Uhrzeit des Abrufs, jeweils übertragene
    Datenmengen, Meldungen über den erfolgreichen Aufruf der Website, verwendeter Browsertyp &amp;
    Betriebssystem, die zuvor besuchte Seite, die IP-Adresse und den benutzten Provider. Diese verarbeiten wir
    bzw. unser Hostinganbieter in unserem Auftrag und auf der Grundlage unseres berechtigten Interesses gem.
    Art. 6 Abs. 1 lit. f DSGVO an dem Schutz vor Missbrauch und aus Sicherheitsgründen. Die personenbezogenen
    Daten werden, mit Ausnahme von solchen, deren weitere Speicherung zu Beweissicherungszwecken erforderlich
    sind, für die Dauer von 7 Tagen gespeichert und sodann automatisch gelöscht.
  </p>
  <h5>b. Webshop-Cookie</h5>
  <p>
    Um Ihnen die Anmeldung in unserem Webshop, sowie einen funktionierenden Warenkorb zu ermöglichen, setzen
    wir auf Ihrem Endgerät ein Cookie ein, dass Ihnen eine eindeutige Kennung zuweist. Die so entstehenden
    personenbezogenen Daten setzen wir Grundlage unseres berechtigten Interesses gem. Art. 6 Abs. 1 lit f
    DSGVO an einem funktionierenden Webshop-System ein. Bei dem Cookie handelt es sich um ein sogenanntes
    Session-Cookie, dass nach Verlassen unserer Website und Beendigung Ihres Browsers automatisch seine
    Gültigkeit verliert und in der Regel von Ihrem Browser automatisch entfernt wird.
  </p>
  <h5>c. Webshop-Abwicklung</h5>
  <p>
    Die Anmeldung in unserem Webshop-System können Sie entweder als Gast oder mit einem Nutzerprofilkonto
    durchführen. Die von Ihnen im Rahmen der Eingabemaske abgefragten Daten werden zum Zwecke der Abwicklung
    der Bestellung von Artikeln aus unserem Webshop verarbeitet auf Grundlage von Art. 6 Abs. 1 lit b DSGVO
    verarbeitet. Ihre Adressdaten übergeben wir darüber hinaus zum Zwecke der Zusendung an unseren
    Versanddienstleister.
  </p>
  <h5>d. Kontaktaufnahme</h5>
  <p>
    Wenn Sie mit uns in Kontakt treten möchten, so steht Ihnen hierfür unser Kontaktformular zur Verfügung.
    Die dort erhobenen Daten verarbeiten wir zum Zwecke der Bearbeitung Ihrer Kontaktanfrage gem. Art. 6 Abs.
    1 lit. b DSGVO. Sollten wir darüber hinaus in geschäftlichem Kontakt bleiben, können Ihre
    personenbezogenen Daten auch im Rahmen des Customer Relationship Managments in einem entsprechenden System
    verarbeitet werden. Sofern die Daten nach Beantwortung Ihrer Anfrage nicht mehr erforderlich sind, werden
    wir diese löschen, sofern keine gesetzliche Archivierungspflichten bestehen.
  </p>
  <h5>e. Newsletter</h5>
  <p>
    Sollten Sie auch in Zukunft wünschen, über aktuelle Produkte und Informationen auf dem Laufenden zu
    bleiben, so bieten wir Ihnen die Möglichkeit zur Anmeldung zu unserem E-Mail-Newsletter.<br />
    Um sicherzustellen, dass sich kein Dritter mit Ihrer E-Mail-Adresse anmeldet, ist das Anmeldeverfahren als
    sogenanntes „Double-Opt-In“-Verfahren ausgestaltet. Nachdem Sie Ihre E-Mail-Adresse in das dafür
    vorgesehene Feld eingegeben haben und den entsprechenden Button zur Anmeldung betätigt haben, erhalten Sie
    von uns eine E-Mail, die einen Link zur Bestätigung Ihrer Anmeldung erhält. Mit der Bestätigung des Links
    erteilen Sie zugleich Ihre Einwilligung zur weiteren Nutzung Ihrer für den Versand notwendigen
    personenbezogenen Daten. Zum Zwecke der Nachweisbarkeit protokollieren wir diese Anmeldung und verarbeiten
    Anmelde- und Bestätigungszeitpunkt, IP-Adresse und die angegebene E-Mail-Adresse auf Grundlage unseres
    berechtigten Interesses an der Nachweisbarkeit einer Anmeldung gem. Art. 6 Abs. 1 lit. f DSGVO. Die von
    Ihnen angegebene E-Mail Adresse, sowie Ihren Namen verwenden wir zum Versand der Newsletter und der
    persönlichen Anrede Wir verarbeiten diese auf Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a, Art.
    7 DSGVO in Verbindung mit § 7 Abs. 2 Nr. 3 UWG, sowie auf Grundlage der gesetzlichen Erlaubnis gem. § 7
    Abs. 3 UWG.<br />
    Sollten Sie die Zusendung des Newsletters in Zukunft nicht mehr wünschen, können die Abonnement jederzeit
    kündigen. Einen entsprechenden Link zur Abmeldung finden Sie jeweils am Ende eines jeden Newsletters.
    Natürlich können Sie uns auch einfach eine E-Mail an folgende Adresse zukommen lassen:
    comline@comline-shop.de<br />
    Nach der Kündigung können wir Ihren Namen und Ihre E-Mail-Adresse auf Grundlage unseres berechtigten
    Interesses an der Nachweisbarkeit der ehemals gegeben Einwilligung gem. Art. 6 Abs. 1 lit. f DSGVO bis zu
    3 Jahre speichern. Die Daten werden ausschließlich zu diesem Zweck vorgehalten und sind zur Nutzung für
    andere Zwecke gesperrt.
  </p>
  <h4>V. Verwendung von Diensten Dritter – technische Funktionalität und Information</h4>
  <h5>a. Google Analytics</h5>
  <p>
    Wir setzen auf unserer Website den Dienst „Google Analytics“ von Google auf der Grundlage unseres
    berechtigten Interesses gem. Art. 6 Abs. 1 lit f DSGVO ein. Dies ermöglicht uns zu erfahren, was für die
    Besucher unserer Website besonders relevant ist, einen Überblick über unsere Besucherzahlen zu erhalten
    und unser Angebot noch attraktiver zu gestalten. Dieser wird von der Google Inc. (1600 Amphitheatre
    Parkway Mountain View, CA 94043 USA; im Folgenden: Google) betrieben. Auch dieser Dienst funktioniert
    nicht ohne den Einsatz von Cookies. Verarbeitet wird die regionale Herkunft des Besuchers, dessen
    Betriebssystem und die Art des Endgeräts. Des Weiteren speichert das Cookie Hinweise darauf, wie Sie zu
    uns gefunden haben, die sog. verweisenden Websites, sowie Zielseiten, Klickpfade, Absprungraten und die
    Verweildauer auf einzelnen Unterseiten.<br />
    Die durch das Cookie erzeugten Informationen werden an einen Server von Google in den USA übertragen und
    dort verarbeitet. Unsere Website ist so konfiguriert, dass Ihre personenbezogenen Daten vor der
    Übertragung in einen Bereich außerhalb des Regelungsbereichs der Europäischen Union oder in anderen
    Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt und damit anonymisiert
    werden (IP-Masking). Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
    nicht mit anderen Daten von Google zusammengeführt.<br />
    Google hat zudem das sogenannte Privacy-Shield-Abkommen ratifiziert und sich zur Einhaltung des
    europäischen Datenschutzrechts verpflichtet. Ein entsprechender Nachweis des Status ist unter
    <a
      href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
      target="_blank"
      rel="noopener noreferrer"
      >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a
    >
    verfügbar. Wir haben zudem mit Google einen Auftragsverarbeitungsvertrag geschlossen, der der Vorlage
    unter folgendem URL entspricht:
    <a href="http://www.google.com/analytics/terms/de.pdf" target="_blank" rel="noopener noreferrer"
      >http://www.google.com/analytics/terms/de.pdf</a
    >.<br />
    Sie können die Erfassung der Daten verhindern, indem Sie das unter dem folgenden Link
    <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer"
      >http://tools.google.com/dlpage/gaoptout?hl=de</a
    >
    verfügbare Browser-Plugin herunterladen und installieren. In diesem Fall wird ein Browser-Cookie gesetzt,
    dass die Erfassung auf dieser und anderen Websites durch Google verhindert.<br />
    Weitere Informationen zu Nutzungsbedingungen und Datenschutz von Google finden Sie unter
    <a href="http://www.google.com/analytics/terms/de.html" target="_blank" rel="noopener noreferrer"
      >http://www.google.com/analytics/terms/de.html</a
    >
    bzw.
    <a href="https://www.google.de/intl/de/policies/" target="_blank" rel="noopener noreferrer"
      >https://www.google.de/intl/de/policies/</a
    >.
  </p>
  <a href="javascript:gaOptOut();" onclick="alert('Google Analytics wurde deaktiviert');"
    >Erfassung von Daten durch Google Analytics für diese Website deaktivieren</a
  ><br />
  <h4>VII. Stand und Änderung dieser Datenschutzerklärung</h4>
  <p>
    Diese Datenschutzerklärung hat den Stand Mai 2018 und ist die derzeit aktuelle Version. Aufgrund der
    Weiterentwicklung unserer Website und Angebote, sowie durch rechtliche Vorgaben kann es jedoch jederzeit
    notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann
    jederzeit unter
    <a href="https://www.comline-shop.de/datenschutz/" target="_blank" rel="noopener noreferrer"
      >https://www.comline-shop.de/datenschutz/</a
    >
    abgerufen werden.
  </p>
  `,
  faqQuestions: [
    {
      question: "Können alle Lizenzen direkt transferiert werden oder nur sobald sie fällig sind?",
      answer: `Eine Migration kann jederzeit durchgeführt werden.<br />
      Befindet sich der Kunde bereits im Verlängerungsfenster und hat die Lizenzen noch nicht oder nur teilweise verlängert, ist eine Migration nicht möglich. In diesem Fall muss erst die vollständige Verlängerung auf dem klassischen Wege durchgeführt werden.<br />
      Bestehen für den Kunden noch offene Kaufautorisierungen müssen diese ebenfalls erst auf dem klassischen Wege bestellt werden.<br />
      Innerhalb von 14 Tagen nach einer Bestellung (ob Verlängerung oder AddOn) kann kein Transfer durchgeführt werden.`,
    },
    {
      question:
        "Kann ich Kunden mit einem 3YC oder Enterprise Lizenzen, bzw. Government oder Education Kunden in den Adobe Cloud Store transferieren?",
      answer: `Nein, der Adobe Cloud Store gibt in diesem Fall einen Fehler aus, dass der Transfer des angegebenen VIP Accounts nicht möglich ist.<br />
      Diese Lizenzmodelle und -segmente werden in den kommenden Monaten von Adobe implementiert.`,
    },
    {
      question: "Sieht der Kunde in seiner Adminkonsole die Customer ID?",
      answer:
        "Nein, der Kunde behält im Falle eines Transfers seine bekannte VIP-Nummer. Bei Neukunden wir eine neue VIP-Nummer generiert.",
    },
    {
      question: "Gibt es die Möglichkeit Lizenzen monats- bzw. tageweise zu buchen?",
      answer:
        "Nein, noch steht diese Funktion nicht bereit. Adobe wird aber in mittelfristiger Zukunft die Möglichkeit der monatsweisen Buchung einführen.",
    },
    {
      question: "Bleibt der Stichtag des Kunden bestehen, nachdem ich ihn in den Adobe Cloud Store transferiert habe?",
      answer: "Ja, der Stichtag („Anniversary-Date“) wird übernommen und bleibt bestehen.",
    },
    {
      question: "Ist das Auto-Renewal bei transferierten Kunden bereits aktiviert?",
      answer:
        "Ja, das Auto-Renewal ist für Neukunden sowie transferierte Kunden standardmäßig aktiviert und kann auf Wunsch deaktiviert werden.",
    },
    {
      question: "Verschwindet der Kunde nach dem Transfer aus der Reseller Konsole?",
      answer: "Ja, nach dem Transfer ist der Kunde nicht mehr in der Reseller Konsole abrufbar.",
    },
    {
      question: "Wie kann ich Preise für Neukunden ermitteln, die noch nicht im Cloud Store angelegt wurden?",
      answer:
        "Aktuell besteht die Möglichkeit Angebote für Bestandskunden über den Warenkorb zu erstellen. Eine allgemeine Angebotsfunktion für den Cloud Store ist geplant.",
    },
    {
      question: "Wie können Kunden von anderen Resellern übernommen werden („Change of Reseller“)?",
      answer:
        `Möchten Sie einen bestehenden VIP Marketplace Kunden übernehmen, senden Sie bitte eine E-Mail mit der VIP-Nummer oder der Customer ID des Kunden an sofa@comline-shop.de und wir kümmern uns um den Wechsel.<br />
        Ist der Kunde noch nicht im VIP Marketplace, können Sie Ihn nach dessen Genehmigung direkt in den Adobe Cloud Store transferieren. Ein vorheriger, „klassischer“ Change of Reseller in der Reseller Konsole ist nicht notwendig.`,
    },
    {
      question: "Lassen sich Aufträge stornieren, nachdem sie im Cloud Store platziert wurden?",
      answer:
        "Ja, Aufträge können innerhalb von 14 Tagen direkt im Cloud Store in der Auftragshistorie storniert werden.",
    },
    {
      question: "Können Verlängerungen vorzeitig oder nachträglich gebucht werden?",
      answer:
        `Nein, die Verlängerung wird automatisch am Stichtag durchgeführt. War das Auto-Renewal nicht aktiviert, können Sie über die „Renewal vergessen?“-Funktion in der Kundenübersicht einen nachträglichen Auftrag veranlassen. Hierbei handelt es sich nicht um ein klassisches Renewal, sondern technisch um eine Neubuchung der Lizenzen, welche anschließend in der Admin Konsole neu zugewiesen werden müssen.<br />
        Stellen Sie nach Möglichkeit also sicher, dass das Auto-Renewal am Tag vor dem Stichtag aktiv ist.`,
    },
    {
      question:
        "Wird es für Kunden im Cloud Store, die zur Verlängerung anstehen rechtzeitig Erinnerungsmails geben, um vorab Angebote und Rechnungen zu erstellen?",
      answer:
        "Ja, Sie als Reseller erhalten vor jedem Renewal insgesamt vier Erinnerungsmails (45, 30, 15 und einen Tag vor dem Stichtag), um Sie pünktlich über anstehende Verlängerungen zu informieren.",
    },
    {
      question:
        "Bleibt der bisherige Prozess für das Lizenzprogramm VIP bestehen oder ändert sich hier im Zuge der neuen Plattform etwas?",
      answer: "Für das „klassische“ VIP gibt es keine Änderungen, hier bleibt vorerst alles beim Alten.",
    },
    {
      question:
        "Was passiert, wenn mein Kunde eine neue Lizenz über seine Adminkonsole hinzufügt? Werde ich darüber benachrichtigt?",
      answer:
        "Ja, wenn ein Kunde über die Adminkonsole eine Kaufautorisierung erstellt, erhalten Sie wie im klassischen VIP eine Benachrichtigung per Mail. Diese Lizenz muss dann innerhalb von sieben (7) Tagen über den Adobe Cloud Store bestellt werden.",
    },
    {
      question:
        "Kann ich für meinen Kunden ein Angebot erstellen?",
      answer:
        "Ja, Sie haben die Möglichkeit über \"Auftrag erstellen\" vor dem Abschluss eines Auftrags ein Angebot zur erzeugen. Der Warenkorb wird beim Verlassen des Fensters gespeichert, sodass sie zu einem späteren Zeitpunkt wieder darauf zugreifen und die Lizenz(en) bestellen können. Zusätzlich haben Sie die Möglichkeit über die die Detailansicht eines \"Kunden\" ein \"Angebot für Renewal\" zu erzeugen.",
    },
  ],
  contactPersons: [
    {
      name: "Marcel Thomsen",
      title: "Head of Business Unit Software",
      phoneNumber: "+49 461 77303-209",
      email: "marcel.thomsen@comline-shop.de",
      image: "marcel-thomsen.jpg",
    },
    {
      name: "Andreas Christensen",
      title: "Business Development Manager Software",
      phoneNumber: "+49 461 77303-202",
      email: "andreas.christensen@comline-shop.de",
      image: "andreas-christensen.jpg",
    },
    {
      name: "Maike Andresen",
      title: "License Specialist",
      phoneNumber: "+49 461 77303-214",
      email: "maike.andresen@comline-shop.de",
      image: "maike-andresen.jpg",
    },
    {
      name: "Thore Hansen",
      title: "Adobe Business Development",
      phoneNumber: "+49 461 77303-215",
      email: "thore.hansen@comline-shop.de",
      image: "thore-hansen.jpg",
    },
  ]
};
