<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">
          {{ $t("contacts") }}
        </h3>
        <b-button variant="dark" v-on:click="addContact()"
          ><fa-icon :icon="['fa-regular', 'fa-user-plus']" class="mr-2"></fa-icon>{{ $t("addContact") }}</b-button
        >
      </b-container>
    </div>
    <b-container class="pt-4">
      <b-form v-on:submit.prevent="updateResellerAccount" v-if="contacts.length > 0">
        <b-card footer-class="border-0 bg-light">
          <contact-component
            v-for="(contact, index) in contacts"
            :key="`contact-${index}`"
            :contact="contact"
            :index="index"
            :type="'reseller'"
            v-on:stateUpdated="stateUpdated"
            v-on:contactUpdated="contactUpdated"
            v-on:contactRemoved="contactRemoved"
          ></contact-component>

          <template #footer>
            <div class="d-flex justify-content-end">
              <submit-button-component
                :state="submitButtonState"
                :label="$t('save')"
                :block="false"
                class="my-2"
              ></submit-button-component>
            </div>
          </template>
        </b-card>
      </b-form>

      <b-spinner variant="secondary" v-else></b-spinner>
    </b-container>
  </div>
</template>

<script>
import ContactComponent from "../components/ContactComponent.vue";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { getDoc, doc } from "firebase/firestore";

export default {
  name: "ResellerContactsView",
  components: {
    ContactComponent,
    SubmitButtonComponent,
  },
  data() {
    return {
      isWorking: false,
      missingFields: true,
      contacts: [],
      companyProfile: null,
      logo: null,
    };
  },
  created() {
    this.getResellerDataFromCollection();
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      if (this.missingFields === true) {
        return "missingFields";
      }

      return "";
    },
  },
  methods: {
    async getResellerDataFromCollection() {
      const docRef = doc(firestore, "resellers", this.$store.state.resellerData.customernumber)
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        this.contacts = docSnapshot.data().companyProfile.contacts;
        this.companyProfile = docSnapshot.data().companyProfile;
        this.logo = docSnapshot.data().logo;
      } else {
        this.$store.dispatch("alertError", {
          message: "Die Daten konnten nicht abgerufen werden.",
          show: true,
          noFooter: false,
        });
      }
    },
    async updateResellerAccount() {
      this.isWorking = true;

      this.companyProfile.contacts = this.contacts;

      const updateResellerAccount = httpsCallable(functions, "updateResellerAccount");
      await updateResellerAccount({
        resellerId: this.$store.state.resellerData.resellerId,
        customerNumber: this.$store.state.resellerData.customernumber,
        logo: this.logo,
        profile: {
          companyProfile: this.companyProfile,
        },
      })
        .then((result) => {
          this.profile = result.data;
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch("alertError", {
            message: "Die Daten konnten nicht gespeichert werden.",
            show: true,
            noFooter: false,
          });
        });

      this.isWorking = false;
    },
    stateUpdated(states) {
      if (Object.values(states).includes(false) || Object.values(states).includes(null)) {
        this.missingFields = true;
      } else {
        this.missingFields = false;
      }
    },
    contactUpdated(contact) {
      this.contacts.splice(contact.index, 1, contact.data);
    },
    addContact() {
      this.contacts.push({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });

      this.missingFields = true;
    },
    contactRemoved(index) {
      this.contacts.splice(index, 1);
      this.missingFields = false;
    },
  },
};
</script>
